/* DON'T EDIT THIS FILE: edit original and run build again */ import { sgrOpenAccountAgreementLabel } from "../tenant-handlers/sgr-open-account-agreement-label.ts";
import { BaseOnboardingOptions } from "./base-onboarding-options.ts";

export class BaseSgrOnboardingOptions extends BaseOnboardingOptions {
  hasSignatura(): boolean {
    return true;
  }

  hasFullGeneralInfoSection(): boolean {
    return false;
  }

  hasAccounts(): boolean {
    return false;
  }

  hasInvestmentProfile(): boolean {
    return false;
  }

  hasOnboardingCoholdersSection(): boolean {
    return false;
  }

  hasRelatedEntitiesSection(): boolean {
    return true;
  }

  usesFinancialGuarantee(): boolean {
    return true;
  }

  supportsWebHomeChannelAccessType(): boolean {
    return true;
  }

  hasFatca(): boolean {
    return false;
  }

  hasMatbaRofex(): boolean {
    return false;
  }

  hasSgrComissions(): boolean {
    return false;
  }

  hasTaxStatusOfRepresentatives(): boolean {
    return false;
  }

  hasOptionToNotUploadDocuments(): boolean {
    return false;
  }

  hasMutualGuaranteeContract(): boolean {
    return true;
  }

  hasTerms(): boolean {
    return false;
  }

  hasBeneficiaryCanBePublic(): boolean {
    return false;
  }

  hasBeneficiaryDoc(): boolean {
    return false;
  }

  allowPersonalAccount(): boolean {
    return false;
  }

  allowForeignCompanies(): boolean {
    return false;
  }

  getOpenAccountAgreementLabel(): string {
    return sgrOpenAccountAgreementLabel;
  }

  getOnboardingLabel(): string {
    return "Vinculación";
  }

  getOnboardingMailLabel(): string {
    return "vinculación";
  }
}
