/* DON'T EDIT THIS FILE: edit original and run build again */ import { EditorButtonGroup } from "../../html-editor/button-group/editor-button-group.tsx";
import { TableButton } from "../../html-editor/button/table-button.tsx";

export const TableButtonGroup = ({ enabled }: { enabled: boolean }) => {
  if (!enabled) {
    return null;
  }
  return (
    <EditorButtonGroup name="table">
      <TableButton />
    </EditorButtonGroup>
  );
};
