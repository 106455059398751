/* DON'T EDIT THIS FILE: edit original and run build again */ import { AttachmentRef } from "../attachment-type/attachment-ref-type.ts";
import { saveAttachment } from "../attachment/attachment-storage.ts";
import { BrowserAttachmentFile } from "../attachment/browser-attachment-file.ts";
import { ButtonProps } from "react-bootstrap";
import { FileInputButton } from "./file-input-button.tsx";

export const SavingFileInputButton = ({
  className = "",
  onUpload,
  onUploadingChange,
  uploadLabel,
  uid,
  autoFocus,
  size,
  disabled = false,
  variant = "outline-blue50",
  multiple = true,
  accept,
}: {
  className?: string;
  onUpload: (attachments: AttachmentRef[]) => void;
  onUploadingChange: (uploading: boolean) => void;
  uploadLabel: string;
  uid: string;
  autoFocus?: boolean;
  variant?: ButtonProps["variant"];
  size?: ButtonProps["size"];
  disabled?: boolean;
  multiple?: boolean;
  accept?: string;
}) => {
  const updateUploading = (uploading: boolean) => {
    if (onUploadingChange) {
      onUploadingChange(uploading);
    }
  };
  const onFileChoose = async (fileList: FileList) => {
    updateUploading(true);
    const newAttachments = await Promise.all(
      [...fileList].map((file) =>
        saveAttachment(new BrowserAttachmentFile(file), uid)
      )
    );
    onUpload(newAttachments);
    updateUploading(false);
  };
  if (!uid) {
    throw new Error("missingUid");
  }
  return (
    <FileInputButton
      disabled={disabled}
      className={className}
      variant={variant}
      size={size}
      autoFocus={autoFocus}
      uploadLabel={uploadLabel}
      onChoose={onFileChoose}
      multiple={multiple}
      accept={accept}
    />
  );
};
