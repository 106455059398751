/* DON'T EDIT THIS FILE: edit original and run build again */ import { AlycTenantHandler } from "../../tenant-handlers/alyc-tenant-handler.ts";
import { stonexAlycTenant } from "./code.ts";
import { stonexAlycFullLabel } from "./full-label.ts";
import { stonexAlycLabel } from "./label.ts";

class StonexAlycTenantHandler extends AlycTenantHandler {
  getCode(): string {
    return stonexAlycTenant;
  }

  getPathLabel(): string | null {
    return "stonex-alyc";
  }

  getLabel(): string {
    return stonexAlycLabel;
  }

  getFullLabel(): string {
    return stonexAlycFullLabel;
  }

  getNotificationsFrom(): string {
    return "no-responder@matchfin.ar";
  }

  getCuit(): string {
    return "33-62174378-9";
  }

  getCnvRegistryId(): string | null {
    return "47";
  }

  publishesInMarketplace(): boolean {
    return true;
  }
}

const stonexAlycTenantHandler = new StonexAlycTenantHandler();

export default stonexAlycTenantHandler;
