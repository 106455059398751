/* DON'T EDIT THIS FILE: edit original and run build again */ import {
  canAccessUsers,
  canSeeOtherUsersRoles,
} from "../permission/permissions.ts";
import { RolesMap } from "../permission/schema.ts";
import { userHasPermission } from "../permission/user-has-permission.ts";
import { TenantHandler } from "../tenant-handler-type/tenant-handler.ts";
import { hasPermissionToListUsers } from "./permissions.ts";

export const canAccessUsersPage = ({
  roles,
  tenantHandler,
}: {
  roles: RolesMap;
  tenantHandler: TenantHandler;
}) =>
  userHasPermission(roles, canAccessUsers) &&
  userHasPermission(roles, canSeeOtherUsersRoles) &&
  hasPermissionToListUsers({ roles, tenantHandler });
