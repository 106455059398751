/* DON'T EDIT THIS FILE: edit original and run build again */ import { getUserFullName } from "../auth-user-full-name/get-user-full-name.ts";
import { Jsonable } from "../core/jsonable.ts";
import isEqual from "react-fast-compare";

const trackToConsole = (name, props) => {
  console.log("track", name, props);
};

const identifyToConsole = ({ uid, userProps }) => {
  console.log("track-user-change", { uid, userProps });
};

type TrackerUserData = {
  uid: string | null;
  userProps: {
    email: string | null;
    fullName: string | null;
    tenant: string | null;
    roles: string[] | null;
    cssResolution: string | null;
    deviceResolution: string | null;
    pixelRatio: number | null;
  };
};

type identifyCallback = (data: TrackerUserData) => void;

let oldData: TrackerUserData = {
  uid: null,
  userProps: {
    email: null,
    fullName: null,
    tenant: null,
    roles: null,
    cssResolution: null,
    deviceResolution: null,
    pixelRatio: null,
  },
};

export const notifyUserToTracker = (account, profile, roles) => {
  const pixelRatio = window.devicePixelRatio;
  const screenHeight = window.screen.height;
  const screenWidth = window.screen.width;
  const newData = {
    uid: account?.uid ?? null,
    userProps: {
      email: account?.email ?? null,
      fullName: profile ? getUserFullName(profile) : null,
      // eslint-disable-next-line no-restricted-properties
      tenant: profile?.tenant ?? null,
      roles: roles ? Object.keys(roles) : null,
      cssResolution: screenWidth + "x" + screenHeight,
      deviceResolution:
        pixelRatio * screenWidth + "x" + pixelRatio * screenHeight,
      pixelRatio,
    },
  };
  if (!isEqual(newData, oldData)) {
    oldData = newData;
    identify(newData);
  }
};

let tracker = trackToConsole;

let identify: identifyCallback = identifyToConsole;

export const setTracker = (newTracker) => {
  tracker = newTracker;
};

export const setTrackerIdentify = (newTrackerIdentify: identifyCallback) => {
  identify = newTrackerIdentify;
};

export const track = (
  name: string,
  props: Record<string, Jsonable> | undefined = undefined
) => {
  tracker(name, props);
};
