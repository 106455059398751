/* DON'T EDIT THIS FILE: edit original and run build again */ import { EndClientEntity } from "../end-client/schema.ts";
import { FirestoreDocumentData } from "../firebase/firestore-wrappers-types.ts";
import { Roles } from "../permission/schema.ts";

export enum EndClientAccessRequestStatus {
  pending = "pending",
  ignored = "ignored",
  authorized = "authorized",
}

export enum EndClientAccessRequestApproverPrivacy {
  admins = "admins",
  visibleForAll = "visibleForAll",
}

export type EndClientAccessRequest = FirestoreDocumentData<{
  authorizedApproverName?: string;
  authorizedApproverPrivacy?: EndClientAccessRequestApproverPrivacy;
  authorizedApproverRole?: Roles;
  authorizedApproverUid?: string;
  authorizedTime?: string;
  ignoredApproverName?: string;
  ignoredApproverPrivacy?: EndClientAccessRequestApproverPrivacy;
  ignoredApproverRole?: Roles;
  ignoredApproverUid?: string;
  ignoredTime?: string;
  endClient: EndClientEntity;
  entityId: string;
  status: EndClientAccessRequestStatus;
  tenant: string;
  time: string;
  requesterUid: string;
}>;

export const getEndClientAccessRequestApproverName = (
  request: EndClientAccessRequest,
  status: EndClientAccessRequestStatus
) => {
  if (status === EndClientAccessRequestStatus.authorized) {
    return request.authorizedApproverName;
  } else if (status === EndClientAccessRequestStatus.ignored) {
    return request.ignoredApproverName;
  } else if (status === EndClientAccessRequestStatus.pending) {
    return undefined;
  }
};

export const getEndClientAccessRequestApproverPrivacy = (
  request: EndClientAccessRequest,
  status: EndClientAccessRequestStatus
) => {
  if (status === EndClientAccessRequestStatus.authorized) {
    return request.authorizedApproverPrivacy;
  } else if (status === EndClientAccessRequestStatus.ignored) {
    return request.ignoredApproverPrivacy;
  } else if (status === EndClientAccessRequestStatus.pending) {
    return undefined;
  }
};

export const getEndClientAccessRequestTime = (
  request: EndClientAccessRequest,
  status: EndClientAccessRequestStatus
) => {
  if (status === EndClientAccessRequestStatus.authorized) {
    return request.authorizedTime;
  } else if (status === EndClientAccessRequestStatus.ignored) {
    return request.ignoredTime;
  } else if (status === EndClientAccessRequestStatus.pending) {
    return undefined;
  }
};

export const getEndClientAccessRequestApproverRole = (
  request: EndClientAccessRequest,
  status: EndClientAccessRequestStatus
) => {
  if (status === EndClientAccessRequestStatus.authorized) {
    return request.authorizedApproverRole;
  } else if (status === EndClientAccessRequestStatus.ignored) {
    return request.ignoredApproverRole;
  } else if (status === EndClientAccessRequestStatus.pending) {
    return undefined;
  }
};

export type EndClientAccessRequestSummary = FirestoreDocumentData<{
  endClientIds: string[];
  tenant: string;
}>;
