/* DON'T EDIT THIS FILE: edit original and run build again */ import { getConfig } from "../config/config.ts";
import {
  getAuthUrl,
  rewriteEmulatorUrl,
} from "../firebase/emulators-url-rewriter.ts";
import {
  getFirebaseFirestorePort,
  getFirebaseFunctionsPort,
  getFirebaseStoragePort,
} from "../firebase/firebase-port.ts";
import { setupFirestoreOptions } from "../firebase/setup-firestore-options.ts";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/functions";
import "firebase/compat/storage";

declare global {
  interface Window {
    idler: any;
  }
}
const initEmulators = (onlyFunctions: boolean) => {
  const { firebaseEmulatorsHost, forceFirebaseEmulatorsToUseHttps } =
    getConfig();

  const forceHttps = () => {
    window.idler.replaceXmlHttpRequestOpen(
      (oldOpen: any) =>
        function (method: any, url: any, ...args: any[]) {
          return oldOpen.call(this, method, rewriteEmulatorUrl(url), ...args);
        }
    );
    window.idler.replaceFetch(
      (oldFetch: any) =>
        (resource: any, ...args: any[]) => {
          return oldFetch(rewriteEmulatorUrl(resource), ...args);
        }
    );
  };
  if (forceFirebaseEmulatorsToUseHttps) {
    forceHttps();
  }
  if (!onlyFunctions) {
    // @ts-expect-error broken types
    firebase.initializeApp({
      apiKey: "example",
      projectId: "example",
      storageBucket: "example.appspot.com",
    });
    // @ts-expect-error broken types
    firebase.auth().useEmulator(getAuthUrl(), {
      disableWarnings: true,
    });
    // @ts-expect-error broken types
    const firestore = firebase.firestore();
    firestore.useEmulator(firebaseEmulatorsHost, getFirebaseFirestorePort());
    // @ts-expect-error broken types
    const storage = firebase.storage();
    storage.useEmulator(firebaseEmulatorsHost, getFirebaseStoragePort());
  }
  // @ts-expect-error broken types
  const functions = firebase.functions();
  functions.useEmulator(firebaseEmulatorsHost, getFirebaseFunctionsPort());
};

export const initFirebase = () => {
  // @ts-expect-error broken types
  if (!firebase.apps.length) {
    const config = getConfig();
    if (config.useFirebaseEmulators) {
      initEmulators(false);
    } else {
      // @ts-expect-error broken types
      firebase.initializeApp(config.firebase);
      if (config.forceFunctionsEmulatorsWithRemoteFirebase) {
        initEmulators(true);
      }
    }
  }
  setupFirestoreOptions();
};
