/* DON'T EDIT THIS FILE: edit original and run build again */ import { getConfig } from "../config/config.ts";
import {
  getAllTenantCodes,
  getTenantHandler,
} from "../tenant-collection/tenant.ts";
import { matchfinTenant } from "../tenant-matchfin-code/matchfin-tenant-code.ts";
import { getUserRealm } from "./user-realm.ts";

const getRealmUrlParts = (realm) => {
  const { tenants, domainPatternForTenantUrls, firebaseStartPort, url } =
    getConfig();
  const realmConfig = tenants?.[realm];
  if (realmConfig && realmConfig.host) {
    const { host, port = "", secure = true } = realmConfig;
    return {
      protocol: secure ? "https" : "http",
      host,
      port: port,
      path: "",
    };
  }
  if (domainPatternForTenantUrls) {
    return {
      protocol: "http",
      host: getRealmHostnameFromPattern(domainPatternForTenantUrls, realm),
      port: firebaseStartPort,
      path: "",
    };
  }
  const parsedUrl = new URL(url);
  return {
    protocol: parsedUrl.protocol.replace(/:/g, ""),
    host: parsedUrl.hostname,
    port: parsedUrl.port,
    path:
      realm === matchfinTenant
        ? ""
        : "/r/" + getTenantHandler(realm).getPathLabel(),
  };
};

export const getRealmHostnameFromPattern = (
  domainPatternForTenantUrls,
  realm
) => {
  return domainPatternForTenantUrls.replace("%tenant%", realm);
};

export const getRealmUrl = (realm) => {
  const realmUrlParts = getRealmUrlParts(realm);
  const { protocol, host, port, path } = realmUrlParts;
  return (
    protocol + "://" + host + (port ? ":" + port : "") + (path ? path : "")
  );
};

export const getRealmHostname = (realm) => getRealmUrlParts(realm).host;

export const getRealmUrlFromProfileTenant = (profileTenant, roles) =>
  getRealmUrl(getUserRealm({ tenant: profileTenant }, roles));

let realmsByHostAlone;

/** only for tenants that don't use paths */
export const getRealmsByHostAlone = () => {
  if (!realmsByHostAlone) {
    realmsByHostAlone = {};
    for (const tenant of getAllTenantCodes()) {
      const urlParts = getRealmUrlParts(tenant);
      if (!urlParts.path) {
        realmsByHostAlone[urlParts.host] = tenant;
      }
    }
  }
  return realmsByHostAlone;
};
