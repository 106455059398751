/* DON'T EDIT THIS FILE: edit original and run build again */ import {
  getInlineEntityById,
  getInlineEntityByIdOrNull,
  getInlineEntityError,
  isInlineEntityEmpty,
} from "../entity/inline-entity.ts";
import { EntityType } from "../gov-id-number/entity-type.ts";
import { BaseLiveField, LiveFieldSpec } from "../live-form/field-spec.ts";
import { LiveField } from "../live-form/live-field.ts";
import { LiveData } from "../live-form/types.ts";
import { entityLiveFieldType } from "./type.ts";

type EntityLiveFieldSpec = LiveFieldSpec & {
  allowEntityTypes?: EntityType;
  includeName?: boolean;
  includeExtraNameFields?: boolean;
  allowForeignEntities?: boolean;
  allowDomesticEntities?: boolean;
  includeDateOfBirth?: boolean;
  useDni?: boolean;
};

export class EntityLiveField extends BaseLiveField {
  public readonly allowEntityTypes: EntityType;
  public readonly includeName: boolean;
  public readonly includeExtraNameFields: boolean;
  public readonly allowForeignEntities: boolean;
  public readonly allowDomesticEntities: boolean;
  public readonly includeDateOfBirth: boolean;
  public readonly useDni: boolean;

  constructor({
    allowEntityTypes = EntityType.Any,
    includeName = true,
    includeExtraNameFields = includeName,
    allowForeignEntities = true,
    allowDomesticEntities = true,
    includeDateOfBirth = false,
    useDni = false,
    ...spec
  }: EntityLiveFieldSpec) {
    super(spec, entityLiveFieldType);
    this.allowEntityTypes = allowEntityTypes;
    this.includeName = includeName;
    this.includeExtraNameFields = includeExtraNameFields;
    this.allowForeignEntities = allowForeignEntities;
    this.allowDomesticEntities = allowDomesticEntities;
    this.includeDateOfBirth = includeDateOfBirth;
    this.useDni = useDni;
  }

  getSubfieldsError(liveData: LiveData, _context: LiveField[]) {
    const value = this.getValue(liveData) as string;
    if (!value) {
      return null;
    }
    const inlineEntity = getInlineEntityById(liveData.baseFolder, value);
    return getInlineEntityError(inlineEntity, {
      allowEntityTypes: this.allowEntityTypes,
    });
  }

  dontUseLabelTag() {
    return true;
  }

  isEmpty(liveData: LiveData) {
    const value = this.getValue(liveData);
    if (!value) {
      return true;
    }
    return isInlineEntityEmpty(
      getInlineEntityByIdOrNull(liveData.baseFolder, value as string)
    );
  }

  getParsedInput(liveData: LiveData) {
    const entityId = this.getValue(liveData) as string;
    if (!entityId) {
      return {};
    }
    const { id: _, ...data } = getInlineEntityById(
      liveData.baseFolder,
      entityId
    );
    return data;
  }
}
