/* DON'T EDIT THIS FILE: edit original and run build again */ import { WithId } from "../core/with-id.ts";
import { FirestoreDocumentData } from "../firebase/firestore-wrappers-types.ts";
import { Roles } from "../permission/schema.ts";

export enum RoleRequestStatus {
  pending = "pending",
  ignored = "ignored",
  authorized = "authorized",
}

export enum RoleRequestApproverPrivacyTypes {
  admins = "admins",
  visibleForAll = "visibleForAll",
}

export type RoleRequest = FirestoreDocumentData<{
  time: string;
  requestedRoles: Roles[];
  tenant: string;
  status: RoleRequestStatus;
  isEndClient: boolean;
  entityId?: string;
  ignoredTime?: string;
  ignoredApproverUid?: string;
  ignoredApproverName?: string;
  ignoredApproverPrivacy?: string;
  ignoredApproverRole?: Roles;
  authorizedTime?: string;
  authorizedApproverUid?: string;
  authorizedApproverName?: string;
  authorizedApproverPrivacy?: string;
  authorizedApproverRole?: Roles;
}>;

export type UserRoleRequest = WithId<RoleRequest, "uid">;
