/* DON'T EDIT THIS FILE: edit original and run build again */ import {
  emailChannelType,
  webHomeChannelType,
} from "../../channel/channel-types.ts";
import {
  AFIP,
  EMAIL,
} from "../../signatura-document-validations/document-validations.ts";
import { BaseOnboardingOptions } from "../../tenant-handlers/base-onboarding-options.ts";
import { ComponentType } from "react";

class ArgenfundsOnboardingOptions extends BaseOnboardingOptions {
  hasSignatura(): boolean {
    return true;
  }

  getSignaturaValidations(): string[] {
    return [EMAIL, AFIP];
  }

  hasSwornStatementAboutLegality(): boolean {
    return true;
  }

  hasProofOfFciRulebook(): boolean {
    return true;
  }

  getSupportedChannelTypes(): string[] {
    return [webHomeChannelType, emailChannelType];
  }

  hasFatca(): boolean {
    return false;
  }

  hasMatbaRofex(): boolean {
    return false;
  }

  hasSgrComissions(): boolean {
    return false;
  }

  hasIsQualifiedInvestorText(): boolean {
    return true;
  }

  async getDocumentHeader(): Promise<
    ComponentType<{ agreementLabel: string }>
  > {
    return (await (() => { throw new Error("This file is not meant to be imported in this context.") })()).default;
  }
}

export const argenfundsOnboardingOptions = new ArgenfundsOnboardingOptions();
