/* DON'T EDIT THIS FILE: edit original and run build again */ import { spaceship } from "../../core/spaceship.ts";
import { useLiveImmediateInput } from "../../live-form/ui/live-immediate-input.tsx";
import {
  LiveFieldInput,
  LiveFieldInputParam,
  LiveFieldRenderer,
  LiveFieldViewer,
} from "../../live-form/ui/types.ts";
import { Div } from "../../pdf-wrapper/pdf-wrapper.tsx";
import { MultipleComboBoxDropdown } from "../../ui/form/combo-box-dropdown.tsx";
import { LabeledCheckbox } from "../../ui/form/labeled-checkbox.tsx";
import { MultipleSelectInput } from "../../ui/form/multiple-select-input.tsx";
import { MultipleOptionLiveField } from "./live-field.ts";
import { multipleOptionLiveFieldType } from "./type.ts";

const Input: LiveFieldInput = (fieldInfo: LiveFieldInputParam) => {
  const { liveCell } = fieldInfo;
  const liveField = liveCell.getLiveField() as MultipleOptionLiveField;
  const valueOptions = liveField.getValueOptions();
  const variant = liveField.getVariant();
  const { value = {}, setValue } = useLiveImmediateInput(fieldInfo);
  if (variant === "comboBox") {
    return (
      <MultipleComboBoxDropdown
        value={value}
        valueOptions={valueOptions}
        setValue={(selectedOptions) => {
          const out: Record<string, true> = {};
          for (const { value } of selectedOptions) {
            out[value] = true;
          }
          setValue(out);
        }}
      />
    );
  } else if (variant === "checkbox") {
    return (
      <div>
        {Object.entries(valueOptions).map(([id, label]) => (
          <LabeledCheckbox
            key={id}
            label={label}
            value={!!value[id]}
            setValue={(checked) => {
              const { [id]: _, ...tmp } = value as any;
              if (checked) {
                tmp[id] = true;
              }
              setValue(tmp);
            }}
          />
        ))}
      </div>
    );
  } else {
    throw new Error("unknownMultipleOptionVariant: " + variant);
  }
};

const Viewer: LiveFieldViewer = ({ liveCell }) => {
  const liveField = liveCell.getLiveField() as MultipleOptionLiveField;
  const value = liveCell.getValue() ?? {};
  const valueOptions = liveField.getValueOptions();
  const variant = liveField.getVariant();
  if (variant === "comboBox") {
    return (
      <MultipleSelectInput
        placeholder={""}
        value={Object.entries(value).map(([key]) => ({
          label: valueOptions[key],
          value: key,
        }))}
        disabled={true}
        setValue={() => {}}
        onChange={() => {}}
        options={valueOptions}
        styles={{
          multiValueRemove: (base) => {
            return { ...base, display: "none" };
          },
        }}
      />
    );
  } else {
    const items = Object.entries(value)
      .filter(([, value]) => value)
      .map(([key]) => ({ key, label: valueOptions[key] }));
    items.sort((a, b) => spaceship(a.label, b.label));
    return (
      <Div>
        {items.map(({ key, label }) => (
          <Div key={key}>{label}</Div>
        ))}
      </Div>
    );
  }
};

export const multipleOptionLiveFieldRenderers: LiveFieldRenderer = {
  type: multipleOptionLiveFieldType,
  Input,
  Viewer,
};
