/* DON'T EDIT THIS FILE: edit original and run build again */ import { getLoggedUserId } from "../firebase/get-logged-user-id.ts";
import { Editor } from "../html-editor/editor.tsx";
import { useHtmlEditorState } from "../html-editor/use-html-editor-state.ts";
import { Viewer } from "../html-editor/viewer.tsx";
import {
  RichTextValue,
  emptyRichTextValue,
} from "../rich-text/rich-text-type.ts";
import { useCallback, useState } from "react";

export const HtmlEditor = ({
  initialValue = emptyRichTextValue,
  onChange,
  onFocus = undefined,
  onBlur = undefined,
  editorClassName = "",
  delayed,
  autoFocus = undefined,
  // Upload ownership requires a uid
  userId,
}: {
  initialValue?: RichTextValue;
  onChange: (
    value: RichTextValue,
    options: { force: boolean; state: any }
  ) => void;
  onFocus?: (event: any) => void;
  onBlur?: (event: any) => void;
  editorClassName: string;
  delayed?: boolean;
  autoFocus?: boolean | undefined;
  userId: string;
}) => {
  const [value, setValue] = useState(initialValue);
  const state = useHtmlEditorState(value, setValue);
  const [focused, setFocused] = useState(false);
  const onFocusCallback = useCallback(
    (event: any) => {
      setFocused(true);
      if (onFocus) {
        onFocus(event);
      }
    },
    [onFocus]
  );
  const onBlurCallback = useCallback(
    (event: any) => {
      setFocused(false);
      if (delayed) {
        onChange(state.getValue(), { force: true, state });
      }
      if (onBlur) {
        onBlur(event);
      }
    },
    [delayed, onBlur, onChange, state]
  );
  if (!userId) {
    userId = getLoggedUserId();
  }
  return (
    <Editor
      onChange={(value, { forceSetValue = false } = {}) => {
        onChange(value, { force: !delayed || forceSetValue, state });
      }}
      htmlEditorState={state}
      onFocus={onFocusCallback}
      onBlur={onBlurCallback}
      containerClassName={"editing " + (focused ? "focused-html-editor " : " ")}
      editorClassName={editorClassName}
      userId={userId}
      autoFocus={autoFocus}
    />
  );
};

export const HtmlViewer = ({ value }: { value: RichTextValue }) => {
  const htmlEditorState = useHtmlEditorState(
    value ?? emptyRichTextValue,
    () => undefined
  );
  return <Viewer htmlEditorState={htmlEditorState} />;
};
