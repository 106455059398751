/* DON'T EDIT THIS FILE: edit original and run build again */ import { useLiveImmediateInput } from "../../live-form/ui/live-immediate-input.tsx";
import {
  LiveFieldInput,
  LiveFieldRenderer,
  LiveFieldViewer,
} from "../../live-form/ui/types.ts";
import { Div } from "../../pdf-wrapper/pdf-wrapper.tsx";
import { ComboBoxDropdown } from "../../ui/form/combo-box-dropdown.tsx";
import { onEnterToOnKeyPress } from "../../ui/form/on-enter.ts";
import { RadioButtonsInput } from "../../ui/form/radio-buttons-input.tsx";
import { SimpleDropdown } from "../../ui/form/simple-dropdown.tsx";
import { SingleOptionLiveField } from "./live-field.ts";
import { singleOptionLiveFieldType } from "./type.ts";

const Input: LiveFieldInput = (fieldInfo) => {
  const { liveCell } = fieldInfo;
  const liveField = liveCell.getLiveField() as SingleOptionLiveField;
  const variant = liveField.getVariant();
  const valueOptions = liveField.getValueOptions();
  const { value, setValue, className, autoFocus, disabled } =
    useLiveImmediateInput(fieldInfo);
  if (variant === "dropdown") {
    return (
      <SimpleDropdown
        value={value ?? ""}
        options={valueOptions}
        setValue={setValue}
        onKeyPress={
          fieldInfo.onEnterPress
            ? onEnterToOnKeyPress(fieldInfo.onEnterPress)
            : undefined
        }
        className={className}
        autoFocus={autoFocus}
        disabled={disabled}
      />
    );
  } else if (variant === "radioButtons") {
    return (
      <RadioButtonsInput
        value={value}
        setValue={setValue}
        options={valueOptions}
        className={className}
        autoFocus={autoFocus}
        disabled={disabled}
        nullable={true}
      />
    );
  } else if (variant === "comboBox") {
    return (
      <ComboBoxDropdown
        value={value}
        valueOptions={valueOptions}
        setValue={setValue}
      />
    );
  } else {
    throw new Error("unknownSingleOptionVariant: " + variant);
  }
};

const Viewer: LiveFieldViewer = (fieldInfo) => {
  const { liveCell } = fieldInfo;
  const liveField = liveCell.getLiveField() as SingleOptionLiveField;
  const valueOptions = liveField.getValueOptions();
  const value = liveCell.getValue();
  if (value) {
    return <Div>{valueOptions[value]}</Div>;
  } else {
    return null;
  }
};

export const singleOptionLiveFieldRenderers: LiveFieldRenderer = {
  type: singleOptionLiveFieldType,
  Input,
  Viewer,
};
