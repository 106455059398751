/* DON'T EDIT THIS FILE: edit original and run build again */ import { AttachmentRef } from "../attachment-type/attachment-ref-type.ts";
import {
  getAttachmentPermalink,
  saveAttachment,
} from "../attachment/attachment-storage.ts";
import { BrowserAttachmentFile } from "../attachment/browser-attachment-file.ts";
import { sleep } from "../core/sleep.ts";
import { getLoggedUserId } from "../firebase/get-logged-user-id.ts";
import { CountExtension } from "@remirror/extension-count";
import { useRemirror } from "@remirror/react";
import { useCallback } from "react";
import {
  BoldExtension,
  BulletListExtension,
  HeadingExtension,
  ImageExtension,
  ItalicExtension,
  LinkExtension,
  TableExtension,
  UnderlineExtension,
} from "remirror/extensions";
import { HtmlEditorState } from "./use-html-editor-state.ts";

const uploadFiles = (
  files: { file: File }[],
  {
    onChange,
    userId,
    htmlEditorState,
  }: {
    onChange:
      | ((
          html: string,
          attachment: AttachmentRef & { permalink: string }
        ) => void)
      | undefined;
    userId: string | undefined;
    htmlEditorState: any;
  }
) => {
  const promises: (() => Promise<{ src: string; fileName: string }>)[] = [];
  for (const { file } of files) {
    promises.push(async () => {
      const attachment = await saveAttachment(
        new BrowserAttachmentFile(file),
        userId || getLoggedUserId()
      );
      const permalink = getAttachmentPermalink(attachment.id);
      onChange?.(htmlEditorState.html, {
        ...attachment,
        permalink,
      });
      // Let it rest a little bit
      await sleep(1500);
      // Remirror data required to show the image
      return {
        src: permalink,
        fileName: attachment.originalName,
      };
    });
  }
  return promises;
};

const createPlaceholder = () => {
  const element = document.createElement("div");
  element.style.setProperty("width", "3rem");
  element.style.setProperty("height", "3rem");
  element.style.setProperty("background-color", "#fff");
  element.classList.add(..."m-4 spinner-border text-gray40".split(" "));
  return element;
};

export const useRemirrorWrapper = ({
  htmlEditorState,
  onEditorChange = undefined,
  editable,
  userId = undefined,
}: {
  htmlEditorState: HtmlEditorState;
  onEditorChange?: (
    value: string,
    attachment: AttachmentRef & { permalink: string }
  ) => void;
  editable: boolean;
  userId?: string;
}) => {
  const uploadHandler = useCallback(
    (files: { file: File }[]) =>
      uploadFiles(files, { userId, onChange: onEditorChange, htmlEditorState }),
    [htmlEditorState, onEditorChange, userId]
  );

  return useRemirror({
    extensions: () => [
      new BoldExtension({}),
      new HeadingExtension({}),
      new UnderlineExtension(),
      new ItalicExtension(),
      new BulletListExtension({}),
      // eslint-disable-next-line
      // @ts-ignore-next I left this on the typescript strictNullChecks migration
      new TableExtension(),
      new LinkExtension({ autoLink: true, defaultTarget: "_blank" }),
      new ImageExtension({
        enableResizing: editable,
        uploadHandler,
        createPlaceholder,
      }),
      new CountExtension({}),
    ],
    content: htmlEditorState.html,
    selection: "start",
    stringHandler: "html",
  });
};
