/* DON'T EDIT THIS FILE: edit original and run build again */ import { getProfileDocument } from "../auth-profile/profile.ts";
import { getConfig } from "../config/config.ts";
import {
  addReportErrorMessageHandler,
  addReportExceptionHandler,
} from "../track/error.ts";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import firebase from "firebase/compat/app";

export const initSentry = () => {
  const config = getConfig();
  if (config.sentryDsn) {
    Sentry.init({
      dsn: config.sentryDsn,
      integrations: [new Integrations.BrowserTracing()],
      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: 1.0,
      release: "matchfin-" + config.environment + "@" + config.version,
      environment: config.environment,
    });
    let unsubscribeProfile: () => void = () => undefined;
    // @ts-expect-error firebase.auth() is not typed
    firebase.auth().onAuthStateChanged((account) => {
      unsubscribeProfile();
      if (account) {
        const baseUserData = {
          id: account.uid,
        };
        Sentry.setUser(
          account.email
            ? {
                ...baseUserData,
                email: account.email,
              }
            : baseUserData
        );
        unsubscribeProfile = getProfileDocument(account.uid).onSnapshot(
          (snap) => {
            const data = snap.data();
            Sentry.setTag("tenant", data ? data.tenant : "");
          },
          () => null
        );
      } else {
        Sentry.setUser(null);
      }
    });
    addReportErrorMessageHandler((message: string) => {
      Sentry.captureMessage(message);
    });
    addReportExceptionHandler((where: string, error: Error) => {
      Sentry.captureException(error, {
        extra: {
          where,
        },
      });
    });
  }
};
