/* DON'T EDIT THIS FILE: edit original and run build again */ import { Profile, UserProfile } from "../auth-profile/schema.ts";
import { ascSorter, sortByMany } from "../core/sort-by-many.ts";
import { WithId } from "../core/with-id.ts";
import { DataPipe } from "../data-pipe/data-pipe.ts";
import { joinPipe } from "../data-pipe/join-pipe.ts";
import { colPipe, docPipe } from "../firebase/firestore-pipe.ts";
import {
  doesUserCompanyHaveTenantLimits,
  getTenantUserCompanyLimitsDoc,
} from "../user-company-limit/data.ts";
import { ObjectPermissionLimits } from "../user-company-limit/permission-limits.ts";
import { userCompanyTypeIds } from "../user-company-type/user-company-type.ts";
import {
  getUserCompaniesCol,
  isUserCompanyForeign,
} from "../user-company/data.ts";
import { UserCompanyType } from "../user-company/user-company-type.ts";

const getBasePipe = (userCompanyTypes: string[]) =>
  colPipe(
    getUserCompaniesCol().where("companyType", "in", userCompanyTypes),
    "id",
    "userCompanies"
  );

type CounterpartiesFilter = {
  userCompanyTypes?: string[];
  pendingApproval?: boolean;
  includeOwnUserCompany?: boolean;
};

export const createFilteredBaseCounterpartiesPipe = (
  profile: Profile,
  {
    userCompanyTypes = userCompanyTypeIds,
    pendingApproval = false,
    includeOwnUserCompany = false,
  }: CounterpartiesFilter
) => {
  if (userCompanyTypes.length === 0) {
    return DataPipe.withInitialData(null, []);
  }
  return getBasePipe(userCompanyTypes).pipe((items) =>
    items.filter(
      (item) =>
        (includeOwnUserCompany || item.entityId !== profile.entityIdCompany) &&
        (pendingApproval || !item.pendingApproval) &&
        doesUserCompanyHaveTenantLimits(item) &&
        !isUserCompanyForeign(item)
    )
  );
};

export type UserCompanyWithLimits = WithId<UserCompanyType, "id"> & {
  limits?: ObjectPermissionLimits;
};

export const createCounterpartiesWithLimitsPipe = (
  tenant: string,
  profile: Profile,
  filter: CounterpartiesFilter
): DataPipe<UserCompanyWithLimits[]> =>
  joinPipe(
    createFilteredBaseCounterpartiesPipe(profile, filter),
    ({ id }) => {
      return docPipe(getTenantUserCompanyLimitsDoc(id, tenant));
    },
    (userCompany, limits) => ({ ...userCompany, limits })
  );

export const createCounterpartiesPipe = (
  tenant: string,
  profile: Profile,
  {
    includeUserCompanyLimits = false,
    ...filter
  }: CounterpartiesFilter & { includeUserCompanyLimits?: boolean }
): DataPipe<UserCompanyWithLimits[]> => {
  if (includeUserCompanyLimits) {
    return createCounterpartiesWithLimitsPipe(tenant, profile, filter);
  } else {
    return createFilteredBaseCounterpartiesPipe(profile, filter);
  }
};

type UserCompanySorter = (
  a: UserCompanyWithLimits,
  b: UserCompanyWithLimits
) => number;

const sortedUserCompanies = (
  items: UserCompanyWithLimits[],
  sorter: UserCompanySorter
) =>
  sortByMany(
    items,
    sorter,
    ascSorter((item: UserCompanyWithLimits) => item.time)
  );

export const createSortedCounterpartiesPipe = (
  sorter: UserCompanySorter,
  tenant: string,
  profile: UserProfile,
  options: CounterpartiesFilter & { includeUserCompanyLimits?: boolean }
) => {
  return createCounterpartiesPipe(tenant, profile, options).pipe((items) =>
    sortedUserCompanies(items, sorter)
  );
};
