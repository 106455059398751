/* eslint-disable max-lines */
// This file is generated, don't edit

import { setWebIconMap } from "./theme/generic-icon.tsx";
import AddCircleOutlineIcon from "../icons/add_circle_outline.svg?react"
import ApartmentIcon from "../icons/apartment.svg?react"
import AssignmentIcon from "../icons/assignment.svg?react"
import AssignmentLateIcon from "../icons/assignment_late.svg?react"
import AssignmentTurnedInIcon from "../icons/assignment_turned_in.svg?react"
import AsteriskIcon from "../icons/asterisk.svg?react"
import BackIcon from "../icons/back.svg?react"
import BellIcon from "../icons/bell.svg?react"
import BoldIcon from "../icons/bold.svg?react"
import BriefcaseIcon from "../icons/briefcase.svg?react"
import BuildingIcon from "../icons/building.svg?react"
import CarIcon from "../icons/car.svg?react"
import ChatIcon from "../icons/chat.svg?react"
import ChatsIcon from "../icons/chats.svg?react"
import CheckIcon from "../icons/check.svg?react"
import ChevronBottomIcon from "../icons/chevron_bottom.svg?react"
import ChevronLeftIcon from "../icons/chevron_left.svg?react"
import ChevronRightIcon from "../icons/chevron_right.svg?react"
import CircleCheckOutlineIcon from "../icons/circle_check_outline.svg?react"
import ControlPointDuplicateIcon from "../icons/control_point_duplicate.svg?react"
import CopyIcon from "../icons/copy.svg?react"
import CutIcon from "../icons/cut.svg?react"
import DateRangeIcon from "../icons/date_range.svg?react"
import DeleteForeverIcon from "../icons/delete_forever.svg?react"
import DocumentsLargeIcon from "../icons/documents_large.svg?react"
import EditFileIcon from "../icons/edit_file.svg?react"
import ExitToAppIcon from "../icons/exit_to_app.svg?react"
import EyeIcon from "../icons/eye.svg?react"
import EyeOffIcon from "../icons/eye_off.svg?react"
import FileBlankOutlineIcon from "../icons/file_blank_outline.svg?react"
import FilePresentIcon from "../icons/file_present.svg?react"
import FolderIcon from "../icons/folder.svg?react"
import GearIcon from "../icons/gear.svg?react"
import GuaranteeIcon from "../icons/guarantee.svg?react"
import HamburgerIcon from "../icons/hamburger.svg?react"
import HeadingH1Icon from "../icons/heading_h1.svg?react"
import HeadingH2Icon from "../icons/heading_h2.svg?react"
import HeadingH3Icon from "../icons/heading_h3.svg?react"
import HomeIcon from "../icons/home.svg?react"
import HouseIcon from "../icons/house.svg?react"
import ImageIcon from "../icons/image.svg?react"
import InfoCircleOutlineIcon from "../icons/info_circle_outline.svg?react"
import ItalicIcon from "../icons/italic.svg?react"
import LinkIcon from "../icons/link.svg?react"
import ListUlIcon from "../icons/list_ul.svg?react"
import LogoAwaSmallIcon from "../icons/logo_awa_small.svg?react"
import LogoBrioSmallIcon from "../icons/logo_brio_small.svg?react"
import LogoMatchfinColoredIcon from "../icons/logo_matchfin_colored.svg?react"
import LogoMatchfinSmallIcon from "../icons/logo_matchfin_small.svg?react"
import LogoRosvalSmallIcon from "../icons/logo_rosval_small.svg?react"
import LogoStcSmallIcon from "../icons/logo_stc_small.svg?react"
import LogoZofingenSmallIcon from "../icons/logo_zofingen_small.svg?react"
import MagnifyingGlassIcon from "../icons/magnifying_glass.svg?react"
import MailOutlineIcon from "../icons/mail_outline.svg?react"
import MatrixIcon from "../icons/matrix.svg?react"
import OffOutlineCloseIcon from "../icons/off_outline_close.svg?react"
import OnboardingIcon from "../icons/onboarding.svg?react"
import PaperPlaneRightIcon from "../icons/paper_plane_right.svg?react"
import PasteIcon from "../icons/paste.svg?react"
import PhoneIcon from "../icons/phone.svg?react"
import PhoneOutlineIcon from "../icons/phone_outline.svg?react"
import PlayIcon from "../icons/play.svg?react"
import PowerIcon from "../icons/power.svg?react"
import ProjectorScreenIcon from "../icons/projector_screen.svg?react"
import RedoIcon from "../icons/redo.svg?react"
import ReportIcon from "../icons/report.svg?react"
import ShareIcon from "../icons/share.svg?react"
import StackedLineChartIcon from "../icons/stacked_line_chart.svg?react"
import SupervisorAccountIcon from "../icons/supervisor_account.svg?react"
import TableAddIcon from "../icons/table_add.svg?react"
import TurnMobileIcon from "../icons/turn_mobile.svg?react"
import UnderlineIcon from "../icons/underline.svg?react"
import UndoIcon from "../icons/undo.svg?react"
import UnlinkIcon from "../icons/unlink.svg?react"
import UserIcon from "../icons/user.svg?react"
import UsersIcon from "../icons/users.svg?react"
import WarningOutlineIcon from "../icons/warning_outline.svg?react"
import WhatsappIcon from "../icons/whatsapp.svg?react"
import XIcon from "../icons/x.svg?react"

setWebIconMap({
  AddCircleOutlineIcon,
  ApartmentIcon,
  AssignmentIcon,
  AssignmentLateIcon,
  AssignmentTurnedInIcon,
  AsteriskIcon,
  BackIcon,
  BellIcon,
  BoldIcon,
  BriefcaseIcon,
  BuildingIcon,
  CarIcon,
  ChatIcon,
  ChatsIcon,
  CheckIcon,
  ChevronBottomIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  CircleCheckOutlineIcon,
  ControlPointDuplicateIcon,
  CopyIcon,
  CutIcon,
  DateRangeIcon,
  DeleteForeverIcon,
  DocumentsLargeIcon,
  EditFileIcon,
  ExitToAppIcon,
  EyeIcon,
  EyeOffIcon,
  FileBlankOutlineIcon,
  FilePresentIcon,
  FolderIcon,
  GearIcon,
  GuaranteeIcon,
  HamburgerIcon,
  HeadingH1Icon,
  HeadingH2Icon,
  HeadingH3Icon,
  HomeIcon,
  HouseIcon,
  ImageIcon,
  InfoCircleOutlineIcon,
  ItalicIcon,
  LinkIcon,
  ListUlIcon,
  LogoAwaSmallIcon,
  LogoBrioSmallIcon,
  LogoMatchfinColoredIcon,
  LogoMatchfinSmallIcon,
  LogoRosvalSmallIcon,
  LogoStcSmallIcon,
  LogoZofingenSmallIcon,
  MagnifyingGlassIcon,
  MailOutlineIcon,
  MatrixIcon,
  OffOutlineCloseIcon,
  OnboardingIcon,
  PaperPlaneRightIcon,
  PasteIcon,
  PhoneIcon,
  PhoneOutlineIcon,
  PlayIcon,
  PowerIcon,
  ProjectorScreenIcon,
  RedoIcon,
  ReportIcon,
  ShareIcon,
  StackedLineChartIcon,
  SupervisorAccountIcon,
  TableAddIcon,
  TurnMobileIcon,
  UnderlineIcon,
  UndoIcon,
  UnlinkIcon,
  UserIcon,
  UsersIcon,
  WarningOutlineIcon,
  WhatsappIcon,
  XIcon,
});
