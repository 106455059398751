/* DON'T EDIT THIS FILE: edit original and run build again */ import { mapArrayToObject } from "../core/map-array-to-object.ts";
import { getTenantHandler } from "../tenant-collection/tenant.ts";
import { matchfinTenant } from "../tenant-matchfin-code/matchfin-tenant-code.ts";
import { investorUserCompanyTypeId } from "../user-company-type-const/user-company-type-const.ts";
import { roleLabels } from "./role-labels.ts";
import { Roles, RolesMap } from "./schema.ts";

const commonInternalRoles = [
  Roles.tenantAdmin,
  Roles.complianceOfficer,
  Roles.analyst,
  Roles.salesManager,
  Roles.sales,
  Roles.backOffice,
  Roles.headOfProducers,
  Roles.producer,
];

const fciSpecificInternalRoles = [Roles.portfolioManager];

const matchfinSpecificInternalRoles = [
  Roles.matchfinAnalyst,
  Roles.matchfinSales,
];

const financerRoles = new Set([
  Roles.investor,
  Roles.guestSgr,
  Roles.guestBank,
]);

const matchfinEndClientRoles = [Roles.matchfinEndClientUser];

const endClientRoles = [Roles.endClientUser];

const crossTenantAdminRoles = [Roles.admin];

export const getInternalRoles = (roles: RolesMap, tenant: string): Roles[] => {
  // FIXME: this should be TenantHandler's resp.
  const tenantHandler = getTenantHandler(tenant);
  const out: Roles[] = [...commonInternalRoles];
  if (tenant === matchfinTenant && roles[Roles.admin]) {
    out.push(...matchfinSpecificInternalRoles);
  }
  if (tenantHandler.getUserCompanyType() === investorUserCompanyTypeId) {
    out.push(...fciSpecificInternalRoles);
  }
  return out;
};

export const getExternalRoles = (roles: RolesMap, tenant: string): Roles[] => {
  const out: Roles[] = [...endClientRoles];
  if (tenant === matchfinTenant && roles[Roles.admin]) {
    out.push(...matchfinEndClientRoles, ...financerRoles);
  }
  return out;
};

export const getFinancerRoles = (): Roles[] => Array.from(financerRoles);

export const getEndClientRoles = (): Roles[] => endClientRoles;

export const isRoleInternal = (
  role: Roles,
  userRoles: RolesMap,
  tenant: string
): boolean => getInternalRoles(userRoles, tenant).includes(role);

export const isRoleCrossTenantAdmin = (role: Roles): boolean =>
  crossTenantAdminRoles.includes(role);

export const isFinancerRole = (role: Roles): boolean => financerRoles.has(role);

export const isEndClientRole = (role: Roles): boolean =>
  endClientRoles.includes(role) || matchfinEndClientRoles.includes(role);

export const isMatchfinSpecificRole = (role: Roles): boolean =>
  matchfinSpecificInternalRoles.includes(role) ||
  matchfinEndClientRoles.includes(role) ||
  financerRoles.has(role);

export const allRoles = Object.keys(roleLabels) as Roles[];

export const rolesSortingIndex = mapArrayToObject(
  allRoles.entries(),
  ([index, role]) => [role as Roles, index]
);

export const getRolesAssignableByAdmin = (
  assignerRoles: RolesMap,
  tenant: string
): Roles[] => {
  const tenantHandler = getTenantHandler(tenant);
  const out: Roles[] = [];
  if (assignerRoles[Roles.admin] || assignerRoles[Roles.tenantAdmin]) {
    out.push(...commonInternalRoles);
    if (tenantHandler.getUserCompanyType() === investorUserCompanyTypeId) {
      out.push(...fciSpecificInternalRoles);
    }
    if (tenant === matchfinTenant && assignerRoles[Roles.admin]) {
      out.push(
        ...matchfinSpecificInternalRoles,
        ...financerRoles,
        ...endClientRoles
      );
    }
  }
  return out;
};
