/* DON'T EDIT THIS FILE: edit original and run build again */ import { LabeledAttachment } from "../../attachment-type/attachment-ref-type.ts";
import { AttachmentItemMap } from "../../attachment-ui/attachment-item.ts";
import { isEmptyObject } from "../../core/is-empty-object.ts";
import { spaceship } from "../../core/spaceship.ts";
import { BaseLiveField, LiveFieldSpec } from "../../live-form/field-spec.ts";
import { LiveData } from "../../live-form/types.ts";
import { multipleFileLiveFieldType } from "./type.ts";

export const getSortedMultipleFileItems = (items: AttachmentItemMap) => {
  const out = Object.values(items);
  out.sort((a: any, b: any) => spaceship(a.time, b.time));
  return out;
};

type MultipleFileLiveFieldSpec = LiveFieldSpec & {
  uploadLabel?: string;
};

export class MultipleFileLiveField extends BaseLiveField {
  private uploadLabel: string;

  constructor(spec: MultipleFileLiveFieldSpec) {
    super(spec, multipleFileLiveFieldType);
    this.uploadLabel = spec.uploadLabel || "Subir documentos";
  }

  isEmpty(liveData: LiveData) {
    const value = this.getValue(liveData) as
      | undefined
      | null
      | Record<string, any>;
    return !value || isEmptyObject(value);
  }

  getUnprefixedAttachments(
    liveData: LiveData,
    label: string,
    key: string
  ): LabeledAttachment[] {
    return getSortedMultipleFileItems(
      (this.getValue(liveData) ?? {}) as AttachmentItemMap
    ).map((item: any) => ({
      label,
      key: (key ? key + "." : "") + item.id,
      attachment: item.attachment,
    }));
  }

  getUploadLabel() {
    return this.uploadLabel;
  }
}
