/* DON'T EDIT THIS FILE: edit original and run build again */ import { fetchDoc } from "../firebase/fetch-doc.ts";
import {
  createFirestoreCollection,
  FirestoreCollection,
  FirestoreDocument,
} from "../firebase/firestore-wrappers.ts";
import { userCompaniesColName } from "./col-name.ts";
import {
  UserCompanyFavoriteLines,
  UserCompanyType,
} from "./user-company-type.ts";

export const getUserCompaniesCol = (): FirestoreCollection<UserCompanyType> =>
  createFirestoreCollection<UserCompanyType>(userCompaniesColName);

export const getUserCompanyDoc = (
  entityId: string
): FirestoreDocument<UserCompanyType> => getUserCompaniesCol().doc(entityId);

export const maybeFetchUserCompany = (
  entityId: string
): Promise<UserCompanyType | undefined> =>
  fetchDoc(getUserCompanyDoc(entityId));

export const getUserCompanyFavoriteLinesCol = (
  userCompanyId: string
): FirestoreCollection<UserCompanyFavoriteLines> =>
  getUserCompanyDoc(userCompanyId).collection<UserCompanyFavoriteLines>(
    "favoriteLines"
  );

export const getUserCompanyFavoriteLinesDoc = (
  userCompanyId: string,
  lineId: string
): FirestoreDocument<UserCompanyFavoriteLines> =>
  getUserCompanyFavoriteLinesCol(userCompanyId).doc(lineId);

export const isUserCompanyForeign = (userCompany: UserCompanyType): boolean =>
  userCompany?.cuit === null;
