/* DON'T EDIT THIS FILE: edit original and run build again */ import { AlycTenantHandler } from "../../tenant-handlers/alyc-tenant-handler.ts";
import { trinidadCapitalTenant } from "./code.ts";
import { trinidadCapitalFullLabel } from "./full-label.ts";
import { trinidadCapitalLabel } from "./label.ts";

class TrinidadCapitalTenantHandler extends AlycTenantHandler {
  getCode(): string {
    return trinidadCapitalTenant;
  }

  getPathLabel(): string | null {
    return "trinidad-capital";
  }

  getLabel(): string {
    return trinidadCapitalLabel;
  }

  getFullLabel(): string {
    return trinidadCapitalFullLabel;
  }

  getNotificationsFrom(): string {
    return "no-responder@matchfin.ar";
  }

  getCuit(): string | null {
    return "30-71487151-6";
  }

  getCnvRegistryId(): string | null {
    return null;
  }

  publishesInMarketplace(): boolean {
    return true;
  }
}

const trinidadCapitalTenantHandler = new TrinidadCapitalTenantHandler();

export default trinidadCapitalTenantHandler;
