/* DON'T EDIT THIS FILE: edit original and run build again */ import { createDateFromUtcDateOnlyString } from "../../core/utc-date.ts";
import { formatUtcDate } from "../../format/format.ts";
import {
  LiveFieldInput,
  LiveFieldInputParam,
  LiveFieldRenderer,
  LiveFieldViewer,
} from "../../live-form/ui/types.ts";
import { useLiveAfterBlurTextInput } from "../../live-form/ui/use-live-after-blur-text-input.tsx";
import { Div } from "../../pdf-wrapper/pdf-wrapper.tsx";
import { DateInput } from "../../ui/form/date-input.tsx";
import { dateLiveFieldType } from "./type.ts";

const Input: LiveFieldInput = (fieldInfo: LiveFieldInputParam) => (
  <DateInput {...useLiveAfterBlurTextInput(fieldInfo)} />
);

const Viewer: LiveFieldViewer = ({ liveCell }) => {
  const fieldValue = (liveCell.getValue() as string) ?? null;
  if (!fieldValue) {
    return null;
  }
  return (
    <Div>{formatUtcDate(createDateFromUtcDateOnlyString(fieldValue))}</Div>
  );
};

export const dateLiveFieldRenderers: LiveFieldRenderer = {
  type: dateLiveFieldType,
  Input,
  Viewer,
};
