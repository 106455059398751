/* DON'T EDIT THIS FILE: edit original and run build again */ import { LiveFieldset } from "../../live-form/fieldset.ts";
import { LiveRecord } from "../../live-form/live-record.ts";
import { LiveData, LiveFormRecordValue } from "../../live-form/types.ts";
import { useReducer } from "react";

type State = {
  recordValue: LiveFormRecordValue;
  showErrors: boolean;
};

enum ActionType {
  SetShowErrors = "setShowErrors",
  SetFieldValue = "setFieldValue",
}

type ActionSetShowErrors = {
  type: ActionType.SetShowErrors;
  show: boolean;
};

type ActionSetFieldValue = {
  type: ActionType.SetFieldValue;
  field: string;
  value: any;
};

type Action = ActionSetShowErrors | ActionSetFieldValue;

const reducer = (state: State, action: Action) => {
  const { type } = action;
  if (type === "setShowErrors") {
    const { show } = action;
    return { ...state, showErrors: show };
  } else if (type === "setFieldValue") {
    const { field, value } = action;
    const recordValue = state.recordValue;
    if (value === recordValue[field]) {
      return state;
    }
    return { ...state, recordValue: { ...recordValue, [field]: value } };
  } else {
    throw new Error("unknownActionType: " + type);
  }
};

export const useLiveFormState = ({
  defaultRecordValue = {},
  defaultShowErrors = false,
  fieldset,
  liveData = {},
}: {
  defaultRecordValue: LiveFormRecordValue | (() => LiveFormRecordValue);
  defaultShowErrors: boolean;
  fieldset: LiveFieldset;
  liveData?: LiveData;
}) => {
  const [{ recordValue, showErrors }, dispatch] = useReducer(
    reducer,
    null,
    () => ({
      recordValue:
        defaultRecordValue instanceof Function
          ? defaultRecordValue()
          : defaultRecordValue,
      showErrors: defaultShowErrors,
    })
  );
  return {
    recordValue,
    saveField: (field: string, value: any) => {
      dispatch({ type: ActionType.SetFieldValue, field, value });
    },
    showErrors,
    setShowErrors: (show: boolean) =>
      dispatch({ type: ActionType.SetShowErrors, show }),
    getError: () => {
      const liveRecord = new LiveRecord(fieldset, {
        ...liveData,
        recordValue,
      });
      return liveRecord.getError();
    },
  };
};
