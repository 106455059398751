/* DON'T EDIT THIS FILE: edit original and run build again */ import { usePipe } from "../firebase/use-pipe.ts";
import { getAccountPipe } from "./account-pipe.front.ts";

export const useAccountAndLoadIt = () => {
  const [loading, data, error] = usePipe(getAccountPipe, [], false);
  if (loading) {
    return [true, null, null, null, null, null] as const;
  }
  if (error !== null) {
    throw new Error("errorUsingAccount: " + error);
  }
  return [
    !!data.updating,
    data.account,
    data.profile,
    data.roles,
    data.privateProfile,
    data.firstTimeModals,
  ] as const;
};
