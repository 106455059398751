/* DON'T EDIT THIS FILE: edit original and run build again */ /* eslint-disable max-lines */
import {
  adminRole,
  analystRole,
  backOfficeRole,
  canFilterbyAlycInvestorRole,
  complianceOfficerRole,
  endClientUserRole,
  guestBankRole,
  guestSgrRole,
  headOfProducersRole,
  investorRole,
  matchfinAnalystRole,
  matchfinEndClientUserRole,
  matchfinSalesRole,
  pendingApprovalRole,
  portfolioManagerRole,
  producerRole,
  salesManagerRole,
  salesRole,
  tenantAdminRole,
  tenantRealmPendingApprovalRole,
} from "../permission/all-roles.ts";

// glossary
//   access: gross level access: view, edit and possibly also list and add
//   add: add new items (often implied)
//   list: see in listing (often implied)
//   view: see in its own full page (either grouped or full info)
//   edit: edit (fully edit, often implied)

export const canDeleteLineComments = "canDeleteLinesComments";
export const canDeleteOnboardingComments = "canDeleteOnboardingComments";
export const canDeleteRiskMatrixComments = "canDeleteRiskMatrixComments";
export const canDeleteSgrReportComments = "canDeleteSgrReportComments";

export const canListUserCompanies = "canListUserCompanies";
export const canAccessCounterpartiesPage = "canAccessCounterpartiesPage";

// object permission

export const canRequestObjectPermission = "canRequestObjectPermission";

// users

export const canAccessUsers = "canAccessUsers";
export const canSeeOtherUsersRoles = "canSeeOtherUsersRoles";
export const canCreateInviteLinks = "canCreateInviteLinks";
export const canCreateProducerInviteLinks = "canCreateProducerInviteLinks";
export const canEditProfile = "canEditProfile";
export const canAddEndClient = "canAddEndClient";
export const canBeAssignedAsProducerUid = "canBeAssignedAsProducerUid";
export const canBeAssignedAsAssigneeUid = "canBeAssignedAsAssigneeUid";
export const canListAllUsers = "canListAllUsers";
export const canListDisabledUsers = "canListDisabledUsers";
export const canListInternalTenantProfiles = "canListInternalTenantProfiles";
export const canListExternalTenantProfiles = "canListExternalTenantProfiles";
export const canListProducerTenantProfiles = "canListProducerTenantProfiles";
export const canListTenantProfiles = "canListTenantProfiles";
export const canViewTenantProfiles = "canViewTenantProfiles";
export const canListProducerAssignedEndClientProfiles =
  "canListProducerAssignedEndClientProfiles";
export const canListAssignedEndClientProfiles =
  "canListAssignedEndClientProfiles";

// base folder

export const canEditBaseFolderAssignee = "canEditAssignee";
export const canEditBaseFolderProducer = "canEditProducer";
export const canDeleteBaseFolderAssignee = "canDeleteBaseFolderAssignee";
export const canDeleteBaseFolderProducer = "canDeleteBaseFolderProducer";
export const canAccessBaseFolders = "canAccessBaseFolders";
export const canAccessOtherUsersFolders = "canAccessOtherUsersFolders";
export const canAccessOtherProducersFolders = "canAccessProducersFolders";
export const canAccessFoldersSharedWithMfAnalysts =
  "canAccessFoldersSharedWithMfAnalysts";
export const canAccessFoldersSharedWithInternalAnalysts =
  "canAccessFoldersSharedWithInternalAnalysts";

// other users

export const canDisableOrEnableOtherUsers = "canDisableOrEnableOtherUsers";
export const canEditOtherUsersProfile = "canEditOtherUsersProfile";
export const canEditOtherUsersRoles = "canEditOtherUsersRoles";
export const canMigrateFinancerUsers = "canMigrateFinancerUsers";

// tenants

export const canChangeToAllowedTenant = "canChangeToAllowedTenant";
export const canChangeToAnyTenant = "canChangeToAnyTenant";
export const canSeeOtherEndClients = "canSeeOtherEndClients";

// controlPanel

export const canAccessControlPanel = "canAccessControlPanel";
export const canAccessMatchfinControlPanel = "canAccessMatchfinControlPanel";

// lines

export const canAccessLines = "canAccessLines";
export const canListLines = "canListLines";
export const canAccessLinesSeparatePagesView =
  "canAccessLinesSeparatePagesView";
export const canAccessLinesPresentationView = "canAccessLinesPresentationView";
export const canAccessLinesPresentationViewPage =
  "canAccessLinesPresentationViewPage";
export const canAccessLinesPresentationViewOnPresentationStatus =
  "canAccessLinesPresentationViewOnPresentationStatus";
export const canOpenMultipleLines = "canOpenMultipleLines";
export const canAnalyzeLine = "canAnalyzeLine";
export const canAccessLineAnalisis = "canAccessLineAnalisis";
export const accessAdminLine = "accessAdminLine";
export const canViewFullInfoLineAdminSections =
  "canViewFullInfoLineAdminSections";
export const canViewPresentationLineAdminSections =
  "canViewPresentationLineAdminSections";
export const canViewInternalLineStatusSection =
  "canViewInternalLineStatusSection";
export const canUseLineFilters = "canUseLineFilters";
export const canEditOtherUserLines = "canEditOtherUserLines";
export const canEditOtherSalesLines = "canEditOtherSalesLines";
export const canEditOtherProducerLines = "canEditOtherProducerLines";
export const canEditLinesWithoutAssigneeOrProducer =
  "canEditLinesWithoutAssigneeOrProducer";
export const canViewInternalLineCommonSections =
  "canViewInternalLineCommonSections";
export const canListLinesWithCommonStatuses = "canListLinesWithCommonStatuses";
export const canViewLinesWithCommonStatuses = "canViewLinesWithCommonStatuses";
export const canListLinesWithPresentationStatus =
  "canListLinesWithPresentationStatus";
export const canViewLinesWithPresentationStatus =
  "canViewLinesWithPresentationStatus";
export const canListLinesWithInfoRequestStatus =
  "canListLinesWithInfoRequestStatus";
export const canViewLinesWithInfoRequestStatus =
  "canViewLinesWithInfoRequestStatus";
export const canListLinesWithInitialLoadStatus =
  "canListLinesWithInitialLoadStatus";
export const canViewLinesWithInitialLoadStatus =
  "canViewLinesWithInitialLoadStatus";
export const canListLinesWithDataLoadedStatus =
  "canListLinesWithDataLoadedStatus";
export const canViewLinesWithDataLoadedStatus =
  "canViewLinesWithDataLoadedStatus";
export const canAccessRiskPreview = "canAccessRiskPreview";
export const canListNonSmeLines = "canListNonSmeLines";
export const canViewLineInstruments = "canViewLineInstruments";
export const canViewLineFinancingNeeds = "canViewLineFinancingNeeds";
export const canEditOpinionReportSection = "canEditOpinionReportSection";
export const canViewOpinionReportSection = "canViewOpinionReportSection";
export const canViewPresentationOpinionReportSection =
  "canViewPresentationOpinionReportSection";
export const canViewFullInfoOpinionReportSection =
  "canViewFullInfoOpinionReportSection";
export const canAddConceptGuarantor = "canAddConceptGuarantor";
export const canEditSubmittedLines = "canEditSubmittedLines";
export const canViewContactInfoSection = "canViewContactInfoSection"; // FIXME: deprecated ?
export const canViewInitialDocumentsSection = "canViewInitialDocumentsSection";
export const canCreateLinesInSurveyingStatus =
  "canCreateLinesInSurveyingStatus";
export const canViewNosisSection = "canViewNosisSection";
export const canViewLinePreApprovals = "canViewLinePreApprovals";
export const canCopyLinesToOtherTenants = "canCopyLinesToOtherTenants";
export const canTransitionToAnalysisStatus = "canTransitionToAnalysisStatus";
export const canEditLineOfficeBranch = "canEditLineOfficeBranch";
export const canRequestMatchfinAnalystsReview =
  "canRequestMatchfinAnalystsReview";
export const canUsePreApprovalAnalysis = "canUsePreApprovalAnalysis";
export const canSeePreApprovalAnalysisDetail =
  "canSeePreApprovalAnalysisDetail";
export const canAccessLineDataLoadPages = "canAccessLineDataLoadPages";
export const canViewLineHasChosenAlyc = "canViewLineHasChosenAlyc";
export const canViewLineChooseYourAlycSection =
  "canViewLineChooseYourAlycSection";

// marketplace

export const canAccessMarketplace = "canAccessMarketplace";
export const canViewCounterpartiesLog = "canViewCounterpartiesLog";
export const canViewUsersInCounterpartiesLog =
  "canViewUsersInCounterpartiesLog";
export const canPublishInMarketplace = "canPublishInMarketplace";
export const canFilterLinesByAlyc = "canFilterLinesByAlyc";

// line inbox & chat

export const canAccessLineInbox = "canAccessLineInbox";
export const canEditLineSharings = "canEditLineSharings";
export const canViewGrantedLineSharings = "canViewGrantedLineSharings";
// of course, only in the same counterparty/tenant
export const canAccessOtherUsersLineSharings =
  "canAccessOtherUsersLineSharings";
export const canAccessOtherProducersLineSharings =
  "canAccessOtherProducersLineSharings";

// onboardings

export const canAccessOnboardingAdminSections =
  "canAccessOnboardingAdminSections";
export const canAccessOnboardingLog = "canAccessOnboardingLog";
export const canAccessOnboardings = "canAccessOnboardings";
export const canAccessRedlinkData = "canAccessRedlinkData";
export const canAccessRenaperData = "canAccessRenaperData";
export const canAddCommentForAnySource = "canAddCommentForAnySource";
export const canAddCommentOnboarding = "canAddCommentOnboarding";
export const canAddOnboardings = "canAddOnboardings";
export const canChangeToAllStatuses = "canChangeToAllStatuses";
export const canCreateVideoSelfieRequests = "canCreateVideoSelfieRequests";
export const canDeleteVideoSelfies = "canDeleteVideoSelfies";
export const canEditOtherProducerOnboardings =
  "canEditOtherProducerOnboardings";
export const canEditOtherSalesOnboardings = "canEditOtherSalesOnboardings";
export const canEditOnboardingsWithoutAssigneeOrProducer =
  "canEditOnboardingsWithoutAssigneeOrProducer";
export const canEditOtherUsersOnboardings = "canEditOtherUsersOnboardings";
export const canEditSubmittedOnboardings = "canEditSubmittedOnboardings";
export const canListOnboardings = "canListOnboardings";
export const canPublishToAune = "canPublishToAune";
export const canPublishToEsco = "canPublishToEsco";
export const canSubmitOthersContainers = "canSubmitOthersContainers";
export const canViewOnboardingsWithCommonStatuses =
  "canViewOnboardingsWithCommonStatuses";
export const canViewOnboardingsWithInfoRequestStatus =
  "canViewOnboardingsWithInfoRequestStatus";
export const canViewOnboardingsWithInitialLoadStatus =
  "canViewOnboardingsWithInitialLoadStatus";
export const canViewVideoSelfies = "canViewVideoSelfies";
export const canBackupOnboardings = "canBackupOnboardings";

// financer status
export const canViewFinancerStatuses = "canViewFinancerStatuses";

// approval of users

export const canApproveRoleRequests = "canApproveRoleRequests";
export const canApproveEndClientRoleRequests =
  "canApproveEndClientRoleRequests";
export const canApproveProducerFolderEndClientRoleRequests =
  "canApproveProducerFolderEndClientRoleRequests";
export const canApproveAssignedFoldersEndClientRoleRequests =
  "canApproveAssignedFoldersEndClientRoleRequests";
export const canApproveEndClientAccessRequests =
  "canApproveEndClientAccessRequests";
export const canApproveProducerFolderEndClientAccessRequests =
  "canApproveProducerFolderEndClientAccessRequests";
export const canApproveAssignedFoldersEndClientAccessRequests =
  "canApproveAssignedFoldersEndClientAccessRequests";

// assigned entities

export const canViewOwnAssignedEntities = "canViewOwnAssignedEntities";
export const canListAllAssignedEntities = "canListOtherUsersAssignedEntities";
export const canListProducerAssignedEntities =
  "canListProducerAssignedEntities";

// helpers
export const canBeAssignedAsHelperToAnAssignee =
  "canBeAssignedAsHelperToAnAssignee";
export const canAssignHelpersToAnAssignee = "canAssignHelpersToAnAssignee";

// admin pages

export const canListUrls = "canListUrls";
export const canDebugPermissions = "canDebugPermissions";

// risk matrix

export const canViewRiskMatrix = "canViewRiskMatrix";

// identity

export const canViewAdminsIdentity = "canViewAdminsIdentity";
export const canViewMatchfinAnalystsIdentity =
  "canViewMatchfinAnalystsIdentity";

// granted lines
export const canAccessGrantedLines = "canAccessGrantedLines";
export const canAccessReceivedLinesInNonFciTenants =
  "canAccessReceivedLinesInNonFciTenants";
export const canAccessReceivedLines = "canAccessReceivedLines";

// live risk
export const canViewLiveRisk = "canViewLiveRisk";

// sgr report
export const canViewSgrReport = "canViewSgrReport";
export const canEditSgrReport = "canEditSgrReport";

// tenant settings
export const canViewTenantSettings = "canViewTenantSettings";
export const canEditTenantSettings = "canEditTenantSettings";
export const canViewSemaphoreSettings = "canViewSemaphoreSettings";
export const canEditSemaphoreSettings = "canEditSemaphoreSettings";

// mercado pago
export const canCreateRiskAnalysisPaymentOrder =
  "canCreateRiskAnalysisPaymentOrder";

const salesAndProducerCommonRolePermissions = [
  accessAdminLine,
  canAccessBaseFolders,
  canAccessGrantedLines,
  canAccessLineInbox,
  canAccessReceivedLinesInNonFciTenants,
  canAccessLines,
  canAccessLinesPresentationView,
  canAccessLinesPresentationViewPage,
  canAccessLinesSeparatePagesView,
  canAccessMarketplace,
  canAccessOnboardingAdminSections,
  canAccessOnboardingLog,
  canAccessOnboardings,
  canAccessRedlinkData,
  canAccessUsers,
  canAccessCounterpartiesPage,
  canAddCommentForAnySource,
  canAddConceptGuarantor,
  canAddOnboardings,
  canAnalyzeLine,
  canAccessLineAnalisis,
  canApproveAssignedFoldersEndClientRoleRequests,
  canApproveAssignedFoldersEndClientAccessRequests,
  canChangeToAllowedTenant,
  canDeleteLineComments,
  canCreateVideoSelfieRequests,
  canEditLineSharings,
  canEditProfile,
  canEditSubmittedLines,
  canEditSubmittedOnboardings,
  canViewOwnAssignedEntities,
  canListAssignedEndClientProfiles,
  canListLines,
  canListLinesWithCommonStatuses,
  canListLinesWithInfoRequestStatus,
  canListLinesWithInitialLoadStatus,
  canListLinesWithPresentationStatus,
  canListNonSmeLines,
  canListOnboardings,
  canListUserCompanies,
  canPublishInMarketplace,
  canRequestMatchfinAnalystsReview,
  canRequestObjectPermission,
  canSeeOtherUsersRoles,
  canUseLineFilters,
  canViewContactInfoSection,
  canViewCounterpartiesLog,
  canViewGrantedLineSharings,
  canViewInitialDocumentsSection,
  canViewInternalLineCommonSections,
  canViewInternalLineStatusSection,
  canViewLineFinancingNeeds,
  canViewLineInstruments,
  canViewLinePreApprovals,
  canViewLinesWithCommonStatuses,
  canViewLinesWithInfoRequestStatus,
  canViewLinesWithInitialLoadStatus,
  canViewLinesWithDataLoadedStatus,
  canViewLinesWithPresentationStatus,
  canViewOnboardingsWithCommonStatuses,
  canViewOnboardingsWithInfoRequestStatus,
  canViewOnboardingsWithInitialLoadStatus,
  canViewPresentationLineAdminSections,
  canViewPresentationOpinionReportSection,
  canViewTenantProfiles,
  canViewVideoSelfies,
  canOpenMultipleLines,
  canViewFinancerStatuses,
  canViewLiveRisk,
  canViewSgrReport,
  canEditLineOfficeBranch,
];

const fullOnboardingPermissions = [
  canPublishToEsco,
  canPublishToAune,
  canAccessBaseFolders,
  canAccessOnboardingAdminSections,
  canAccessOnboardingLog,
  canAccessOnboardings,
  canAccessOtherUsersFolders,
  canAccessRedlinkData,
  canAccessRenaperData,
  canAddCommentOnboarding,
  canAddOnboardings,
  canCreateVideoSelfieRequests,
  canDeleteVideoSelfies,
  canEditSubmittedOnboardings,
  canListOnboardings,
  canViewOnboardingsWithCommonStatuses,
  canViewOnboardingsWithInfoRequestStatus,
  canViewOnboardingsWithInitialLoadStatus,
  canViewVideoSelfies,
  canBackupOnboardings,
];

const financerPermissions = [
  accessAdminLine,
  canAccessLineInbox,
  canAccessReceivedLines,
  canAccessLinesPresentationView,
  canAccessLinesPresentationViewPage,
  canAccessMarketplace,
  canAccessOtherUsersLineSharings,
  canAnalyzeLine,
  canAccessLineAnalisis,
  canEditProfile,
  canListLinesWithPresentationStatus,
  canRequestObjectPermission,
  canUseLineFilters,
  canViewFullInfoLineAdminSections,
  canViewFullInfoOpinionReportSection,
  canViewInternalLineCommonSections,
  canViewInternalLineStatusSection,
  canViewLineFinancingNeeds,
  canViewLineInstruments,
  canViewLinesWithPresentationStatus,
  canViewPresentationLineAdminSections,
  canViewPresentationOpinionReportSection,
  canViewLiveRisk,
  canViewFinancerStatuses,
  canViewSgrReport,
];

const analystPermissions = [
  accessAdminLine,
  canAccessBaseFolders,
  canAccessLineInbox,
  canAccessReceivedLines,
  canAccessLines,
  canAccessLinesPresentationView,
  canAccessLinesPresentationViewPage,
  canAccessLinesSeparatePagesView,
  canAccessMarketplace,
  canListUserCompanies,
  canAddCommentForAnySource,
  canAddConceptGuarantor,
  canAnalyzeLine,
  canAccessLineAnalisis,
  canCreateLinesInSurveyingStatus,
  canDeleteLineComments,
  canEditProfile,
  canEditSubmittedLines,
  canEditSubmittedOnboardings,
  canViewTenantSettings,
  canEditTenantSettings,
  canViewSemaphoreSettings,
  canEditSemaphoreSettings,
  canListLines,
  canListLinesWithCommonStatuses,
  canListLinesWithInitialLoadStatus,
  canListLinesWithInfoRequestStatus,
  canListLinesWithPresentationStatus,
  canListNonSmeLines,
  canListTenantProfiles,
  canSeeOtherUsersRoles,
  canTransitionToAnalysisStatus,
  canUseLineFilters,
  canViewContactInfoSection,
  canViewFinancerStatuses,
  canViewFullInfoLineAdminSections,
  canViewFullInfoOpinionReportSection,
  canViewGrantedLineSharings,
  canViewInitialDocumentsSection,
  canViewInternalLineCommonSections,
  canViewInternalLineStatusSection,
  canViewLineFinancingNeeds,
  canViewLineInstruments,
  canViewLinesWithCommonStatuses,
  canViewLinesWithInfoRequestStatus,
  canViewLinesWithInitialLoadStatus,
  canViewLinesWithDataLoadedStatus,
  canViewLinesWithPresentationStatus,
  canViewOpinionReportSection,
  canViewPresentationLineAdminSections,
  canViewPresentationOpinionReportSection,
  canOpenMultipleLines,
  canViewLiveRisk,
  canViewSgrReport,
  canEditLineOfficeBranch,
];

const tenantAdminPermissions = [
  ...fullOnboardingPermissions,
  accessAdminLine,
  canAccessBaseFolders,
  canAccessGrantedLines,
  canAccessLineInbox,
  canAccessReceivedLines,
  canAccessLines,
  canAccessLinesPresentationView,
  canAccessLinesPresentationViewPage,
  canAccessLinesSeparatePagesView,
  canAccessMarketplace,
  canAccessOnboardings,
  canAccessOtherUsersLineSharings,
  canAccessUsers,
  canAccessCounterpartiesPage,
  canAddCommentForAnySource,
  canAddConceptGuarantor,
  canAnalyzeLine,
  canAccessLineAnalisis,
  canApproveEndClientRoleRequests,
  canApproveEndClientAccessRequests,
  canAssignHelpersToAnAssignee,
  canChangeToAllStatuses,
  canChangeToAllowedTenant,
  canCreateInviteLinks,
  canCreateLinesInSurveyingStatus,
  canDeleteBaseFolderAssignee,
  canDeleteBaseFolderProducer,
  canDeleteLineComments,
  canDeleteOnboardingComments,
  canDeleteRiskMatrixComments,
  canDisableOrEnableOtherUsers,
  canEditBaseFolderAssignee,
  canEditBaseFolderProducer,
  canEditLineSharings,
  canEditOtherUserLines,
  canEditOtherUsersOnboardings,
  canEditOtherUsersProfile,
  canEditOtherUsersRoles,
  canEditProfile,
  canEditSubmittedLines,
  canEditSubmittedOnboardings,
  canListDisabledUsers,
  canListLines,
  canListLinesWithCommonStatuses,
  canListLinesWithInfoRequestStatus,
  canListLinesWithInitialLoadStatus,
  canListLinesWithPresentationStatus,
  canListNonSmeLines,
  canListOnboardings,
  canListTenantProfiles,
  canListUserCompanies,
  canViewTenantProfiles,
  canListInternalTenantProfiles,
  canListExternalTenantProfiles,
  canListAllAssignedEntities,
  canPublishInMarketplace,
  canRequestMatchfinAnalystsReview,
  canRequestObjectPermission,
  canSeeOtherEndClients,
  canSeeOtherUsersRoles,
  canSubmitOthersContainers,
  canTransitionToAnalysisStatus,
  canUseLineFilters,
  canViewContactInfoSection,
  canViewCounterpartiesLog,
  canViewFullInfoLineAdminSections,
  canViewFullInfoOpinionReportSection,
  canViewGrantedLineSharings,
  canViewInitialDocumentsSection,
  canViewInternalLineCommonSections,
  canViewInternalLineStatusSection,
  canViewLineFinancingNeeds,
  canViewLineInstruments,
  canViewLinePreApprovals,
  canViewLinesWithCommonStatuses,
  canViewLinesWithInfoRequestStatus,
  canViewLinesWithInitialLoadStatus,
  canViewLinesWithDataLoadedStatus,
  canViewLinesWithPresentationStatus,
  canViewNosisSection,
  canViewOpinionReportSection,
  canViewPresentationLineAdminSections,
  canViewPresentationOpinionReportSection,
  canOpenMultipleLines,
  canViewFinancerStatuses,
  canViewLiveRisk,
  canViewSgrReport,
  canViewTenantSettings,
  canEditTenantSettings,
  canViewSemaphoreSettings,
  canEditSemaphoreSettings,
  canEditLineOfficeBranch,
];

export const rolePermissionsAsArrays: Record<string, string[]> = {
  [adminRole]: [
    ...tenantAdminPermissions,
    canApproveRoleRequests,
    canBackupOnboardings,
    canChangeToAnyTenant,
    canCopyLinesToOtherTenants,
    canDebugPermissions,
    canDeleteSgrReportComments,
    canEditOpinionReportSection,
    canEditSgrReport,
    canListUrls,
    canListAllUsers,
    canMigrateFinancerUsers,
    canViewAdminsIdentity,
    canViewMatchfinAnalystsIdentity,
    canViewRiskMatrix,
    canViewUsersInCounterpartiesLog,
    canAccessRiskPreview,
    canListLinesWithDataLoadedStatus,
    canViewLineHasChosenAlyc,
    canUsePreApprovalAnalysis,
    canSeePreApprovalAnalysisDetail,
  ],
  [tenantAdminRole]: tenantAdminPermissions,
  [pendingApprovalRole]: [canEditProfile],
  [tenantRealmPendingApprovalRole]: [canEditProfile],
  [endClientUserRole]: [
    canAccessBaseFolders,
    canAccessControlPanel,
    canAccessLines,
    canAccessLinesSeparatePagesView,
    canAccessOnboardings,
    canAccessRedlinkData,
    canAddEndClient,
    canAddOnboardings,
    canCreateVideoSelfieRequests,
    canEditProfile,
    canRequestObjectPermission,
    canViewInternalLineCommonSections,
    canViewLineFinancingNeeds, // FIXME: EndClient does not have mktp
    canViewLineInstruments,
    canViewLinesWithCommonStatuses,
    canViewLinesWithInfoRequestStatus,
    canViewLinesWithInitialLoadStatus,
    canViewLinesWithDataLoadedStatus,
    canViewLinesWithPresentationStatus,
    canViewOnboardingsWithInfoRequestStatus,
    canViewOnboardingsWithInitialLoadStatus,
    canViewLiveRisk, // FIXME: EndClient does not have presentation view
    canViewSgrReport, // FIXME: EndClient does not have mktp
  ],
  [matchfinEndClientUserRole]: [
    canAccessControlPanel,
    canAccessMatchfinControlPanel,
    canAccessBaseFolders,
    canAccessLines,
    canViewLinesWithInfoRequestStatus,
    canViewLinesWithInitialLoadStatus,
    canViewLinesWithDataLoadedStatus,
    canViewLinesWithPresentationStatus,
    canViewLiveRisk,
    canEditProfile,
    canViewInternalLineCommonSections,
    canViewLineInstruments,
    canAccessRiskPreview,
    canAccessLineAnalisis,
    canCreateRiskAnalysisPaymentOrder,
    canUsePreApprovalAnalysis,
    canAccessLinesPresentationViewPage,
    canAccessLinesPresentationViewOnPresentationStatus,
    canViewPresentationLineAdminSections,
    accessAdminLine,
    canAccessLineDataLoadPages,
    canViewLineChooseYourAlycSection,
    canAccessLineInbox,
    canViewGrantedLineSharings,
  ],
  [matchfinAnalystRole]: [
    ...analystPermissions,
    canChangeToAllowedTenant,
    canDeleteSgrReportComments,
    canEditOpinionReportSection,
    canEditSgrReport,
    canSeeOtherEndClients,
    canViewMatchfinAnalystsIdentity,
    canAccessFoldersSharedWithMfAnalysts,
    canListLinesWithDataLoadedStatus,
    canViewLineHasChosenAlyc,
    canUsePreApprovalAnalysis,
    canSeePreApprovalAnalysisDetail,
  ],
  [producerRole]: [
    ...salesAndProducerCommonRolePermissions,
    canBeAssignedAsProducerUid,
  ],
  [headOfProducersRole]: [
    ...salesAndProducerCommonRolePermissions,
    canApproveProducerFolderEndClientRoleRequests,
    canApproveProducerFolderEndClientAccessRequests,
    canListProducerAssignedEndClientProfiles,
    canBeAssignedAsAssigneeUid,
    canCreateProducerInviteLinks,
    canEditBaseFolderAssignee,
    canEditBaseFolderProducer,
    canAccessOtherProducersFolders,
    canAccessOtherProducersLineSharings,
    canAccessUsers,
    canListTenantProfiles,
    canViewTenantProfiles,
    canEditOtherProducerLines,
    canEditOtherProducerOnboardings,
    canListProducerTenantProfiles,
    canListProducerAssignedEntities,
    canSeeOtherEndClients,
  ],
  [salesRole]: [
    ...salesAndProducerCommonRolePermissions,
    canViewOpinionReportSection,
    canBeAssignedAsAssigneeUid,
    canBeAssignedAsHelperToAnAssignee,
    canListTenantProfiles,
    canViewTenantProfiles,
    canListInternalTenantProfiles,
  ],
  [salesManagerRole]: [
    ...salesAndProducerCommonRolePermissions,
    canApproveEndClientRoleRequests,
    canApproveEndClientAccessRequests,
    canViewOpinionReportSection,
    canBeAssignedAsAssigneeUid,
    canEditBaseFolderAssignee,
    canEditBaseFolderProducer,
    canAccessOtherUsersFolders,
    canAccessOtherUsersLineSharings,
    canAccessUsers,
    canAssignHelpersToAnAssignee,
    canEditOtherSalesLines,
    canEditOtherSalesOnboardings,
    canEditOnboardingsWithoutAssigneeOrProducer,
    canListTenantProfiles,
    canViewTenantProfiles,
    canListInternalTenantProfiles,
    canListExternalTenantProfiles,
    canListAllAssignedEntities,
    canSeeOtherEndClients,
    canEditLinesWithoutAssigneeOrProducer,
  ],
  [matchfinSalesRole]: [canSeeOtherUsersRoles, canListAllUsers],
  [backOfficeRole]: [
    accessAdminLine,
    canAccessBaseFolders,
    canAccessGrantedLines,
    canAccessLineInbox,
    canAccessReceivedLines,
    canAccessLines,
    canAccessLinesPresentationView,
    canAccessLinesPresentationViewPage,
    canAccessLinesSeparatePagesView,
    canAccessMarketplace,
    canAccessOnboardingAdminSections,
    canAccessOnboardingLog,
    canAccessOnboardings,
    canAccessRedlinkData,
    canAccessUsers,
    canAccessCounterpartiesPage,
    canAnalyzeLine,
    canAccessLineAnalisis,
    canChangeToAllowedTenant,
    canEditProfile,
    canListLines,
    canListLinesWithCommonStatuses,
    canListLinesWithInfoRequestStatus,
    canListLinesWithInitialLoadStatus,
    canListLinesWithPresentationStatus,
    canListNonSmeLines,
    canListOnboardings,
    canListUserCompanies,
    canSeeOtherUsersRoles,
    canUseLineFilters,
    canViewContactInfoSection,
    canViewCounterpartiesLog,
    canViewGrantedLineSharings,
    canViewInitialDocumentsSection,
    canViewInternalLineCommonSections,
    canViewInternalLineStatusSection,
    canViewLineFinancingNeeds,
    canViewLineInstruments,
    canViewLinePreApprovals,
    canViewLinesWithCommonStatuses,
    canViewLinesWithInfoRequestStatus,
    canViewLinesWithInitialLoadStatus,
    canViewLinesWithDataLoadedStatus,
    canViewLinesWithPresentationStatus,
    canViewOnboardingsWithCommonStatuses,
    canViewOnboardingsWithInfoRequestStatus,
    canViewOnboardingsWithInitialLoadStatus,
    canViewPresentationLineAdminSections,
    canViewPresentationOpinionReportSection,
    canViewTenantProfiles,
    canViewFinancerStatuses,
    canViewLiveRisk,
    canViewSgrReport,
    canViewOpinionReportSection,
    canAccessOtherUsersFolders,
    canAccessOtherUsersLineSharings,
    canListTenantProfiles,
    canListInternalTenantProfiles,
    canListExternalTenantProfiles,
    canSeeOtherEndClients,
  ],
  [portfolioManagerRole]: [
    accessAdminLine,
    canAccessBaseFolders,
    canAccessGrantedLines,
    canAccessLineInbox,
    canAccessReceivedLines,
    canAccessLines,
    canAccessLinesPresentationView,
    canAccessLinesPresentationViewPage,
    canAccessMarketplace,
    canAnalyzeLine,
    canAccessLineAnalisis,
    canChangeToAllowedTenant,
    canEditProfile,
    canListLines,
    canListLinesWithCommonStatuses,
    canListLinesWithInfoRequestStatus,
    canListLinesWithInitialLoadStatus,
    canListLinesWithPresentationStatus,
    canListNonSmeLines,
    canSeeOtherUsersRoles,
    canUseLineFilters,
    canViewContactInfoSection,
    canViewGrantedLineSharings,
    canViewInitialDocumentsSection,
    canViewInternalLineCommonSections,
    canViewInternalLineStatusSection,
    canViewLineFinancingNeeds,
    canViewLineInstruments,
    canViewLinePreApprovals,
    canViewLinesWithCommonStatuses,
    canViewLinesWithInfoRequestStatus,
    canViewLinesWithInitialLoadStatus,
    canViewLinesWithDataLoadedStatus,
    canViewLinesWithPresentationStatus,
    canViewPresentationLineAdminSections,
    canViewPresentationOpinionReportSection,
    canViewTenantProfiles,
    canViewFinancerStatuses,
    canViewLiveRisk,
    canViewSgrReport,
    canViewOpinionReportSection,
    canAccessOtherUsersFolders,
    canAccessOtherUsersLineSharings,
  ],
  [analystRole]: [
    ...analystPermissions,
    canAccessOtherUsersLineSharings,
    canEditLineSharings,
    canRequestMatchfinAnalystsReview,
    canRequestObjectPermission,
    canAccessFoldersSharedWithInternalAnalysts,
  ],
  [investorRole]: [...financerPermissions, canListNonSmeLines],
  [guestSgrRole]: financerPermissions,
  [guestBankRole]: [...financerPermissions, canListNonSmeLines],
  [complianceOfficerRole]: [
    ...fullOnboardingPermissions,
    canAccessUsers,
    canChangeToAllowedTenant,
    canEditBaseFolderAssignee,
    canEditBaseFolderProducer,
    canDeleteOnboardingComments,
    canEditProfile,
    canListExternalTenantProfiles,
    canListTenantProfiles,
    canSeeOtherEndClients,
    canSeeOtherUsersRoles,
    canViewLiveRisk,
    canViewOpinionReportSection,
    canViewRiskMatrix,
    canViewSgrReport,
    canViewTenantProfiles,
  ],
  [canFilterbyAlycInvestorRole]: [canFilterLinesByAlyc],
};
