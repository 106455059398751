/* DON'T EDIT THIS FILE: edit original and run build again */ import {
  emailChannelType,
  reutersChannelType,
  webHomeChannelType,
  whatsappChannelType,
} from "../../channel/channel-types.ts";
import {
  AFIP,
  EMAIL,
} from "../../signatura-document-validations/document-validations.ts";
import { BaseOnboardingOptions } from "../../tenant-handlers/base-onboarding-options.ts";
import { ComponentType } from "react";

class FacimexOnboardingOptions extends BaseOnboardingOptions {
  hasSignatura(): boolean {
    return true;
  }

  hasHumanDirectShareholders(): boolean {
    return false;
  }

  hasRevenueVolume(): boolean {
    return false;
  }

  getSignaturaValidations(): string[] {
    return [EMAIL, AFIP];
  }

  hasSwornStatementAboutLegality(): boolean {
    return true;
  }

  hasProofOfFciRulebook(): boolean {
    return true;
  }

  hasIsQualifiedInvestorText(): boolean {
    return true;
  }

  getSupportedChannelTypes(): string[] {
    return [
      reutersChannelType,
      webHomeChannelType,
      whatsappChannelType,
      emailChannelType,
    ];
  }

  getReutersUserDestinations(): { fullName: string; address: string }[] {
    return [
      {
        fullName: "JOAQUIN FERNANDEZ",
        address: "joaquinfernandez@facimex.com.ar",
      },
      { fullName: "CARLOS SUAREZ", address: "carlossuarez@facimex.com.ar" },
      { fullName: "ANDRES DURBAN", address: "andresdurban@facimex.com.ar" },
      { fullName: "IGNACIO AIMI", address: "ignacioaimi@facimex.com.ar" },
    ];
  }

  async getEmailHeader(): Promise<ComponentType<Record<string, never>>> {
    return (await import("./email-header.tsx")).default;
  }

  async getDocumentHeader(): Promise<
    ComponentType<{ agreementLabel: string }>
  > {
    return (await (() => { throw new Error("This file is not meant to be imported in this context.") })()).default;
  }
}

export const facimexOnboardingOptions = new FacimexOnboardingOptions();
