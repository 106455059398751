/* DON'T EDIT THIS FILE: edit original and run build again */ import { DataPipe } from "../data-pipe/data-pipe.ts";
import { colPipe } from "../firebase/firestore-pipe.ts";
import { findChatSubscriptions } from "./chat-subscription.ts";

export const createUnreadCountPipe = (
  loggedUid: string,
  lineSharingId: string | null = null
): DataPipe<number> =>
  colPipe(
    findChatSubscriptions({ uid: loggedUid, unread: true, lineSharingId }),
    "id",
    "unreadCountPipe"
  ).pipe((subscriptions) =>
    subscriptions
      .map(({ unreadCount }) => unreadCount)
      .reduce((a, b) => a + b, 0)
  );
