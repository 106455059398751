/* DON'T EDIT THIS FILE: edit original and run build again */ import { registerLiveFieldRenderer } from "../live-form-field-collection/type-renderers.ts";
import { passwordSetterLiveFieldRenderer } from "./renderer.tsx";

export const registerPasswordSetterLiveFieldType = () => {
  try {
    registerLiveFieldRenderer(passwordSetterLiveFieldRenderer);
  } catch (error) {
    if (!error.message.includes("liveFormFieldRendererAlreadyDeclared")) {
      throw error;
    }
  }
};
