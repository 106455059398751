/* DON'T EDIT THIS FILE: edit original and run build again */ import { LiveCell } from "../../live-form/cell.ts";
import { LiveFieldset } from "../../live-form/fieldset.ts";
import { LiveRecord } from "../../live-form/live-record.ts";
import { RawLiveFormViewer } from "../../live-form/ui/raw-live-form-viewer.tsx";
import { RawLiveForm } from "../../live-form/ui/raw-live-form.tsx";
import {
  LiveFieldInput,
  LiveFieldInputParam,
  LiveFieldRenderer,
  LiveFieldViewer,
} from "../../live-form/ui/types.ts";
import { Div } from "../../pdf-wrapper/pdf-wrapper.tsx";
import { ValidationFeedback } from "../../ui/form/validation-feedback.tsx";
import { LabelLiveField } from "./live-field.ts";
import { labelLiveFieldType } from "./type.ts";

const MaybeIndented = ({
  children,
  indented,
  hasBorder = false,
}: {
  children: React.ReactNode;
  indented: boolean;
  hasBorder?: boolean;
}) => {
  if (indented) {
    return (
      <Div
        className={
          "mt-3 " +
          (hasBorder ? "ps-3 border-start border-green70 border-4" : "ps-4")
        }
      >
        {children}
      </Div>
    );
  } else {
    return children;
  }
};

const createLiveRecord = (liveCell: LiveCell, fieldset: LiveFieldset) =>
  new LiveRecord(
    fieldset,
    liveCell.getLiveData(),
    liveCell.getShowErrors(),
    liveCell.isDisabled()
  );

const Input: LiveFieldInput = ({
  liveCell,
  saveField,
  getLiveFormInputFactory,
  Widgets,
}: LiveFieldInputParam) => {
  const liveField = liveCell.getLiveField() as LabelLiveField;
  const fieldset = liveField.getFieldset();
  if (!fieldset) {
    return null;
  }
  const error = liveCell.getThisFieldError();
  return (
    <MaybeIndented
      indented={liveField.isIndented()}
      hasBorder={liveField.hasBorder()}
    >
      {error ? <ValidationFeedback error={error} /> : null}
      <RawLiveForm
        getLiveFormInputFactory={getLiveFormInputFactory}
        liveRecord={createLiveRecord(liveCell, fieldset)}
        saveField={saveField}
        Widgets={Widgets}
        allowDefaultValue={false}
      />
    </MaybeIndented>
  );
};

const Viewer: LiveFieldViewer = ({
  liveCell,
  getLiveFormInputFactory,
  Widgets,
  showLabels,
}) => {
  const liveField = liveCell.getLiveField() as LabelLiveField;
  const fieldset = liveField.getFieldset();
  if (!fieldset) {
    return null;
  }
  return (
    <MaybeIndented indented={liveField.isIndented()}>
      <RawLiveFormViewer
        getLiveFormInputFactory={getLiveFormInputFactory}
        liveRecord={createLiveRecord(liveCell, fieldset)}
        Widgets={Widgets}
        showLabels={showLabels}
      />
    </MaybeIndented>
  );
};

export const labelLiveFieldRenderers: LiveFieldRenderer = {
  type: labelLiveFieldType,
  Input,
  Viewer,
};
