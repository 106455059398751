/* DON'T EDIT THIS FILE: edit original and run build again */ import { BankTenantHandler } from "../../tenant-handlers/bank-tenant-handler.ts";
import { bancocoinagTenant } from "./code.ts";
import { bancocoinagFullLabel } from "./full-label.ts";

class BancocoinagTenantHandler extends BankTenantHandler {
  getCode(): string {
    return bancocoinagTenant;
  }

  getPathLabel(): string | null {
    return "banco-coinag";
  }

  getLabel(): string {
    return "Banco Coinag";
  }

  getFullLabel(): string {
    return bancocoinagFullLabel;
  }

  getNotificationsFrom(): string {
    return "no-responder@matchfin.ar";
  }

  getCuit(): string {
    return "30-71419596-0";
  }

  hasOpinionReportSection(): boolean {
    return false;
  }

  publishesInMarketplace(): boolean {
    return true;
  }

  hasGrantedLineSection(): boolean {
    return true;
  }
}

const bancocoinagTenantHandler = new BancocoinagTenantHandler();

export default bancocoinagTenantHandler;
