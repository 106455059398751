/* DON'T EDIT THIS FILE: edit original and run build again */ import { LineOptions } from "../../tenant-handler-type/line-options.ts";
import { OnboardingOptions } from "../../tenant-handler-type/onboarding-options.ts";
import { AlycTenantHandler } from "../../tenant-handlers/alyc-tenant-handler.ts";
import { ComponentType, SVGProps } from "react";
import { portfolioTenant } from "./code.ts";
import { portfolioFullLabel } from "./full-label.ts";
import { portfolioLineOptions } from "./portfolio-line-options.ts";
import { portfolioMavAgentId } from "./portfolio-mav-agent-id.ts";
import { portfolioOnboardingOptions } from "./portfolio-onboarding-options.ts";

class PortfolioTenantHandler extends AlycTenantHandler {
  async getAuthPageLogo(): Promise<ComponentType<SVGProps<SVGElement>>> {
    // @ts-expect-error the hook is auto generated
    return (await import("./logo_portfolio.svg?react")).default;
  }

  getCode(): string {
    return portfolioTenant;
  }

  getPathLabel(): string | null {
    return "portfolio-investment";
  }

  getLineOptions(): LineOptions {
    return portfolioLineOptions;
  }

  getOnboardingOptions(): OnboardingOptions | null {
    return portfolioOnboardingOptions;
  }

  getLabel(): string {
    return "Portfolio Investment";
  }

  getFullLabel(): string {
    return portfolioFullLabel;
  }

  getNotificationsFrom(): string {
    return "no-responder@portfolioinvestment.com.ar";
  }

  getComplianceEmailAddress(): string {
    return "compliance@portfolioinvestment.com.ar";
  }

  getCuit(): string | null {
    return "30-65940185-8";
  }

  getCnvRegistryId(): string | null {
    return "282";
  }

  publishesInMarketplace(): boolean {
    return true;
  }

  doEndClientUsersHaveAccessToTheirEndClientLines(): boolean {
    return true;
  }

  getMavAgentId(): number {
    return portfolioMavAgentId;
  }
}

const portfolioTenantHandler = new PortfolioTenantHandler();

export default portfolioTenantHandler;
