/* DON'T EDIT THIS FILE: edit original and run build again */ import {
  defaultNullableCheckboxNoLabel,
  defaultNullableCheckboxYesLabel,
} from "./checkbox-labels.ts";
import { RadioButtonsInput } from "./radio-buttons-input.tsx";

const yes = "yes";
const no = "no";

export const NullableCheckbox = ({
  value,
  setValue,
  className,
  autoFocus,
  yesLabel = defaultNullableCheckboxYesLabel,
  noLabel = defaultNullableCheckboxNoLabel,
}) => (
  <RadioButtonsInput
    autoFocus={autoFocus}
    value={value === true ? yes : value === false ? no : null}
    setValue={(newValue) => {
      if (newValue === yes) {
        setValue(true);
      } else if (newValue === no) {
        setValue(false);
      } else {
        setValue(null);
      }
    }}
    className={className}
    options={{
      [yes]: yesLabel,
      [no]: noLabel,
    }}
    nullable={true}
  />
);
