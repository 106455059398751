/* DON'T EDIT THIS FILE: edit original and run build again */ import { frozenEmptyObject } from "../../core/frozen-empty-object.ts";
import Select, { GroupBase, StylesConfig, components } from "react-select";

export const MultipleSelectInput = ({
  value,
  setValue,
  onChange,
  options,
  children = null,
  setChildrenInputValueForTests = (_param) => false,
  placeholder,
  menuPortalTarget,
  noOptionsMessage,
  menuShouldScrollIntoView,
  styles,
  disabled,
  autoFocus,
}: {
  value: any;
  setValue: any;
  onChange: any;
  options: any;
  children?: React.ReactNode;
  setChildrenInputValueForTests?: (_param) => boolean;
  placeholder?: string;
  menuPortalTarget?: HTMLElement;
  noOptionsMessage?: () => string;
  menuShouldScrollIntoView?: boolean;
  styles?: StylesConfig<any, true, GroupBase<any>>;
  disabled?: boolean;
  autoFocus?: boolean;
}) => {
  const setRef = (node) => {
    if (node) {
      node.setInputValueForTests = (labels) => {
        if (!setChildrenInputValueForTests(labels)) {
          const selected = options
            .filter(({ label }) => labels.includes(label))
            .map(({ value }) => value);
          setValue(selected);
        }
      };
    }
  };
  const MenuList = (props) => (
    <components.MenuList {...props}>
      {children}
      {props.children}
    </components.MenuList>
  );
  return (
    <span ref={setRef} data-is-input data-is-multiple-select>
      <Select
        isMulti={true}
        value={value}
        onChange={onChange}
        options={options}
        components={
          disabled
            ? { DropdownIndicator: () => null, IndicatorSeparator: () => null }
            : children
            ? { MenuList }
            : frozenEmptyObject
        }
        placeholder={placeholder}
        menuPortalTarget={menuPortalTarget}
        noOptionsMessage={noOptionsMessage}
        menuShouldScrollIntoView={menuShouldScrollIntoView}
        styles={styles}
        isDisabled={disabled}
        autoFocus={autoFocus}
      />
    </span>
  );
};
