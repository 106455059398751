/* DON'T EDIT THIS FILE: edit original and run build again */ import canAccessInboxCondition from "../line-inbox-condition/can-access-inbox-condition.ts";
import { inboxPath } from "../line-inbox-path/inbox-path.ts";
import { linesPath } from "../line-path/get-relative-line-url.ts";
import {
  canAccessGrantedLines,
  canListLines,
} from "../permission/permissions.ts";
import { userHasPermission } from "../permission/user-has-permission.ts";
import { SidebarMenuItem } from "../sidebar-type/sidebar-types.ts";
import { FolderIcon } from "../theme/icon.ts";
import { sentLinesListingPath } from "./sent-lines-listing-path.ts";

export const folderListingTabId = "folderListing";

const folderMenuItem: SidebarMenuItem = {
  id: folderListingTabId,
  getPath: ({ roles }) =>
    userHasPermission(roles, canListLines)
      ? linesPath
      : userHasPermission(roles, canAccessGrantedLines)
      ? sentLinesListingPath
      : inboxPath,
  label: "Carpetas",
  condition: ({ tenantHandler, roles }) =>
    (userHasPermission(roles, canListLines) ||
      userHasPermission(roles, canAccessGrantedLines) ||
      canAccessInboxCondition(roles, tenantHandler)) &&
    !!tenantHandler.getLineOptions(),
  order: 2,
  hasPriority: true,
  icon: (className) => <FolderIcon className={className} />,
};

export default folderMenuItem;
