/* DON'T EDIT THIS FILE: edit original and run build again */ import { logoutPath } from "../auth-ui/logout-path.ts";
import { Link } from "react-router-dom";
import {
  ErrorMessage,
  ErrorPage,
  SimpleErrorPage,
} from "./error-page.front.tsx";

export const ForbiddenPage = () => (
  <ErrorPage
    title="No tenés permiso para entrar a esta página"
    note={
      <div>
        Si tenés permiso, podés{" "}
        <Link to={logoutPath}> salir y entrar como otro usuario</Link>.
      </div>
    }
  />
);

export const SimpleForbiddenPage = () => (
  <SimpleErrorPage
    title="No tenés permiso para entrar a esta página"
    note={
      <div>
        Si tenés permiso, podés{" "}
        <Link to={logoutPath}> salir y entrar como otro usuario</Link>.
      </div>
    }
  />
);

export const ForbiddenPageMessage = () => (
  <ErrorMessage
    title="No tenés permiso para entrar a esta página"
    note={
      <div>
        Si tenés permiso podés{" "}
        <Link to="/login">entrar otra vez como otro usuario</Link>
      </div>
    }
  />
);
