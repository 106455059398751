// DON'T EDIT THIS FILE: edit the original Triggers.ts files and regenerate derived files

import hook0 from "../chat/navbar-collect.front.tsx";
import hook1 from "../common-folder-listing/navbar-collect.front.tsx";
import hook2 from "../control-panel/navbar-collect.front.tsx";
import hook3 from "../counterparties-pages/navbar-collect.front.tsx";
import hook4 from "../marketplace-common-listing/navbar-collect.front.tsx";
import hook5 from "../onboarding/navbar-collect.front.tsx";
import hook6 from "../risk-matrix/navbar-collect.front.tsx";
import hook7 from "../settings-section/navbar-collect.front.tsx";
import hook8 from "../sgrs-reports/navbar-collect.front.tsx";
import hook9 from "../users/navbar-collect.front.tsx";

export default [hook0, hook1, hook2, hook3, hook4, hook5, hook6, hook7, hook8, hook9];
