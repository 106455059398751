/* DON'T EDIT THIS FILE: edit original and run build again */ import firebase from "firebase/compat/app";
import { ElementOf } from "../core/element-of.ts";
import { Jsonable } from "../core/jsonable.ts";
import { Mutable } from "../core/mutable.ts";

const firestoreDocumentTag: unique symbol = Symbol.for("firestoreDocument");

export type DocumentBaseData = { [firestoreDocumentTag]?: never }; // expanded in the future, and as a branding mechanism

export type FirestoreDocumentData<TData> = Readonly<DocumentBaseData & TData>;

export type UpdateData<T> = firebase.firestore.UpdateData<Mutable<T>>;

export type SetData<T> = firebase.firestore.WithFieldValue<Mutable<T>>;

export type MergeData<T> = SetData<Partial<T>>;

export type PartialWithFieldValue<T> =
  firebase.firestore.PartialWithFieldValue<T>;

export type FirestoreDocumentRef<T> = firebase.firestore.DocumentReference<T>;

export type FirestoreDocumentSnapshot<T> =
  firebase.firestore.DocumentSnapshot<T>;

export type FirestoreQuerySnapshot<T> = firebase.firestore.QuerySnapshot<T>;

export type FirestoreQueryDocumentSnapshot<T> =
  firebase.firestore.QueryDocumentSnapshot<T>;

export type FirestoreWriteResult = firebase.firestore.WriteResult;

export const firestoreArrayUnion = firebase.firestore.FieldValue.arrayUnion;

export const firestoreArrayRemove = firebase.firestore.FieldValue.arrayRemove;

export const firestoreIncrement = firebase.firestore.FieldValue.increment;

export const firestoreDocumentId = firebase.firestore.FieldPath.documentId;

export const firestoreDelete = firebase.firestore.FieldValue.delete;

export const firestoreServerTimestamp =
  firebase.firestore.FieldValue.serverTimestamp;

export type WhereFilterOpElemLike = firebase.firestore.WhereFilterOp &
  ("<" | "<=" | "==" | "!=" | ">=" | ">");

export type WhereFilterOpElemInArrayLike = firebase.firestore.WhereFilterOp &
  "array-contains";

export type WhereFilterOpArrayLike = firebase.firestore.WhereFilterOp &
  ("in" | "not-in" | "array-contains-any");

export type WhereFilterOp =
  | WhereFilterOpElemLike
  | WhereFilterOpElemInArrayLike
  | WhereFilterOpArrayLike;

export type FieldValue = firebase.firestore.FieldValue;

export type FieldPath = firebase.firestore.FieldPath;

export type FieldKey<T> = keyof T & string;

export type FieldOpAndValue<T, K extends FieldKey<T> = FieldKey<T>> =
  | [K, WhereFilterOpElemLike, K extends keyof T ? T[K] : Jsonable]
  | [
      K,
      WhereFilterOpElemInArrayLike,
      K extends keyof T ? ElementOf<T[K]> : Jsonable
    ]
  | [K, WhereFilterOpArrayLike, K extends keyof T ? T[K][] : Jsonable[]];

export type OrderByDirection = firebase.firestore.OrderByDirection;

export type OrderAndDir<T> = [
  FieldKey<T> | firebase.firestore.FieldPath,
  OrderByDirection
];

export const firestoreDisjunctionLimit = 30;
