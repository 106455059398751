/* DON'T EDIT THIS FILE: edit original and run build again */ import { gray70 } from "../../theme/custom-colors.ts";
import Select from "react-select";
import { SimpleDropdownValueOptions } from "./simple-dropdown.tsx";

type ComboBoxDropdownProps = {
  value: any;
  valueOptions: SimpleDropdownValueOptions;
  setValue: (value: any) => void | Promise<void>;
};

export const ComboBoxDropdown = ({
  value,
  valueOptions,
  setValue,
}: ComboBoxDropdownProps) => {
  const onRef = (node) => {
    if (node) {
      node.setInputValueForTests = (selectedLabelOrNull) => {
        if (selectedLabelOrNull === null) {
          setValue(null);
          return null;
        } else {
          const foundOption = Object.entries(valueOptions).find(
            ([, label]) => label === selectedLabelOrNull
          );
          if (!foundOption) {
            return "noLabelMatchesValue";
          }
          setValue(foundOption[0]);
          return null;
        }
      };
    }
  };
  return (
    <div data-is-input ref={onRef}>
      <Select
        className="basic-single"
        classNamePrefix="select"
        name="single-option"
        isClearable={true}
        isSearchable={true}
        placeholder={"Elegí o escribí una opcion"}
        noOptionsMessage={() => <div>No se encuentran opciones</div>}
        value={value ? { value, label: valueOptions[value] } : null}
        onChange={(o) => {
          setValue(o?.value);
        }}
        options={Object.entries(valueOptions).map(([value, label]) => ({
          label,
          value,
        }))}
        styles={{
          control: (baseStyles) => ({
            ...baseStyles,
            borderColor: gray70,
            height: "50px",
            borderRadius: "6px",
          }),
        }}
      />
    </div>
  );
};

export const MultipleComboBoxDropdown = ({
  value,
  valueOptions,
  setValue,
}: {
  value: Record<string, boolean>;
  valueOptions: SimpleDropdownValueOptions;
  setValue: (selectedOptions: { label: string; value: string }[]) => void;
}) => {
  const options = Object.entries(valueOptions).map(([value, label]) => ({
    label,
    value,
  }));
  const values = Object.entries(value).map(([key]) => ({
    label: valueOptions[key],
    value: key,
  }));
  const onRef = (node) => {
    if (node) {
      node.setInputValueForTests = (selectedLabel: string) => {
        const selectedOption = options.find(
          ({ label }) => selectedLabel === label
        );
        if (selectedOption && !value[selectedOption.value]) {
          setValue([...values, selectedOption]);
        }
      };
    }
  };
  return (
    <div data-is-input ref={onRef}>
      <Select
        className="basic-multiple"
        classNamePrefix="select"
        name="multiple-option"
        isClearable={true}
        isSearchable={true}
        isMulti={true}
        placeholder={"Elegí o escribí una opcion"}
        noOptionsMessage={() => <div>No se encuentran opciones</div>}
        value={values}
        onChange={(selectedOptions) => {
          setValue(selectedOptions as { label: string; value: string }[]);
        }}
        options={options}
        styles={{
          control: (baseStyles) => ({
            ...baseStyles,
            borderColor: gray70,
            minHeight: "50px",
            borderRadius: "6px",
          }),
        }}
      />
    </div>
  );
};
