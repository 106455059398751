/* DON'T EDIT THIS FILE: edit original and run build again */ import { InvestorTenantHandler } from "../../tenant-handlers/investor-tenant-handler.ts";
import { dracmaFciTenant } from "./code.ts";
import { dracmaFciFullLabel } from "./full-label.ts";
import { dracmaFciLabel } from "./label.ts";

class DracmaFciTenantHandler extends InvestorTenantHandler {
  getCode(): string {
    return dracmaFciTenant;
  }

  getPathLabel(): string | null {
    return "dracma-fondos";
  }

  getLabel(): string {
    return dracmaFciLabel;
  }

  getFullLabel(): string {
    return dracmaFciFullLabel;
  }

  getOnboardingOptions() {
    return null;
  }

  getNotificationsFrom(): string {
    return "no-responder@matchfin.ar";
  }

  getCuit(): string {
    return "30-71349100-0";
  }

  publishesInMarketplace(): boolean {
    return true;
  }
}

const dracmaFciTenantHandler = new DracmaFciTenantHandler();

export default dracmaFciTenantHandler;
