/* DON'T EDIT THIS FILE: edit original and run build again */ import { AlycTenantHandler } from "../../tenant-handlers/alyc-tenant-handler.ts";
import { efireTenant } from "./code.ts";
import { efireFullLabel } from "./full-label.ts";

class EfireTenantHandler extends AlycTenantHandler {
  getCode(): string {
    return efireTenant;
  }

  getPathLabel(): string | null {
    return "efire";
  }

  getLabel(): string {
    return "EFIRE";
  }

  getFullLabel(): string {
    return efireFullLabel;
  }

  getNotificationsFrom(): string {
    return "no-responder@estructurasfinancieras.com.ar";
  }

  getCuit(): string | null {
    return "30-71445215-7";
  }

  getCnvRegistryId(): string | null {
    return "503";
  }

  hasOpinionReportSection(): boolean {
    return false;
  }

  publishesInMarketplace(): boolean {
    return true;
  }
}

const efireTenantHandler = new EfireTenantHandler();

export default efireTenantHandler;
