/* DON'T EDIT THIS FILE: edit original and run build again */ import { LiveFieldset } from "../../live-form/fieldset.ts";
import { LiveRecord } from "../../live-form/live-record.ts";
import { LiveData, LiveFormRecordValue } from "../../live-form/types.ts";
import { AddDataPopup } from "../../theme/add-data-popup.tsx";
import { RawLiveForm } from "./raw-live-form.tsx";
import { LiveFieldGetLiveFormInputFactory, LiveFormWidgets } from "./types.ts";
import { useLiveFormState } from "./use-live-form-stte.ts";

export const LiveFormCreationPopup = ({
  title,
  getLiveFormInputFactory,
  liveData,
  onFieldChange = null,
  fieldset,
  onRemoveClick,
  onCloseClick,
  saver,
  getInitialValue,
  Widgets,
  buttonLabel,
  allowSaveWithErrors = undefined,
  defaultShowErrors = false,
  showCancel = true,
}: {
  title: string;
  fieldset: LiveFieldset;
  liveData: LiveData;
  getLiveFormInputFactory: LiveFieldGetLiveFormInputFactory;
  onFieldChange?: any;
  onRemoveClick?: any;
  onCloseClick?: any;
  saver: (recordValue: LiveFormRecordValue) => void | Promise<void>;
  getInitialValue?: any;
  Widgets?: LiveFormWidgets;
  buttonLabel?: string;
  allowSaveWithErrors?: (recordValue: LiveFormRecordValue) => boolean;
  defaultShowErrors?: boolean;
  showCancel?: boolean;
}) => {
  const { recordValue, saveField, showErrors, setShowErrors, getError } =
    useLiveFormState({
      defaultRecordValue: getInitialValue,
      defaultShowErrors,
      liveData: liveData,
      fieldset,
    });
  const saveInternal = async () => {
    const error = getError();
    if (error && !allowSaveWithErrors?.(recordValue)) {
      setShowErrors(true);
      return;
    }
    await saver(recordValue);
  };
  const liveRecord = new LiveRecord(
    fieldset,
    {
      ...liveData,
      recordValue,
    },
    showErrors
  );
  return (
    <AddDataPopup
      title={title}
      onRemoveClick={onRemoveClick}
      onCloseClick={onCloseClick}
      onSaveClick={saveInternal}
      buttonLabel={buttonLabel}
      showCancel={showCancel}
    >
      <RawLiveForm
        getLiveFormInputFactory={getLiveFormInputFactory}
        liveRecord={liveRecord}
        saveField={(field, value) => {
          saveField(field, value);
          onFieldChange?.();
        }}
        autoFocus={true}
        Widgets={Widgets}
        onEnterPress={saveInternal}
      />
    </AddDataPopup>
  );
};
