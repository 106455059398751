/* DON'T EDIT THIS FILE: edit original and run build again */ import { CommandButton } from "../../html-editor/button/command-button.tsx";
import { UnlinkIcon } from "../../theme/icon.ts";
import { useActive, useChainedCommands } from "@remirror/react";
import { useCallback } from "react";

export const RemoveLinkButton = () => {
  const chain = useChainedCommands();
  const active = useActive().link();

  const handleAction = useCallback(() => {
    chain.removeLink().focus().run();
  }, [chain]);

  return (
    <CommandButton
      commandName="remove-link"
      active={active}
      enabled={active}
      onAction={handleAction}
    >
      <UnlinkIcon />
    </CommandButton>
  );
};
