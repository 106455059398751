/* DON'T EDIT THIS FILE: edit original and run build again */ import { setConfigGetter } from "../config/config.ts";
import { notNull } from "../core/not-null.ts";
import { initFirebase } from "../firebase/init-firebase.front.ts";
import "../web-icon-list.js";
import React from "react";
import { createRoot } from "react-dom/client";
import App from "./app.front.tsx";
import "./custom.front.scss";
import { initSentry } from "./sentry.front.ts";
import { initAmplitude } from "./track-to-amplitude.front.ts";

let started = false;

const startApp = async () => {
  if (started) {
    return;
  }
  started = true;
  setConfigGetter(() => (window as any).appConfig);
  initFirebase();
  initSentry();
  initAmplitude();
  const container = document.getElementById("root");
  const root = createRoot(notNull(container));
  root.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>
  );
};

startApp();
