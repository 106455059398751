/* DON'T EDIT THIS FILE: edit original and run build again */ import { EditorButtonGroup } from "../../html-editor/button-group/editor-button-group.tsx";
import { RedoButton } from "../../html-editor/button/redo-button.tsx";
import { UndoButton } from "../../html-editor/button/undo-button.tsx";

export const HistoryButtonGroup = () => (
  <EditorButtonGroup name="history">
    <UndoButton />
    <RedoButton />
  </EditorButtonGroup>
);
