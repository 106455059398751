/* DON'T EDIT THIS FILE: edit original and run build again */ import { SimplePageBorder } from "../theme-pages/page-border.front.tsx";

export const MaintenancePage = () => {
  return (
    <SimplePageBorder>
      <div className="col-md-10 d-flex align-items-center flex-column mt-7">
        <div className="text-xl fw-bold mb-4">Página en mantenimiento</div>
        <div>En unos minutos volverá a estar disponible.</div>
      </div>
    </SimplePageBorder>
  );
};
