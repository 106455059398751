/* DON'T EDIT THIS FILE: edit original and run build again */ import { CommandButton } from "../../html-editor/button/command-button.tsx";
import { PasteIcon } from "../../theme/icon.ts";
import { useCommands, useCurrentSelection } from "@remirror/react";
import { useCallback } from "react";

export const PasteButton = () => {
  const { paste } = useCommands();
  // Force component update on selection change
  useCurrentSelection();

  const handleAction = useCallback(() => {
    if (paste.enabled()) {
      paste();
    }
  }, [paste]);

  const enabled = paste.enabled();

  return (
    <CommandButton
      commandName="paste"
      active={false}
      enabled={enabled}
      onAction={handleAction}
    >
      <PasteIcon />
    </CommandButton>
  );
};
