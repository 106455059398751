/* DON'T EDIT THIS FILE: edit original and run build again */ import {
  canCreateInviteLinks,
  canCreateProducerInviteLinks,
} from "../../permission/permissions.ts";
import { RolesMap } from "../../permission/schema.ts";
import { userHasPermission } from "../../permission/user-has-permission.ts";
import { TenantHandler } from "../../tenant-handler-type/tenant-handler.ts";

export const userHasPermissionToListUsersInvited = ({
  roles,
  tenantHandler,
}: {
  roles: RolesMap;
  tenantHandler: TenantHandler;
}) =>
  userHasPermissionToListAllUsersInvited({ roles, tenantHandler }) ||
  userHasPermissionToListInvitedProducers({ roles, tenantHandler });

export const userHasPermissionToListInvitedProducers = ({
  roles,
  tenantHandler,
}: {
  roles: RolesMap;
  tenantHandler: TenantHandler;
}) =>
  userHasPermission(roles, canCreateProducerInviteLinks) &&
  tenantHandler.canCreateInviteLinks();

export const userHasPermissionToListAllUsersInvited = ({
  roles,
  tenantHandler,
}: {
  roles: RolesMap;
  tenantHandler: TenantHandler;
}) =>
  userHasPermission(roles, canCreateInviteLinks) &&
  tenantHandler.canCreateInviteLinks();
