/* DON'T EDIT THIS FILE: edit original and run build again */ import { getConfig } from "../config/config.ts";

export const now = () => {
  const config = getConfig();
  if (config.staticTime) {
    return new Date(config.staticTime);
  } else {
    return new Date(new Date().getTime() - config.timeDeltaMs);
  }
};
