/* DON'T EDIT THIS FILE: edit original and run build again */ import { argentinaCountryCode } from "../country/country-codes.ts";
import md5 from "md5";

const getNationalIdCode = (country: string, type: string, id: string) =>
  country +
  "-" +
  type +
  ":" +
  id.replace(/\./g, "%2E").replace(/\//g, "%2F").replace(/ /g, "%20");

const getRawEntityId = ({
  cuit,
  country,
  foreignIdNumber,
}: {
  cuit?: string;
  country?: string;
  foreignIdNumber?: string;
}) => {
  if (cuit) {
    return getNationalIdCode(argentinaCountryCode, "cuit", cuit);
  } else if (country && foreignIdNumber) {
    return getNationalIdCode(country, "unspecified", foreignIdNumber);
  } else {
    throw new Error("missingKeyInfoForId");
  }
};

export const getEntityId = ({
  cuit,
  country,
  foreignIdNumber,
}: {
  cuit?: string;
  country?: string;
  foreignIdNumber?: string;
}) =>
  "ENTITY:" +
  md5(
    getRawEntityId({
      cuit,
      country,
      foreignIdNumber,
    })
  );
