/* DON'T EDIT THIS FILE: edit original and run build again */ import { RichTextValue } from "../rich-text/rich-text-type.ts";

export type HtmlEditorState = {
  setValue: (value: RichTextValue) => void;
  getValue: () => RichTextValue;
  setHtml: (html: string) => void;
  setText: (text: string) => void;
  length: number;
} & RichTextValue;

export const useHtmlEditorState = (
  value: RichTextValue,
  setValue: (value: RichTextValue) => void
): HtmlEditorState => {
  const getValue = (): RichTextValue => ({
    html: value.html ?? "",
    text: value.text ?? "",
    attachments: value.attachments ?? [],
  });
  const setHtml = (html: string) => {
    setValue({ ...getValue(), html });
  };
  const setText = (text: string) => setValue({ ...getValue(), text });
  return {
    ...getValue(),
    setHtml,
    setText,
    getValue,
    setValue,
    length: getValue().text.trim().length,
  };
};
