/* DON'T EDIT THIS FILE: edit original and run build again */ import { CommandButton } from "../../html-editor/button/command-button.tsx";
import { BoldIcon } from "../../theme/icon.ts";
import { useActive, useCommands } from "@remirror/react";
import { useCallback } from "react";

export const BoldButton = (_props: Record<string, never>) => {
  const { toggleBold } = useCommands();

  const handleAction = useCallback(() => {
    if (toggleBold.enabled()) {
      toggleBold();
    }
  }, [toggleBold]);

  const active = useActive().bold();
  const enabled = toggleBold.enabled();

  return (
    <CommandButton
      commandName="bold"
      active={active}
      enabled={enabled}
      onAction={handleAction}
    >
      <BoldIcon />
    </CommandButton>
  );
};
