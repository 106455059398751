/* DON'T EDIT THIS FILE: edit original and run build again */ import {
  AssignedEntitiesList,
  AssignedEntitiesListTypes,
} from "../../assigned-entities/data-pipe.ts";
import {
  getProfileCol,
  getProfilesByUidPipe,
} from "../../auth-profile/profile.ts";
import { UserProfile } from "../../auth-profile/schema.ts";
import { getRoleRequestsCol } from "../../auth-role-requests/role-request.ts";
import {
  RoleRequestStatus,
  UserRoleRequest,
} from "../../auth-role-requests/schema.ts";
import { notNull } from "../../core/not-null.ts";
import { DataPipe } from "../../data-pipe/data-pipe.ts";
import { leftJoin } from "../../data-pipe/left-join.ts";
import { batchColPipe } from "../../firebase/batch-pipe.ts";
import { colPipe } from "../../firebase/firestore-pipe.ts";
import { canApproveEndClientRoleRequests } from "../../permission/permissions.ts";
import { RolesMap } from "../../permission/schema.ts";
import { userHasPermission } from "../../permission/user-has-permission.ts";
import { UsersListPipeFilters } from "../../users/filter-types.ts";
import {
  FullUserInfo,
  UsersListDataPipeArgs,
} from "../../users/list-data-pipe-params.ts";

export const createBasicEndClientRequestsPipe = (
  roles: RolesMap,
  tenant: string,
  statuses: RoleRequestStatus[],
  entityIdsList: AssignedEntitiesList
): DataPipe<UserRoleRequest[]> => {
  const col = getRoleRequestsCol()
    .where("tenant", "==", tenant)
    .where("status", "in", statuses)
    .where("isEndClient", "==", true);
  if (userHasPermission(roles, canApproveEndClientRoleRequests)) {
    return colPipe(col, "uid", "approveEndClientUsersPipe");
  } else if (entityIdsList.type === AssignedEntitiesListTypes.partial) {
    return batchColPipe(
      col,
      "entityId",
      "in",
      entityIdsList.entityIds,
      "uid",
      "pendingRoleRequestsProducersPipe"
    );
  } else {
    return DataPipe.withInitialData(null, []);
  }
};

const createProfilesPipe = (
  requests: UserRoleRequest[],
  tenant: string
): DataPipe<UserProfile[]> =>
  getProfilesByUidPipe(
    getProfileCol()
      .where("tenant", "==", tenant)
      .where("disabled", "==", false),
    requests.map((request) => request.uid),
    "approveEndClientUsersProfilePipe"
  );

const createEndClientRequestsPipe = (
  tenant: string,
  roles: RolesMap,
  entityIdsList: AssignedEntitiesList
): DataPipe<FullUserInfo[]> =>
  createBasicEndClientRequestsPipe(
    roles,
    tenant,
    [RoleRequestStatus.pending, RoleRequestStatus.ignored],
    entityIdsList
  ).pipeToPipe((requests) =>
    createProfilesPipe(requests, tenant).pipe((profiles) =>
      leftJoin(
        requests,
        profiles,
        "uid",
        "uid",
        (roleRequest, profile) =>
          ({
            ...profile,
            endClientIds: roleRequest.entityId ? [roleRequest.entityId] : [],
            roleRequest,
            roles: roleRequest.requestedRoles,
          } as FullUserInfo) // spread is not keeping type o.o
      )
    )
  );

const filterResults = (
  pipe: DataPipe<FullUserInfo[]>,
  filters: UsersListPipeFilters
) =>
  pipe.pipe((users) =>
    users.filter(
      ({ roleRequest }) =>
        roleRequest &&
        ((roleRequest.status === RoleRequestStatus.pending &&
          filters?.showPendingEndClientRoleRequests) ||
          (roleRequest.status === RoleRequestStatus.ignored &&
            filters?.showIgnoredEndClientRoleRequests))
    )
  );

export const createApproveEndClientUsersPipe = ({
  tenant,
  roles,
  filters,
  entityIdsList,
}: UsersListDataPipeArgs): DataPipe<FullUserInfo[]> => {
  if (
    !filters?.showPendingEndClientRoleRequests &&
    !filters?.showIgnoredEndClientRoleRequests
  ) {
    return DataPipe.withInitialData(null, []);
  }
  return filterResults(
    createEndClientRequestsPipe(tenant, roles, notNull(entityIdsList)),
    filters
  );
};
