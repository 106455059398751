/* DON'T EDIT THIS FILE: edit original and run build again */ export const blue20 = "#0459c8";
export const blue50 = "#0771fa";
export const blue70 = "#83b8fd";
export const gray10 = "#111827";
export const gray20 = "#1f2937";
export const gray30 = "#485460";
export const gray40 = "#6b7280";
export const gray50 = "#9ca3af";
export const gray60 = "#d1d5db";
export const gray70 = "#e5e7eb";
export const gray80 = "#eff1f1";
export const white = "#ffffff";
export const orange40 = "#fbcc71";
export const orange70 = "#fde6b8";
export const green20 = "#39bc78";
export const green40 = "#4ade80";
export const green70 = "#a5efc0";
export const warning = "#f53b57";

export const allColors = {
  blue20,
  blue50,
  blue70,
  gray10,
  gray20,
  gray30,
  gray40,
  gray50,
  gray60,
  gray70,
  gray80,
  orange40,
  orange70,
  green20,
  green40,
  green70,
  warning,
  white,
};
