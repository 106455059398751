/* DON'T EDIT THIS FILE: edit original and run build again */ import { CommandButton } from "../../html-editor/button/command-button.tsx";
import { CutIcon } from "../../theme/icon.ts";
import { useCommands, useCurrentSelection } from "@remirror/react";
import { useCallback } from "react";

export const CutButton = () => {
  const { cut } = useCommands();
  // Force component update on selection change
  useCurrentSelection();

  const handleAction = useCallback(() => {
    if (cut.enabled()) {
      cut();
    }
  }, [cut]);

  const enabled = cut.enabled();

  return (
    <CommandButton
      commandName="cut"
      active={false}
      enabled={enabled}
      onAction={handleAction}
    >
      <CutIcon />
    </CommandButton>
  );
};
