/* DON'T EDIT THIS FILE: edit original and run build again */ import { EditorButtonGroup } from "../../html-editor/button-group/editor-button-group.tsx";
import { CopyButton } from "../../html-editor/button/copy-button.tsx";
import { CutButton } from "../../html-editor/button/cut-button.tsx";
import { PasteButton } from "../../html-editor/button/paste-button.tsx";

export const DataTransferButtonGroup = () => (
  <EditorButtonGroup name="data-transfer">
    <CopyButton />
    <CutButton />
    <PasteButton />
  </EditorButtonGroup>
);
