/* DON'T EDIT THIS FILE: edit original and run build again */ import { useDelayedChangeTextInputState } from "../../ui/form/delayed-change-text-input.tsx";
import { BaseTextLiveField } from "./base-text-live-field-spec.tsx";
import { LiveFieldInputParam } from "./types.ts";

export const useLiveAfterBlurTextInput = ({
  liveCell,
  saveField,
  onEnterPress,
}: LiveFieldInputParam) => {
  const liveField = liveCell.getLiveField();
  if (!(liveField instanceof BaseTextLiveField)) {
    throw new Error("useLiveAfterBlurTextInput: expected BaseTextLiveField");
  }
  const value = liveCell.getValue() ?? "";
  const autoComplete = liveField.getAutoComplete();
  const className = liveField.getClassName();
  const error = liveCell.getError();
  return {
    disabled: liveCell.isDisabled(),
    autoFocus: liveField.getAutoFocus(),
    className: (error ? "is-invalid" : "") + " " + className,
    autoComplete: autoComplete,
    ...useDelayedChangeTextInputState({
      value: value as string,
      setValue: (value: string) => saveField(liveField.getField(), value),
      onEnterPress,
    }),
  };
};
