/* DON'T EDIT THIS FILE: edit original and run build again */ import {
  ErrorMessage,
  ErrorPage,
  SimpleErrorPage,
} from "./error-page.front.tsx";

const title = "Página no encontrada";
const note =
  "La página solicitada no existe, por favor vuelva a la página anterior.";

export const PageNotFoundPage = () => <ErrorPage title={title} note={note} />;

export const SimplePageNotFoundPage = () => (
  <SimpleErrorPage title={title} note={note} />
);

export const PageNotFoundMessage = () => (
  <ErrorMessage title={title} note={note} />
);
