/* DON'T EDIT THIS FILE: edit original and run build again */ import { EntityType } from "../../gov-id-number/entity-type.ts";
import { EMAIL } from "../../signatura-document-validations/document-validations.ts";
import { BaseOnboardingOptions } from "../../tenant-handlers/base-onboarding-options.ts";
import { ComponentType } from "react";

class LamOnboardingOptions extends BaseOnboardingOptions {
  hasSignatura(): boolean {
    return true;
  }

  hasGenderField(): boolean {
    return false;
  }

  hasVideoSelfie(): boolean {
    return false;
  }

  getSignaturaValidations(): string[] {
    return [EMAIL];
  }

  getEmailAfterLabelText(): string | null {
    return null;
  }

  hasInvestmentProfile(): boolean {
    return false;
  }

  hasProofOfFciRulebook(): boolean {
    return false;
  }

  hasFullGeneralInfoSection(): boolean {
    return true;
  }

  hasFatca(): boolean {
    return true;
  }

  hasMatbaRofex(): boolean {
    return false;
  }

  hasSgrComissions(): boolean {
    return false;
  }

  hasCorporateAdminSection(): boolean {
    return true;
  }

  hasBeneficiaryDoc(): boolean {
    return false;
  }

  hasOwnedEntityField(): boolean {
    return false;
  }

  usesUruguayLaw(): boolean {
    return true;
  }

  getBeneficiaryEntityType(): string {
    return EntityType.OnlyHumans;
  }

  finalBeneficiariesPercentage(): number {
    return 15;
  }

  hasOptionToNotUploadDocuments(): boolean {
    return false;
  }

  hasTaxStatusOfRepresentatives(): boolean {
    return false;
  }

  hasAssemblyAttendanceDocument(): boolean {
    return true;
  }

  async getDocumentHeader(): Promise<
    ComponentType<{ agreementLabel: string }>
  > {
    return (await (() => { throw new Error("This file is not meant to be imported in this context.") })()).default;
  }
}

export const lamOnboardingOptions = new LamOnboardingOptions();
