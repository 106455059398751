/* DON'T EDIT THIS FILE: edit original and run build again */ import { LiveTextViewer } from "../../live-form/ui/text-viewer.tsx";
import {
  LiveFieldInputParam,
  LiveFieldRenderer,
} from "../../live-form/ui/types.ts";
import { useLiveAfterBlurTextInput } from "../../live-form/ui/use-live-after-blur-text-input.tsx";
import { TextareaInput } from "../../ui/form/textarea-input.tsx";
import { longTextLiveFieldType } from "./type.ts";

const Input = (fieldInfo: LiveFieldInputParam) => (
  <TextareaInput {...useLiveAfterBlurTextInput(fieldInfo)} />
);

export const longTextLiveFieldRenderers: LiveFieldRenderer = {
  type: longTextLiveFieldType,
  Input: Input,
  Viewer: LiveTextViewer,
};
