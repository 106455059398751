/* DON'T EDIT THIS FILE: edit original and run build again */ export const PENDING = "pending";
export const ACCEPTED = "accepted";
export const CANCELLED = "cancelled";

export type InviteLinkStatus =
  | typeof PENDING
  | typeof ACCEPTED
  | typeof CANCELLED; // FIXME: use enum

const labels = {
  [PENDING]: "Pendiente",
  [ACCEPTED]: "Aceptada",
  [CANCELLED]: "Cancelada",
};

export const inviteUserStatuses = Object.keys(labels);

export const getInviteUserStatusLabel = (status) => {
  if (!labels[status]) {
    throw new Error("unknownInviteUserStatus: " + status);
  }
  return labels[status];
};
