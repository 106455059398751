/* DON'T EDIT THIS FILE: edit original and run build again */ import { useNavigateInTenant } from "../routing/tenant-routing.tsx";
import { Button } from "react-bootstrap";
import { PageBorder, SimplePageBorder } from "./page-border.front.tsx";

export const ErrorMessage = ({
  title,
  note = undefined,
  showHomeLink = true,
}: {
  title?: React.ReactNode;
  note?: React.ReactNode;
  showHomeLink?: boolean;
}) => {
  const navigate = useNavigateInTenant();
  return (
    <div className="col-md-10 d-flex align-items-center flex-column mt-7">
      <div className={"text-xl fw-bold"}>{title}</div>
      {note ? <div className="pt-3">{note}</div> : null}
      {showHomeLink ? (
        <Button className="mt-5" onClick={() => navigate("/")} variant="blue50">
          Ir al home
        </Button>
      ) : null}
    </div>
  );
};

export const SimpleErrorMessage = ({
  title,
  note = undefined,
}: {
  title?: React.ReactNode;
  note?: React.ReactNode;
}) => {
  return (
    <div className="d-flex align-items-center flex-column mt-7">
      <div className={"text-xl fw-bold"}>{title}</div>
      {note ? <div className="pt-3">{note}</div> : null}
    </div>
  );
};

export const ErrorPage = ({
  title = undefined,
  note = undefined,
  showHomeLink = true,
}: {
  title?: React.ReactNode;
  note?: React.ReactNode;
  showHomeLink?: boolean;
}) => {
  return (
    <PageBorder>
      <ErrorMessage title={title} note={note} showHomeLink={showHomeLink} />
    </PageBorder>
  );
};

export const SimpleErrorPage = ({
  title = undefined,
  note = undefined,
}: {
  title?: React.ReactNode;
  note?: React.ReactNode;
}) => {
  return (
    <SimplePageBorder>
      <div className="flex-grow-1">
        <div className="py-4">
          <SimpleErrorMessage title={title} note={note} />
        </div>
      </div>
    </SimplePageBorder>
  );
};
