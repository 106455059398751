/* DON'T EDIT THIS FILE: edit original and run build again */ import {
  emailChannelType,
  whatsappChannelType,
} from "../../channel/channel-types.ts";
import { BaseOnboardingOptions } from "../../tenant-handlers/base-onboarding-options.ts";

class StcOnboardingOptions extends BaseOnboardingOptions {
  hasSignatura(): boolean {
    return true;
  }

  hasAune(): boolean {
    return true;
  }

  hasHumanDirectShareholders(): boolean {
    return false;
  }

  getSupportedChannelTypes(): string[] {
    return [whatsappChannelType, emailChannelType];
  }

  getEmailAfterLabelText(): string | null {
    return (
      "Este mail será detallado en el PUC de Caja de Valores " +
      "(Consulta Online de saldos y movimientos de la cuenta corriente)."
    );
  }

  hasOwnedEntityField(): boolean {
    return false;
  }

  hasEstimatedAmountToInvest(): boolean {
    return true;
  }

  hasAssemblyAttendanceDocument(): boolean {
    return true;
  }

  hasPositionFieldRequired(): boolean {
    return true;
  }

  hasHasNoFinalBeneficiariesField(): boolean {
    return false;
  }
}

export const stcOnboardingOptions = new StcOnboardingOptions();
