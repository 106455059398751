/* DON'T EDIT THIS FILE: edit original and run build again */ import { canViewUserProfile } from "../users-profile-permissions/permissions.ts";
import { disabledUsersTypeId } from "./disabled-users/type.ts";
import { usersListTypes } from "./list-types.ts";
import { tenantUsersTypeId } from "./tenant-users/type.ts";

export const canLinkToUsersProfile = (
  userTypeId,
  { userProfile, loggedInProfile, loggedInPrivateProfile, loggedInRoles }
) =>
  [tenantUsersTypeId, disabledUsersTypeId].includes(userTypeId) &&
  canViewUserProfile(
    userProfile,
    loggedInProfile,
    loggedInPrivateProfile,
    loggedInRoles
  );

export const getPermissionsToListUsers = ({ roles, tenantHandler }) => {
  const out = {};
  for (const usersListType of usersListTypes) {
    out[usersListType.id] = usersListType.hasPermission({
      roles,
      tenantHandler,
    });
  }
  return out;
};

export const hasPermissionToListUsers = ({ roles, tenantHandler }) => {
  const permissions = getPermissionsToListUsers({ roles, tenantHandler });
  return Object.keys(permissions).some((key) => permissions[key]);
};
