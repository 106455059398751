/* DON'T EDIT THIS FILE: edit original and run build again */ import { LineOptions } from "../../tenant-handler-type/line-options.ts";
import { OnboardingOptions } from "../../tenant-handler-type/onboarding-options.ts";
import { SgrTenantHandler } from "../../tenant-handlers/sgr-tenant-handler.ts";
import { fintechsgrTenant } from "./code.ts";
import { fintechsgrLineOptions } from "./fintechsgr-line-options.ts";
import { fintechsgrOnboardingOptions } from "./fintechsgr-onboarding-options.ts";
import { fintechsgrFullLabel } from "./full-label.ts";

class FintechsgrTenantHandler extends SgrTenantHandler {
  getCode(): string {
    return fintechsgrTenant;
  }

  getLineOptions(): LineOptions {
    return fintechsgrLineOptions;
  }

  getOnboardingOptions(): OnboardingOptions | null {
    return fintechsgrOnboardingOptions;
  }

  getPathLabel(): string | null {
    return "fintech-sgr";
  }

  getLabel(): string {
    return "Fintech SGR";
  }

  getFullLabel(): string {
    return fintechsgrFullLabel;
  }

  hasOpinionReportSection(): boolean {
    return false;
  }

  getNotificationsFrom(): string {
    return "no-responder@matchfin.ar";
  }

  getComplianceEmailAddress(): string {
    return "fintechsgr@matchfin.ar";
  }

  getCuit(): string | null {
    return "33-71668098-9";
  }

  hasGrantedLineSection(): boolean {
    return true;
  }
}

const fintechsgrTenantHandler = new FintechsgrTenantHandler();
export default fintechsgrTenantHandler;
