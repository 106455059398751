/* DON'T EDIT THIS FILE: edit original and run build again */ import { OnboardingOptions } from "../../tenant-handler-type/onboarding-options.ts";
import { AlycTenantHandler } from "../../tenant-handlers/alyc-tenant-handler.ts";
import { winsecuritiesTenant } from "./code.ts";
import { winsecuritiesFullLabel } from "./full-label.ts";
import { winsecuritiesLabel } from "./label.ts";

class WinsecuritiesTenantHandler extends AlycTenantHandler {
  getCode(): string {
    return winsecuritiesTenant;
  }

  getPathLabel(): string | null {
    return "win-securities";
  }

  getLabel(): string {
    return winsecuritiesLabel;
  }

  getFullLabel(): string {
    return winsecuritiesFullLabel;
  }

  getOnboardingOptions(): OnboardingOptions | null {
    return null;
  }

  getNotificationsFrom(): string {
    return "no-responder@matchfin.ar";
  }

  getCuit(): string {
    return "30-69320460-3";
  }

  getCnvRegistryId(): string | null {
    return "298";
  }

  publishesInMarketplace(): boolean {
    return true;
  }
}

const winsecuritiesTenantHandler = new WinsecuritiesTenantHandler();

export default winsecuritiesTenantHandler;
