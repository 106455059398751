/* DON'T EDIT THIS FILE: edit original and run build again */ import { notNull } from "../core/not-null.ts";
import { fetchDoc } from "../firebase/fetch-doc.ts";
import {
  FirestoreDocument,
  createFirestoreCollection,
} from "../firebase/firestore-wrappers.ts";
import { Settings } from "./schema.ts";

// "settings/limits" === "settings"
// In the future this record should be editable by the admins
export const getSettingsDoc = (): FirestoreDocument<Settings> =>
  createFirestoreCollection<Settings>("settings").doc("limits");

export const maybeFetchSettings = async (): Promise<Settings | undefined> =>
  await fetchDoc(getSettingsDoc());

export const fetchSettings = async (): Promise<Settings> =>
  notNull(await maybeFetchSettings());
