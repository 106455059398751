/* DON'T EDIT THIS FILE: edit original and run build again */ import { Nullable } from "../core/nullable.ts";
import { WithId } from "../core/with-id.ts";
import { useMemoCompare } from "../react/use-memo-compare.ts";
import { useEffect, useMemo, useState } from "react";
import {
  DocumentBaseData,
  FirestoreDocumentSnapshot,
  FirestoreQuerySnapshot,
} from "./firestore-wrappers-types.ts";
import {
  FirestoreCollection,
  FirestoreDocument,
} from "./firestore-wrappers.ts";
import { Selector } from "./selector-type.ts";

const cleanupDelay = 10000;

type Comparable<TType> = TType & { isEqual(b: TType): boolean };

const selectorsEqual = <TSelector extends { isEqual(b: TSelector): boolean }>(
  a: Nullable<TSelector>,
  b: Nullable<TSelector>
): boolean => {
  if (!a || !b) {
    return a === b;
  } else {
    return a.isEqual(b);
  }
};

const useColOrDoc = <TValue, TSnapshot>(
  selector: Comparable<Selector<TSnapshot>> | null,
  transformer: (data: TSnapshot) => TValue
): [loading: boolean, value: TValue | null, error: string | Error | null] => {
  const [value, setValue] = useState<TValue | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null as Error | null);
  const memoSelector = useMemoCompare(selector, selectorsEqual);
  useEffect(() => {
    if (memoSelector) {
      let cleanedUp = false;
      setLoading(true);
      const cleanup = memoSelector.onSnapshot(
        (val) => {
          // we delay the actual cleanup to make firestore reuse the listener, so this could be triggered after it's not displayed
          if (cleanedUp) {
            return;
          }
          setValue(transformer(val));
          setLoading(false);
        },
        (error) => {
          setError(error);
          setLoading(false);
        }
      );
      return () => {
        cleanedUp = true;
        setTimeout(() => {
          cleanup();
        }, cleanupDelay);
      };
    } else {
      setValue(null);
      setLoading(false);
    }
  }, [memoSelector, transformer]);
  return [loading, value, error];
};

const docValToData =
  <Tkey extends string>(idProp: Tkey | null) =>
  <TData extends DocumentBaseData>(
    val: FirestoreDocumentSnapshot<TData>
  ): WithId<TData, Tkey> | TData | undefined => {
    if (!val.data) {
      console.error({ val });
    }
    const data = val.data();
    return data && idProp ? { ...data, [idProp]: val.id } : data;
  };

export function useDoc<TData extends DocumentBaseData>(
  doc: FirestoreDocument<TData>
): [boolean, TData | null, Error | null];

export function useDoc<
  TDataOne extends DocumentBaseData,
  TDataTwo extends DocumentBaseData
>(
  doc: FirestoreDocument<TDataOne> | FirestoreDocument<TDataTwo>
): [boolean, TDataOne | TDataTwo | null, Error | null]; // FIXME: extend to arbitrary number of generic types

export function useDoc<TData extends DocumentBaseData, TKey extends string>(
  doc: FirestoreDocument<TData>,
  idProp: TKey
): [boolean, WithId<TData, TKey> | null, Error | null];

export function useDoc<TData extends DocumentBaseData>(
  doc: FirestoreDocument<TData>,
  idProp: string | null = null
) {
  const transformer = useMemo(() => docValToData(idProp), [idProp]);
  return useColOrDoc(doc, transformer);
}

const colValToData =
  <TKey extends string>(idProp?: TKey) =>
  <TData extends Record<string, any>>(val: FirestoreQuerySnapshot<TData>) => {
    const docs: TData[] | WithId<TData, TKey>[] = [];
    if (!val.forEach) {
      console.error({ val });
    }
    val.forEach((doc) => {
      const data = doc.data();
      docs.push(idProp ? { ...data, [idProp]: doc.id } : data);
    });
    return docs;
  };

export function useCol<TData extends DocumentBaseData>(
  query: FirestoreCollection<TData> | null
): [boolean, TData[], string | Error | null];

export function useCol<TData extends DocumentBaseData, TKey extends string>(
  query: FirestoreCollection<TData> | null,
  idProp: TKey
): [boolean, WithId<TData, TKey>[], Error | null];

export function useCol<TData extends DocumentBaseData, TKey extends string>(
  query: FirestoreCollection<TData> | null,
  idProp?: TKey
) {
  const transformer = useMemo(() => colValToData(idProp), [idProp]);
  return useColOrDoc(query, transformer);
}
