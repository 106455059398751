/* DON'T EDIT THIS FILE: edit original and run build again */ import { InvestorTenantHandler } from "../../tenant-handlers/investor-tenant-handler.ts";
import { galileoTenant } from "./code.ts";
import { galileoFullLabel } from "./full-label.ts";
import { galileoLabel } from "./label.ts";

class GalileoTenantHandler extends InvestorTenantHandler {
  getCode(): string {
    return galileoTenant;
  }

  getLabel(): string {
    return galileoLabel;
  }

  getPathLabel(): string {
    return "galileo-argentina";
  }

  getFullLabel(): string {
    return galileoFullLabel;
  }

  getNotificationsFrom(): string {
    return "no-responder@matchfin.ar";
  }

  getCuit(): string | null {
    return "30-69436725-5";
  }
}

const galileoTenantHandler = new GalileoTenantHandler();

export default galileoTenantHandler;
