/* DON'T EDIT THIS FILE: edit original and run build again */ import { AttachmentRef } from "../attachment-type/attachment-ref-type.ts";

export type RichTextValue = {
  attachments: AttachmentRef[];
  text: string;
  html: string;
};

export const emptyRichTextValue: RichTextValue = {
  text: "",
  html: "",
  attachments: [],
};

Object.freeze(emptyRichTextValue);
