/* DON'T EDIT THIS FILE: edit original and run build again */ import { logoutPath } from "../auth-ui/logout-path.ts";
import { getUserFullName } from "../auth-user-full-name/get-user-full-name.ts";
import { SimpleErrorPage } from "../theme-pages/error-page.front.tsx";
import { Link } from "react-router-dom";

export const UndefinedRealmErrorPage = () => (
  <SimpleErrorPage
    title="Página no encontrada"
    note={
      <div>No pudimos identificar la configuración a usar desde el dominio</div>
    }
  />
);

export const RealmDomainMismatchErrorPage = ({ realmFromUrl }) => (
  <SimpleErrorPage
    title="Página no encontrada"
    note={
      <div>
        <p>El tenant {realmFromUrl} no trabaja en este dominio.</p>
      </div>
    }
  />
);

export const ConnectionErrorPage = () => (
  <SimpleErrorPage
    title="Problema de conexión"
    note={<div>No pudimos conectarnos con el servidor</div>}
  />
);

export const RealmAccountMismatchErrorPage = ({
  profile,
  userRealm,
  realmFromUrl,
  profileExpectedUrl,
}) => (
  <SimpleErrorPage
    title="Dirección incorrecta para usuario actual"
    note={
      <div>
        <p>
          Iniciaste sesión como {getUserFullName(profile)} ({profile.email}),
          que usa la dirección de {userRealm}, pero accediste por la de{" "}
          {realmFromUrl}.
        </p>
        <p>
          Para ir a {userRealm} entrá a{" "}
          <a href={profileExpectedUrl}>{profileExpectedUrl}</a>.
        </p>
        <p>
          Si querés entrar por {realmFromUrl} tenés que{" "}
          <Link to={logoutPath}>cerrar la sesión</Link> y volver a iniciar con
          otro usuario.
        </p>
      </div>
    }
  />
);
