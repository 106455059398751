/* DON'T EDIT THIS FILE: edit original and run build again */ import { BaseTenantHandler } from "../../tenant-handlers/base-tenant-handler.ts";
import { investorUserCompanyTypeId } from "../../user-company-type-const/user-company-type-const.ts";
import { southernTrustTenant } from "./code.ts";
import { southernTrustFullLabel } from "./full-label.ts";
import { southernTrustLabel } from "./label.ts";

class SouthernTrustTenantHandler extends BaseTenantHandler {
  getCode(): string {
    return southernTrustTenant;
  }

  getPathLabel(): string | null {
    return "southern-trust";
  }

  getLabel(): string {
    return southernTrustLabel;
  }

  getFullLabel(): string {
    return southernTrustFullLabel;
  }

  getNotificationsFrom(): string {
    return "no-responder@matchfin.ar";
  }

  getCuit(): string | null {
    return "33-71060890-9";
  }

  getUserCompanyType(): string {
    return investorUserCompanyTypeId;
  }
}

const southernTrustTenantHandler = new SouthernTrustTenantHandler();

export default southernTrustTenantHandler;
