/* DON'T EDIT THIS FILE: edit original and run build again */ import { CommandButton } from "../../html-editor/button/command-button.tsx";
import { useActive, useCommands } from "@remirror/react";
import { useCallback } from "react";

export const TableButton = () => {
  const { createTable } = useCommands();

  const handleAction = useCallback(() => {
    if (createTable.enabled()) {
      createTable();
    }
  }, [createTable]);

  const active = useActive().table();
  const enabled = createTable.enabled();

  return (
    <CommandButton
      commandName="table"
      active={active}
      enabled={enabled}
      onAction={handleAction}
    >
      Tabla
    </CommandButton>
  );
};
