/* DON'T EDIT THIS FILE: edit original and run build again */ import { intersperse } from "../core/intersperse.ts";
import { Div } from "../pdf-wrapper/pdf-wrapper.tsx";
import { RemoveButton } from "../theme/remove-button.tsx";
import { MouseEventHandler } from "react";
import { Button } from "react-bootstrap";

const InterspacedRow = ({ items }) => (
  <Div className="d-flex flex-row w-100 bg-gray80 justify-content-start align-items-center p-2 rounded-2 my-2 flex-wrap">
    {intersperse(
      items.filter((x) => x),
      (i) => (
        <Div className="ms-4" key={"sep_" + i} />
      )
    )}
  </Div>
);

const NumberDiv = ({ number, incomplete = false }) => (
  <Div
    className={
      "rounded-circle text-white p-1 d-flex align-items-center justify-content-center flex-shrink-0 text-s small-square " +
      (incomplete ? "bg-orange40" : "bg-green40")
    }
  >
    {number}
  </Div>
);

export const EditButton = ({
  onClick,
  className = "",
}: {
  onClick: MouseEventHandler<HTMLButtonElement>;
  className?: string;
}) => (
  <div className="flex-d align-items-center">
    <Button
      variant="outline-blue50"
      onClick={onClick}
      className={"d-flex align-items-center px-4 py-2 " + className}
    >
      <span>Editar</span>
    </Button>
  </div>
);

export const ListRow = ({
  number,
  onRemoveClick,
  onEditClick,
  incomplete = false,
  items,
}: {
  number?: number;
  onRemoveClick?: MouseEventHandler<HTMLButtonElement>;
  onEditClick?: MouseEventHandler<HTMLButtonElement>;
  incomplete?: boolean;
  items: React.ReactNode[];
}) => {
  return (
    <InterspacedRow
      items={[
        number ? (
          <NumberDiv key="numberIcon" number={number} incomplete={incomplete} />
        ) : null,
        ...items,
        onEditClick ? (
          <EditButton key="editButton" onClick={onEditClick} />
        ) : null,
        onRemoveClick ? (
          <RemoveButton key="removeButton" onClick={onRemoveClick} />
        ) : null,
      ]}
    />
  );
};
