/* DON'T EDIT THIS FILE: edit original and run build again */ import { useCurrentTenant } from "../app-state/app-state-context.ts";
import { AdminTenantSelect } from "../auth-admin-tenant-select/admin-tenant-select.tsx";
import { UserProfile } from "../auth-profile/schema.ts";
import { useRequiredActionsCached } from "../auth-required-actions-context/use-required-actions.front.ts";
import {
  useFirebaseAccount,
  useProfile,
  useRolesMap,
} from "../auth-ui/use-account.ts";
import { canAccessTenant } from "../auth/allowed-tenants.ts";
import { isEmptyObject } from "../core/is-empty-object.ts";
import { mapArrayToObject } from "../core/map-array-to-object.ts";
import { ascSorter } from "../core/sort-by-many.ts";
import { combinePipesAsObject } from "../data-pipe/combine-pipes-as-object.ts";
import { DataPipe } from "../data-pipe/data-pipe.ts";
import { usePipe } from "../firebase/use-pipe.ts";
// @ts-expect-error the hook is auto generated
import collectedMenuItems from "../hook/navbar-collect.js";
import { endClientUserRole } from "../permission/all-roles.ts";
import { RolesMap } from "../permission/schema.ts";
import {
  SidebarMenuItem,
  SidebarMenuItemContext,
} from "../sidebar-type/sidebar-types.ts";
import { SidebarMenuOption } from "../sidebar/menu-option.tsx";
import { SignOutButton } from "../sidebar/sign-out.tsx";
import { UserInfo } from "../sidebar/user-info.tsx";
import { getTenantHandler } from "../tenant-collection/tenant.ts";
import { TenantHandler } from "../tenant-handler-type/tenant-handler.ts";
import {
  LogoAwaSmallIcon,
  LogoBrioSmallIcon,
  LogoMatchfinSmallIcon,
  LogoRosvalSmallIcon,
  LogoStcSmallIcon,
  LogoZofingenSmallIcon,
} from "../theme/icon.ts";
import { isUserUsingMobile } from "../ui/user-in-mobile.ts";
import { useState } from "react";

const buildMenuItems = (): SidebarMenuItem[] => {
  const menuItems = [...collectedMenuItems];
  menuItems.sort(ascSorter((item) => item.order));
  return menuItems;
};

let menuOptions: SidebarMenuItem[] | undefined;

const getMenuItems = ({
  roles,
  profile,
  tenant,
  tenantHandler,
}: {
  roles: RolesMap;
  profile: UserProfile;
  tenant: string;
  tenantHandler: TenantHandler;
}): SidebarMenuItem[] => {
  if (!menuOptions) {
    menuOptions = buildMenuItems();
  }
  const filteredMenuOptions = menuOptions.filter(({ condition }) =>
    condition({ roles, profile, tenant, tenantHandler })
  );
  return filteredMenuOptions;
};

const createNotificationsPipe = (
  dropDownItems: SidebarMenuItem[],
  props: SidebarMenuItemContext
) =>
  combinePipesAsObject(
    mapArrayToObject(dropDownItems, (item) => {
      if (item.getHasNotificationsPipe) {
        return [item.id, item.getHasNotificationsPipe(props)];
      }
      return [
        item.id,
        DataPipe.withInitialData(null, { unread: 0, badge: null }),
      ];
    })
  );

const getSideBarLogo = (tenant: string): React.ReactNode => {
  // FIXME: this should be in tenantHandler
  if (tenant === "rosval") {
    return <LogoRosvalSmallIcon className="icon-2" />;
  }
  if (tenant === "zofingen") {
    return <LogoZofingenSmallIcon className="icon-2" />;
  }
  if (tenant === "brio") {
    return <LogoBrioSmallIcon className="icon-2" />;
  }
  if (tenant === "stc") {
    return <LogoStcSmallIcon className="icon-2" />;
  }
  if (tenant === "awa") {
    return <LogoAwaSmallIcon className="icon-2" />;
  }
  return <LogoMatchfinSmallIcon className="icon-2" />;
};

export const Sidebar = ({ current }: { current?: string }): React.ReactNode => {
  const account = useFirebaseAccount();
  const roles = useRolesMap();
  const profile = useProfile();
  const tenant = useCurrentTenant();
  const tenantHandler = getTenantHandler(tenant);
  const isMobile = isUserUsingMobile();
  const menuItems =
    account && canAccessTenant(profile, tenant)
      ? getMenuItems({
          roles,
          profile,
          tenant,
          tenantHandler,
        })
      : [];
  const primaryItems = menuItems.filter((x) => {
    return x.hasPriority;
  });
  const secondaryItems = menuItems.filter((x) => {
    return !x.hasPriority;
  });
  const [loadingNotifications, notificationData] = usePipe(
    createNotificationsPipe,
    [menuItems, { tenant, tenantHandler, roles, profile }]
  );
  const [focused, setFocused] = useState(false);
  const [hovered, setHovered] = useState(false);
  const open = focused || hovered;
  const { hasRequiredActions } = useRequiredActionsCached();
  if (hasRequiredActions || isEmptyObject(profile) || isEmptyObject(roles)) {
    return null;
  }
  return (
    <div style={{ width: "58px" }}>
      <div
        className={
          "bg-gray20 sidebar d-flex flex-column justify-content-between p-2 " +
          (open ? "sidebar-expanded" : "sidebar-collapsed")
        }
        tabIndex={0}
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
        style={{
          overflowY: isMobile ? "scroll" : "hidden",
          scrollbarWidth: "none",
          msOverflowStyle: "none",
        }}
      >
        <div>
          {!roles[endClientUserRole] ? (
            <SidebarMenuOption
              icon={() => getSideBarLogo(tenant)}
              path={"/"}
              label={"Matchfin"}
              showLabel={false}
              showHover={false}
            />
          ) : null}
          {primaryItems.map(({ id, getPath, label, icon }) => (
            <SidebarMenuOption
              path={getPath({ roles })}
              label={label}
              icon={icon}
              notificationData={
                !loadingNotifications && notificationData
                  ? notificationData[id]
                  : null
              }
              sidebarOpen={open}
              selected={current === id}
              key={id}
            />
          ))}
          {!isEmptyObject(secondaryItems) && (
            <hr className="mt-3 mb-3 border border-gray50 border-1 w-100" />
          )}
          {secondaryItems.map(({ id, getPath, label, icon }) => (
            <SidebarMenuOption
              path={getPath({ roles })}
              label={label}
              icon={icon}
              notificationData={
                current !== id && !loadingNotifications && notificationData
                  ? notificationData[id]
                  : null
              }
              selected={current === id}
              sidebarOpen={open}
              key={id}
            />
          ))}
        </div>
        {profile && profile.email ? (
          <div>
            <UserInfo profile={profile} open={open} />
            <div
              className="d-flex flex-row"
              style={open ? {} : { visibility: "hidden" }}
            >
              <AdminTenantSelect />
              <SignOutButton />
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};
