/* DON'T EDIT THIS FILE: edit original and run build again */ import { CommandButton } from "../../html-editor/button/command-button.tsx";
import { CopyIcon } from "../../theme/icon.ts";
import { useCommands, useCurrentSelection } from "@remirror/react";
import { useCallback } from "react";

export const CopyButton = () => {
  const { copy } = useCommands();
  // Force component update on selection change
  useCurrentSelection();

  const handleAction = useCallback(() => {
    if (copy.enabled()) {
      copy();
    }
  }, [copy]);

  const enabled = copy.enabled();

  return (
    <CommandButton
      commandName="copy"
      active={false}
      enabled={enabled}
      onAction={handleAction}
    >
      <CopyIcon />
    </CommandButton>
  );
};
