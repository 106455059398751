/* DON'T EDIT THIS FILE: edit original and run build again */ import { getUserFullName } from "../auth-user-full-name/get-user-full-name.ts";
import { BaseFolder } from "../base-folder/base-folder-type.ts";
import { pathUpdate } from "../dotpath/path.ts";
import {
  optionalDateOfBirthStructure,
  requiredDateOfBirthStructure,
} from "../entity-core-field/date-of-birth-entition.ts";
import {
  extraNameFieldset,
  nameFields,
} from "../entity-core-field/name-fields.ts";
import { getEntityInfo } from "../entity-info/get-entity-info.ts";
import { InlineEntity } from "../entity/inline-entity-type.ts";
import { EntityType } from "../gov-id-number/entity-type.ts";
import { LiveFieldset } from "../live-form/fieldset.ts";
import { LiveRecord } from "../live-form/live-record.ts";
import { LiveData } from "../live-form/types.ts";
import { RawLiveForm } from "../live-form/ui/raw-live-form.tsx";
import { LiveFieldGetLiveFormInputFactory } from "../live-form/ui/types.ts";
import { SmallLoading } from "../theme/loading.tsx";
import { LabeledCheckbox } from "../ui/form/labeled-checkbox.tsx";
import clone from "just-clone";
import { useRef, useState } from "react";
import { IdSection } from "./id-section.tsx";

enum EntitySearchStatus {
  Searching = "searching",
  Found = "found",
  NotFound = "notFound",
}

const EntitySearchStatusContent = ({
  status,
}: {
  status: EntitySearchStatus | null;
}) => {
  if (!status) {
    return null;
  } else if (status === EntitySearchStatus.Searching) {
    return (
      <div>
        <SmallLoading />
        <div className="ms-1">Buscando...</div>
      </div>
    );
  } else if (status === EntitySearchStatus.Found) {
    return <div className="ms-1">Encontrado</div>;
  } else {
    return <div className="ms-1">No encontrado, se creará nuevo</div>;
  }
};

const EntitySearchStatusWidget = ({
  status,
}: {
  status: EntitySearchStatus | null;
}) => (
  <div
    className={"m-2 flex-shrink-0 " + (status ? "" : "invisible")}
    style={{ width: "12em" }}
  >
    <EntitySearchStatusContent status={status} />
  </div>
);

const getValueWithEntityName = (value: Partial<InlineEntity>) => {
  const isEmpty = (value: Partial<InlineEntity>) =>
    !value.country || !value.foreignIdNumber;
  if (value.isCompany || isEmpty(value)) {
    return value;
  } else {
    return {
      ...value,
      entityName: getUserFullName({
        firstName: value.firstName,
        lastName: value.lastName,
      }),
    };
  }
};

export const ForeignInfo = ({
  value,
  setValue,
  options,
  baseFolder,
  getLiveFormInputFactory,
}: {
  value: Partial<InlineEntity>;
  setValue: (value: Partial<InlineEntity> | undefined) => void;
  options: Record<string, any>;
  baseFolder?: BaseFolder;
  getLiveFormInputFactory: LiveFieldGetLiveFormInputFactory;
}) => {
  const {
    required,
    allowEntityTypes,
    validate,
    includeDateOfBirth,
    includeName,
    includeExtraNameFields,
    dateOfBirthRequired,
  } = options;
  const [searching, setSearching] = useState(null as null | boolean);
  const [searched, setSearched] = useState(false);
  const [found, setFound] = useState(false);
  const cancelLastSearchRef = useRef(null as null | (() => void));
  const showErrors =
    validate && (required || (value.country && !value.foreignIdNumber));
  const disabled = !value.foreignIdNumber || !value.country || searching;
  const liveData: LiveData = {
    baseFolder,
    specificFolder: undefined,
    recordValue: value,
    viewOnly: disabled ?? undefined,
  };
  const createLiveForm = (
    fieldset: LiveFieldset,
    setValue: (value: any) => void
  ) => {
    return (
      <RawLiveForm
        getLiveFormInputFactory={getLiveFormInputFactory}
        liveRecord={
          new LiveRecord(fieldset, liveData, showErrors, disabled ?? undefined)
        }
        saveField={(field, newFieldValue) => {
          const newValue = clone(value);
          pathUpdate(newValue, field, newFieldValue);
          setValue(newValue);
        }}
        allowDefaultValue={false}
      />
    );
  };

  return (
    <>
      <IdSection
        {...{
          showErrors,
          value: {
            foreignIdNumber: value.foreignIdNumber,
            country: value.country,
          },
          setValue: async ({
            country,
            foreignIdNumber,
          }: {
            country: string;
            foreignIdNumber: string;
          }) => {
            const valueToSave: any = { country, foreignIdNumber };
            if (allowEntityTypes !== EntityType.Any) {
              valueToSave.isCompany =
                allowEntityTypes === EntityType.OnlyCompanies;
            }
            if (!country || !foreignIdNumber) {
              setValue(valueToSave);
            } else {
              if (cancelLastSearchRef.current) {
                cancelLastSearchRef.current();
              }
              let canceled = false;
              cancelLastSearchRef.current = () => {
                canceled = true;
              };
              setSearching(true);
              const info = (
                await getEntityInfo({
                  country,
                  foreignIdNumber,
                  baseFolder,
                })
              ).data;
              if (!canceled) {
                setSearching(null);
                setSearched(true);
                setFound(!!info);
                setValue(info ? info : { ...value, ...valueToSave });
              }
            }
          },
          afterIdNumber: (
            <EntitySearchStatusWidget
              status={
                searching
                  ? EntitySearchStatus.Searching
                  : searched
                  ? found
                    ? EntitySearchStatus.Found
                    : EntitySearchStatus.NotFound
                  : null
              }
            />
          ),
        }}
      />
      {!disabled && allowEntityTypes === EntityType.Any ? (
        <LabeledCheckbox
          label="Es una persona humana"
          className="mb-2"
          disabled={false}
          value={!value.isCompany}
          setValue={(isHuman) => setValue({ ...value, isCompany: !isHuman })}
        />
      ) : null}
      {includeName && !disabled
        ? createLiveForm(new LiveFieldset(nameFields), (value) =>
            setValue(getValueWithEntityName(value))
          )
        : null}
      {includeExtraNameFields && !disabled
        ? createLiveForm(extraNameFieldset, setValue)
        : null}
      {includeDateOfBirth && !disabled
        ? createLiveForm(
            dateOfBirthRequired
              ? new LiveFieldset(requiredDateOfBirthStructure)
              : new LiveFieldset(optionalDateOfBirthStructure),
            setValue
          )
        : null}
    </>
  );
};
