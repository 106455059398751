/* DON'T EDIT THIS FILE: edit original and run build again */ import { argentinaCountryCode } from "../country/country-codes.ts";
import { getEntityId } from "../entity/get-entity-id.ts";
import { getEmailWithName } from "../mail/get-email-with-name.ts";
import { LineOptions } from "../tenant-handler-type/line-options.ts";
import { TenantHandler } from "../tenant-handler-type/tenant-handler.ts";
import { matchfinTenant } from "../tenant-matchfin-code/matchfin-tenant-code.ts";
import { ComponentType, SVGProps } from "react";
import { BaseLineOptions } from "./base-line-options.ts";
import { BaseOnboardingOptions } from "./base-onboarding-options.ts";

const defaultLineOptions = new BaseLineOptions();

export abstract class BaseTenantHandler implements TenantHandler {
  private entityId: string | null;

  async getAuthPageLogo(): Promise<ComponentType<SVGProps<SVGElement>> | null> {
    return null;
  }

  hasOpinionReportSection(): boolean {
    return true;
  }

  abstract getLabel(): string;

  abstract getCode(): string;

  abstract getCuit(): string | null;

  abstract getUserCompanyType(): string | null;

  abstract getPathLabel(): string | null;

  abstract getFullLabel(): string | null;

  getComplianceEmailAddress(): string | null {
    return null;
  }

  canSignUp(): boolean {
    return (
      this.supportsOnboardings() ||
      this.doEndClientUsersHaveAccessToTheirEndClientLines()
    );
  }

  getLineOptions(): BaseLineOptions | null {
    return defaultLineOptions;
  }

  supportsOnboardings(): boolean {
    return this.getOnboardingOptions() !== null;
  }

  getNotificationsFromWithName() {
    return getEmailWithName(this.getNotificationsFrom(), this.getFullLabel());
  }

  abstract getNotificationsFrom(): string;

  getOnboardingOptions(): BaseOnboardingOptions | null {
    return null;
  }

  getOnboardingOptionsOrFail() {
    const options = this.getOnboardingOptions();
    if (!options) {
      throw new Error("noOnboardingOptionsForTenant: " + this.getCode());
    }
    return options;
  }

  getLineOptionsOrFail(): LineOptions {
    const options = this.getLineOptions();
    if (!options) {
      throw new Error("noLineOptionsForTenant: " + this.getCode());
    }
    return options;
  }

  canCreateInviteLinks(): boolean {
    return true;
  }

  getEntityId() {
    if (!this.entityId) {
      this.entityId = getEntityId({
        cuit: this.getCuit() ?? undefined,
        country: this.getTenantCompanyCountry(),
        foreignIdNumber: this.getTenantCompanyForeignIdNumber() ?? undefined,
      });
    }
    return this.entityId;
  }

  publishesInMarketplace(): boolean {
    return false;
  }

  supportsFinancerUsers(): boolean {
    return false;
  }

  getTenantCompanyCountry(): string {
    return argentinaCountryCode;
  }

  getTenantCompanyForeignIdNumber(): string | null {
    return null;
  }

  supportsDomesticBankAccounts(): boolean {
    return true;
  }

  supportsBcraBankAccounts(): boolean {
    return true;
  }

  doEndClientUsersHaveAccessToTheirEndClientLines(): boolean {
    return false;
  }

  doesProducersSignUpViaMatchfinRealm(): boolean {
    return false;
  }

  hasGrantedLineSection(): boolean {
    return false;
  }

  getMavAgentId(): number | null {
    return null;
  }

  getOfficeBranches(): { id: string; name: string }[] | null {
    return null;
  }

  usesDataLoadedStatus(): boolean {
    return false;
  }

  hasSemaphoreSettings(): boolean {
    return true;
  }

  hasSgrsReportsSection(): boolean {
    return this.getCode() === matchfinTenant;
  }
}
