/* DON'T EDIT THIS FILE: edit original and run build again */ import { AttachmentRef } from "../attachment-type/attachment-ref-type.ts";
import React, { useContext } from "react";

export const HtmlEditorContext = React.createContext<{
  userId: string | null;
  htmlEditorState: any;
  onEditorChange:
    | null
    | ((
        value: string,
        attachment: AttachmentRef & { permalink: string }
      ) => void);
}>({
  userId: null,
  htmlEditorState: null,
  onEditorChange: null,
});

export const useHtmlEditorContext = () => useContext(HtmlEditorContext);
