/* DON'T EDIT THIS FILE: edit original and run build again */ import { useIsInPdf } from "../pdf-wrapper/context.ts";
import { CSSProperties, MouseEventHandler } from "react";

let pdfIconMap, webIconMap;

export const setPdfIconMap = (value) => {
  pdfIconMap = value;
};

export const setWebIconMap = (value) => {
  webIconMap = value;
};

export const createGenericIcon =
  (name: string) =>
  ({
    width,
    height,
    size = 16,
    ...props
  }: {
    width?: number;
    height?: number;
    size?: number;
    style?: CSSProperties;
    onClick?: MouseEventHandler<SVGSVGElement>;
    onMouseEnter?: MouseEventHandler<SVGSVGElement>;
    onMouseLeave?: MouseEventHandler<SVGSVGElement>;
    tabIndex?: number;
    color?: string;
    fill?: string;
    className?: string;
  }) => {
    const isInPdf = useIsInPdf();
    const Icon = isInPdf ? pdfIconMap[name] : webIconMap[name];
    return (
      <Icon
        {...props}
        width={width ?? size}
        height={height ?? size}
        data-icon={name}
      />
    );
  };
