/* DON'T EDIT THIS FILE: edit original and run build again */ import { InvestorTenantHandler } from "../../tenant-handlers/investor-tenant-handler.ts";
import { ComponentType, SVGProps } from "react";
import { argenfundsOnboardingOptions } from "./argenfunds-onboarding-options.ts";
import { argenfundsTenant } from "./code.ts";
import { argenfundsFullLabel } from "./full-label.ts";

class ArgenfundsTenantHandler extends InvestorTenantHandler {
  async getAuthPageLogo(): Promise<ComponentType<SVGProps<SVGElement>>> {
    // @ts-expect-error the hook is auto generated
    return (await import("./logo_argenfunds.svg?react")).default;
  }

  getOnboardingOptions() {
    return argenfundsOnboardingOptions;
  }

  getCode() {
    return argenfundsTenant;
  }

  getPathLabel(): string | null {
    return "argenfunds";
  }

  getLabel() {
    return "Argenfunds";
  }

  getFullLabel() {
    return argenfundsFullLabel;
  }

  getNotificationsFrom() {
    return "no-responder@matchfin.ar";
  }

  getComplianceEmailAddress(): string {
    return "teamcompliance@argenfunds.com.ar";
  }

  getCuit() {
    return "30-71159660-3";
  }
}

const argenfundsTenantHandler = new ArgenfundsTenantHandler();

export default argenfundsTenantHandler;
