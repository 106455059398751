/* DON'T EDIT THIS FILE: edit original and run build again */ import { SimplePageBorder } from "../theme-pages/page-border.front.tsx";
import { Button } from "react-bootstrap";

export const MustUpdatePage = () => {
  return (
    <SimplePageBorder>
      <div className="col-md-10 d-flex align-items-center flex-column mt-7">
        <div className="text-xl fw-bold text-center mb-3">
          Nueva versión disponible
        </div>
        <div>Por favor recargue la página.</div>
        <Button
          variant="blue50"
          className="mt-5"
          onClick={() => window.location.reload()}
        >
          Recargar
        </Button>
      </div>
    </SimplePageBorder>
  );
};
