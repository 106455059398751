/* DON'T EDIT THIS FILE: edit original and run build again */ import { Gender, getGenderLabel } from "../gender/genders.ts";
import { gray50 } from "../theme/custom-colors.ts";
import { DeleteForeverIcon } from "../theme/icon.ts";
import { CuitFormControl } from "../ui/form/cuit-form-control.tsx";
import { DniFormControl } from "../ui/form/dni-form-control.tsx";
import { onEnterToOnKeyPress } from "../ui/form/on-enter.ts";
import { CuitStatusContent } from "./base-cuit-input.tsx";
import { CuitInputState } from "./cuit-input-state.ts";

export const CuitInput = ({
  autoFocus = undefined,
  id,
  onEnterPress,
  cuitInputState,
}: {
  autoFocus?: boolean;
  id?: string;
  onEnterPress?: () => void;
  cuitInputState: CuitInputState;
}) => {
  const {
    idNumber,
    loadingIdNumber,
    entityName,
    error,
    markError,
    dispatchFocusInInput,
    dispatchBlurInInput,
    dispatchFocusInMatchSelector,
    dispatchBlurInMatchSelector,
    setValue,
    setMatch,
    useDni,
    matches,
    fixed,
    isFocused,
    matchChosen,
  } = cuitInputState;
  const props = {
    key: "input",
    onKeyPress: onEnterPress ? onEnterToOnKeyPress(onEnterPress) : undefined,
    autoFocus: autoFocus,
    onFocus: dispatchFocusInInput,
    onBlur: dispatchBlurInInput,
    id: id,
    className: markError ? "is-invalid" : "",
    value: idNumber,
    setValue,
  };
  return (
    <div>
      <div className="d-flex align-items-center flex-gap-4">
        <div>
          {fixed ? (
            <div>
              {idNumber}{" "}
              <DeleteForeverIcon
                tabIndex={-1}
                className="cursor-pointer"
                onClick={() => setValue("")}
              />
            </div>
          ) : useDni ? (
            <DniFormControl {...props} />
          ) : (
            <CuitFormControl {...props} />
          )}
        </div>
        <CuitStatusContent
          {...{
            loadingIdNumber,
            markError,
            error,
            idNumber,
            entityName,
          }}
        />
      </div>
      {isFocused && matches && !matchChosen ? (
        <div
          className="position-absolute bg-white rounded py-1"
          tabIndex={0}
          onFocus={dispatchFocusInMatchSelector}
          onBlur={dispatchBlurInMatchSelector}
          style={{ boxShadow: "0px 1px 2px 2px " + gray50 }}
        >
          <div className="p-2 text-gray50 text-s">Múltiples coincidencias</div>
          {matches.map((match) => (
            <div
              key={match.idNumber}
              className="d-flex p-2 flex-gap-2 cursor-pointer bg-gray80-hover"
              onClick={() => setMatch(match)}
            >
              <span>{match.idNumber}</span>
              <span>{match.entityName} </span>
              {match.gender ? (
                <span>{getGenderLabel(match.gender as Gender)}</span>
              ) : null}
            </div>
          ))}
        </div>
      ) : null}
    </div>
  );
};
