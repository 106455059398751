/* DON'T EDIT THIS FILE: edit original and run build again */ import { useRealmFromUrl } from "../app-state/app-state-context.ts";
import { UserProfile } from "../auth-profile/schema.ts";
import { logoutPath } from "../auth-ui/logout-path.ts";
import { useAccountAndLoadIt } from "../auth-ui/use-account-and-load-it.front.ts";
import { AccountContext } from "../auth-ui/use-account.ts";
import { frozenEmptyObject } from "../core/frozen-empty-object.ts";
import { getRealmUrlFromProfileTenant } from "../realm/realm-url.ts";
import { getUserRealm } from "../realm/user-realm.ts";
import { Loading } from "../theme/loading.tsx";
import { notifyUserToTracker } from "../track/track.ts";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { RealmAccountMismatchErrorPage } from "./error-pages.front.tsx";

export const AccountWrapper = ({ children }: { children: React.ReactNode }) => {
  const [
    loadingAccount,
    account,
    profile,
    roles,
    privateProfile,
    firstTimeModals,
  ] = useAccountAndLoadIt();
  const { pathname } = useLocation();
  const realmFromUrl = useRealmFromUrl();
  useEffect(() => {
    notifyUserToTracker(account, profile, roles);
  }, [account, profile, roles]);

  if (loadingAccount) {
    return <Loading />;
  }
  // eslint-disable-next-line no-restricted-properties
  if (profile?.tenant && pathname !== logoutPath) {
    const nonEmptyProfile = profile as UserProfile;
    // logout page is not in tenant, thus we can reference directly
    const userRealm = getUserRealm(nonEmptyProfile, roles ?? frozenEmptyObject);
    if (userRealm !== realmFromUrl) {
      const profileExpectedUrl = getRealmUrlFromProfileTenant(
        // eslint-disable-next-line no-restricted-properties
        nonEmptyProfile.tenant,
        roles
      );
      return (
        <RealmAccountMismatchErrorPage
          profile={nonEmptyProfile}
          userRealm={userRealm}
          realmFromUrl={realmFromUrl}
          profileExpectedUrl={profileExpectedUrl}
        />
      );
    }
  }
  return (
    <AccountContext.Provider
      value={{ account, profile, privateProfile, roles, firstTimeModals }}
    >
      {children}
    </AccountContext.Provider>
  );
};
