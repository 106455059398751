/* DON'T EDIT THIS FILE: edit original and run build again */ import { FormControl } from "react-bootstrap";
import { PatternFormat } from "react-number-format";

export const CuitFormControl = ({ setValue, ...props }) => (
  <FormControl
    {...props}
    onValueChange={(values) => setValue(values.formattedValue)}
    as={PatternFormat}
    format={"##-########-#"}
  />
);
