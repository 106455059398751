/* DON'T EDIT THIS FILE: edit original and run build again */ import { runFirestoreTransaction } from "../firebase/firestore-run-transaction.ts";
import { FirestoreTransaction } from "../firebase/firestore-wrappers-transaction.ts";
import {
  FirestoreDocumentSnapshot,
  firestoreIncrement,
  FirestoreWriteResult,
} from "../firebase/firestore-wrappers-types.ts";
import {
  createFirestoreCollection,
  FirestoreCollection,
  FirestoreDocument,
} from "../firebase/firestore-wrappers.ts";
import { chatSubscriptionColName } from "./chat-subscription-col-name.ts";
import { ChatSubscription } from "./schema.ts";

export const getChatSubscriptionCol =
  (): FirestoreCollection<ChatSubscription> =>
    createFirestoreCollection<ChatSubscription>(chatSubscriptionColName);

export const findChatSubscriptions = ({
  lineSharingId,
  uid,
  unread,
}: {
  lineSharingId: string | null;
  uid: string;
  unread: boolean;
}): FirestoreCollection<ChatSubscription> => {
  let query = getChatSubscriptionCol();
  if (lineSharingId) {
    query = query.where("lineSharingId", "==", lineSharingId);
  }
  if (uid) {
    query = query.where("uid", "==", uid);
  }
  if (unread) {
    query = query.where("hasUnread", "==", true);
  }
  return query;
};

export const getChatSubscriptionId = ({
  lineSharingId,
  uid,
}: {
  lineSharingId: string;
  uid: string;
}): string => lineSharingId + "-" + uid;

export const getChatSubscriptionDoc = (
  chatSubscriptionId: string
): FirestoreDocument<ChatSubscription> =>
  getChatSubscriptionCol().doc(chatSubscriptionId);

export const upsertChatSubscription = async ({
  lineSharingId,
  uid,
  time,
  increaseUnread = true,
}: {
  lineSharingId: string;
  uid: string;
  time: Date;
  increaseUnread: boolean;
}): Promise<void> => {
  await runFirestoreTransaction(async (transaction: FirestoreTransaction) => {
    const doc = getChatSubscriptionDoc(
      getChatSubscriptionId({ lineSharingId, uid })
    );
    return transaction
      .get(doc)
      .then((snap: FirestoreDocumentSnapshot<ChatSubscription>) => {
        if (snap.exists) {
          transaction.update(doc, {
            unreadCount: firestoreIncrement(increaseUnread ? 1 : 0),
            hasUnread: increaseUnread || !!snap.data()?.hasUnread,
          });
        } else {
          transaction.set(doc, {
            lineSharingId,
            uid,
            firstUnreadTime: time.toISOString(),
            unreadCount: increaseUnread ? 1 : 0,
            hasUnread: increaseUnread,
          });
        }
      });
  });
};

export const cleanUnread = ({
  uid,
  lineSharingId,
  time,
}: {
  uid: string;
  lineSharingId: string;
  time: Date;
}): Promise<FirestoreWriteResult> =>
  getChatSubscriptionDoc(getChatSubscriptionId({ uid, lineSharingId })).update({
    unreadCount: 0,
    hasUnread: false,
    lastReadTime: time.toISOString(),
  });
