/* DON'T EDIT THIS FILE: edit original and run build again */ import { notNull } from "../../core/not-null.ts";
import {
  LiveFieldInput,
  LiveFieldRenderer,
  LiveFieldViewer,
} from "../../live-form/ui/types.ts";
import {
  LocationInput,
  useLocationState,
} from "../../ui/form/location-input.tsx";
import { LocationViewer } from "../../ui/form/location-viewer.tsx";
import { useCallback } from "react";
import { LocationLiveField } from "./live-field.ts";
import { locationLiveFieldType } from "./type.ts";

const Input: LiveFieldInput = ({ liveCell, saveField }) => {
  const liveField = liveCell.getLiveField() as LocationLiveField;
  const field = notNull(liveField.getField());
  const onChange = useCallback(
    (value: any) => saveField(field, value),
    [field, saveField]
  );
  const {
    detailed = false,
    country = {},
    otherProvince = {},
    argentineProvince = {},
    locality = {},
    zipCode = {},
    streetLine = {},
  } = liveField.getLocationOptions();
  const locationState = useLocationState({
    detailed,
    defaultValue: (liveCell.getValue() ?? {}) as any,
    onChange,
  });
  return (
    <LocationInput
      className="ms-4 mt-3"
      autoFocus={liveField.getAutoFocus()}
      locationState={locationState}
      options={{
        country,
        otherProvince,
        argentineProvince,
        locality,
        zipCode,
        streetLine,
      }}
      required={liveCell.isRequired()}
    />
  );
};

const Viewer: LiveFieldViewer = ({ liveCell }) => (
  <LocationViewer value={liveCell.getValue() ?? {}} />
);

export const locationLiveFieldRenderers: LiveFieldRenderer = {
  type: locationLiveFieldType,
  Input,
  Viewer,
};
