/* DON'T EDIT THIS FILE: edit original and run build again */ import { FormSection } from "../theme/form-body.tsx";
import { CheckIcon } from "../theme/icon.ts";
import { LoadingButton } from "../theme/loading-button.tsx";
import { RemoveButton } from "../theme/remove-button.tsx";
import { Button } from "react-bootstrap";

export const AddDataPopup = ({
  note = null,
  title,
  onCloseClick,
  onRemoveClick = null,
  onSaveClick,
  children,
  saveDisabled = false,
  className = "",
  buttonLabel = "Guardar",
  showCancel = false,
}: {
  note?: string | null;
  title: React.ReactNode;
  onCloseClick: () => void;
  onRemoveClick?: (() => void) | null;
  onSaveClick: () => Promise<void>;
  children: React.ReactNode;
  saveDisabled?: boolean;
  className?: string;
  buttonLabel?: string;
  showCancel?: boolean;
}) => {
  return (
    <>
      <div className={"px-5 py-4 border-bottom-light-2 mb-3 " + className}>
        <div className="d-flex flex-row align-items-center justify-content-between">
          <div className="text-l-compact fw-bold">{title}</div>
          {onRemoveClick ? (
            <RemoveButton onClick={onRemoveClick} className="me-2" />
          ) : null}
        </div>
        {note ? <div className="pt-1">{note}</div> : null}
      </div>
      <FormSection className="pb-5">
        {children}
        <div className="mt-4 d-flex justify-content-end">
          {showCancel ? (
            <Button className="me-2" variant="link" onClick={onCloseClick}>
              Cancelar
            </Button>
          ) : null}
          <LoadingButton
            disabled={saveDisabled}
            variant="outline-blue50"
            onClick={onSaveClick}
          >
            <CheckIcon className="me-1" />
            {buttonLabel}
          </LoadingButton>
        </div>
      </FormSection>
    </>
  );
};
