/* DON'T EDIT THIS FILE: edit original and run build again */ import {
  canAccessReceivedLines,
  canAccessReceivedLinesInNonFciTenants,
} from "../permission/permissions.ts";
import { RolesMap } from "../permission/schema.ts";
import { userHasPermission } from "../permission/user-has-permission.ts";
import { TenantHandler } from "../tenant-handler-type/tenant-handler.ts";
import { investorUserCompanyTypeId } from "../user-company-type-const/user-company-type-const.ts";

const canAccessInboxCondition = (
  roles: RolesMap,
  tenantHandler: TenantHandler
) =>
  userHasPermission(roles, canAccessReceivedLines) ||
  (userHasPermission(roles, canAccessReceivedLinesInNonFciTenants) &&
    tenantHandler.getUserCompanyType() !== investorUserCompanyTypeId);

export default canAccessInboxCondition;
