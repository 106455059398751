/* DON'T EDIT THIS FILE: edit original and run build again */ import { SgrTenantHandler } from "../../tenant-handlers/sgr-tenant-handler.ts";
import { rigsgrTenant } from "./code.ts";
import { rigsgrFullLabel } from "./full-label.ts";
import { rigsgrLabel } from "./label.ts";

class RigsgrTenantHandler extends SgrTenantHandler {
  getCode(): string {
    return rigsgrTenant;
  }

  getPathLabel(): string | null {
    return "rigsgr";
  }

  getLabel(): string {
    return rigsgrLabel;
  }

  getFullLabel(): string | null {
    return rigsgrFullLabel;
  }

  getOnboardingOptions() {
    return null;
  }

  getNotificationsFrom(): string {
    return "no-responder@matchfin.ar";
  }

  getCuit(): string | null {
    return "30-71632114-9";
  }

  publishesInMarketplace(): boolean {
    return true;
  }
}

const rigsgrTenantHandler = new RigsgrTenantHandler();

export default rigsgrTenantHandler;
