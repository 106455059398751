/* DON'T EDIT THIS FILE: edit original and run build again */ import { LiveTextViewer } from "../../live-form/ui/text-viewer.tsx";
import {
  LiveFieldInputParam,
  LiveFieldRenderer,
} from "../../live-form/ui/types.ts";
import { useLiveAfterBlurTextInput } from "../../live-form/ui/use-live-after-blur-text-input.tsx";
import { InputElement } from "../../ui/form/input-element.tsx";
import { phoneLiveFieldType } from "./type.ts";

const PhoneInput = (props: {
  value: string;
  setValue: (value: string) => void;
  disabled?: boolean;
  autoFocus?: boolean;
  className?: string;
  autoComplete?: string;
  onKeyPress?: (event: React.KeyboardEvent) => void;
}) => <InputElement type="tel" {...props} />;

const LivePhoneInput = (fieldInfo: LiveFieldInputParam) => (
  <PhoneInput {...useLiveAfterBlurTextInput(fieldInfo)} />
);

export const phoneLiveFieldRenderers: LiveFieldRenderer = {
  type: phoneLiveFieldType,
  Input: LivePhoneInput,
  Viewer: LiveTextViewer,
};
