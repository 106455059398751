/* DON'T EDIT THIS FILE: edit original and run build again */ import { LineOptions } from "../../tenant-handler-type/line-options.ts";
import { ValueChainTenantHandler } from "../../tenant-handlers/value-chain-tenant-handler.ts";
import { syngentaTenant } from "./code.ts";
import { syngentaFullLabel } from "./full-label.ts";
import { syngentaLineOptions } from "./syngenta-line-options.ts";

class SyngentaTenantHandler extends ValueChainTenantHandler {
  getCode(): string {
    return syngentaTenant;
  }

  getPathLabel(): string | null {
    return "syngenta";
  }

  getLineOptions(): LineOptions {
    return syngentaLineOptions;
  }

  getLabel(): string {
    return "Syngenta";
  }

  getFullLabel(): string {
    return syngentaFullLabel;
  }

  getNotificationsFrom(): string {
    return "no-responder@matchfin.ar";
  }

  getCuit(): string | null {
    return "30-64632845-0";
  }

  publishesInMarketplace(): boolean {
    return true;
  }

  doesProducersSignUpViaMatchfinRealm(): boolean {
    return true;
  }
}

const syngentaTenantHandler = new SyngentaTenantHandler();
export default syngentaTenantHandler;
