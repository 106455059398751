/* DON'T EDIT THIS FILE: edit original and run build again */ import { mapArrayToObject } from "../core/map-array-to-object.ts";
import { notNull } from "../core/not-null.ts";
import { WithId } from "../core/with-id.ts";
import { batchFetchCol } from "../firebase/fetch-col.ts";
import { fetchDoc } from "../firebase/fetch-doc.ts";
import {
  firestoreDocumentId,
  FirestoreWriteResult,
} from "../firebase/firestore-wrappers-types.ts";
import {
  createFirestoreCollection,
  FirestoreCollection,
  FirestoreDocument,
} from "../firebase/firestore-wrappers.ts";
import { Roles, RolesMap, UserRoles } from "../permission/schema.ts";
import { userRolesColName } from "./col-name.ts";

export const getUserRolesCol = (): FirestoreCollection<UserRoles> =>
  createFirestoreCollection<UserRoles>(userRolesColName);

export const getUserRolesDoc = (uid: string): FirestoreDocument<UserRoles> =>
  getUserRolesCol().doc(uid);

export const fetchUserRoles = async (
  uid: string
): Promise<UserRoles | undefined> => fetchDoc(getUserRolesDoc(uid));

export const fetchUserRolesByUids = async (
  uids: string[]
): Promise<WithId<UserRoles, "uid">[]> =>
  batchFetchCol(getUserRolesCol(), firestoreDocumentId(), "in", uids, "uid");

export const fetchUserRolesByTenant = async (
  tenant: string,
  roles: Roles[],
  limit: number | null = null
): Promise<WithId<UserRoles, "uid">[]> => {
  let col = getUserRolesCol()
    .where("disabled", "==", false)
    .where("tenant", "==", tenant);
  if (limit !== null) {
    col = col.limit(limit);
  }
  return batchFetchCol(
    col,
    "roles",
    "array-contains-any",
    roles as Roles[],
    "uid"
  );
};

export const fetchUserRolesForAllTenants = async (
  roles: Roles[]
): Promise<WithId<UserRoles, "uid">[]> => {
  const col = getUserRolesCol().where("disabled", "==", false);
  return batchFetchCol(
    col,
    "roles",
    "array-contains-any",
    roles as Roles[],
    "uid"
  );
};

export const getUserRolesMap = (userRoles: UserRoles): RolesMap =>
  mapArrayToObject(userRoles?.roles ?? [], (role) => [role, true]);

export const fetchUserRolesMap = async (uid: string): Promise<RolesMap> =>
  fetchUserRoles(uid).then((userRolesOrNull) =>
    getUserRolesMap(notNull(userRolesOrNull))
  );

export const maybeFetchUserRolesMap = async (
  uid: string
): Promise<RolesMap | undefined> =>
  fetchUserRoles(uid).then((userRolesOrNull) =>
    userRolesOrNull ? getUserRolesMap(userRolesOrNull) : undefined
  );

export const setUserRoles = (
  uid: string,
  roles: Roles[],
  tenant: string,
  disabled: boolean = false
): Promise<FirestoreWriteResult> =>
  getUserRolesDoc(uid).set({
    roles,
    tenant,
    disabled,
  });

export const replaceUserRoles = (
  uid: string,
  roles: Roles[]
): Promise<FirestoreWriteResult> => getUserRolesDoc(uid).update({ roles });
