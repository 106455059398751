/* DON'T EDIT THIS FILE: edit original and run build again */ import { notNull } from "../core/not-null.ts";
import { HtmlEditor, HtmlViewer } from "../html-editor/html-editor.tsx";
import {
  LiveFieldInput,
  LiveFieldRenderer,
  LiveFieldViewer,
} from "../live-form/ui/types.ts";
import { RichTextValue } from "../rich-text/rich-text-type.ts";
import { htmlLiveFieldType } from "./type.ts";

const Viewer: LiveFieldViewer = ({ liveCell }) => {
  const value = liveCell.getValue();
  if (value) {
    return <HtmlViewer value={value} />;
  } else {
    return null;
  }
};

const Input: LiveFieldInput = ({ liveCell, saveField }) => {
  const value = liveCell.getValue() as RichTextValue | null;
  const error = liveCell.getError();
  const liveField = liveCell.getLiveField();
  const field = notNull(liveField.getField());
  return (
    <HtmlEditor
      autoFocus={liveField.getAutoFocus()}
      onChange={(value: RichTextValue, { force }) => {
        if (force) {
          saveField(field, value);
        }
      }}
      editorClassName={error ? "is-invalid" : ""}
      initialValue={value ?? undefined}
      delayed={true}
      userId={notNull(liveCell.getLiveData().specificFolder).ownerUid as string}
    />
  );
};

export const htmlLiveFieldRenderers: LiveFieldRenderer = {
  type: htmlLiveFieldType,
  Input: Input,
  Viewer,
};
