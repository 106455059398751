/* DON'T EDIT THIS FILE: edit original and run build again */ import { LiveTextViewer } from "../../live-form/ui/text-viewer.tsx";
import {
  LiveFieldInputParam,
  LiveFieldRenderer,
} from "../../live-form/ui/types.ts";
import { useLiveAfterBlurTextInput } from "../../live-form/ui/use-live-after-blur-text-input.tsx";
import { PasswordInput } from "./password-input.tsx";
import { passwordLiveFieldType } from "./type.ts";

const DelayedInput = (fieldInfo: LiveFieldInputParam) => {
  return <PasswordInput {...useLiveAfterBlurTextInput(fieldInfo)} />;
};

export const passwordLiveFieldRenderers: LiveFieldRenderer = {
  type: passwordLiveFieldType,
  Input: DelayedInput,
  Viewer: LiveTextViewer,
};
