/* DON'T EDIT THIS FILE: edit original and run build again */ import { OnboardingOptions } from "../../tenant-handler-type/onboarding-options.ts";
import { AlycTenantHandler } from "../../tenant-handlers/alyc-tenant-handler.ts";
import { BaseLineOptions } from "../../tenant-handlers/base-line-options.ts";
import { ComponentType, SVGProps } from "react";
import { zofingenTenant } from "./code.ts";
import { zofingenFullLabel } from "./full-label.ts";
import { zofingenLabel } from "./label.ts";
import { zofingenLineOptions } from "./zofingen-line-options.ts";
import { zofingenOnboardingOptions } from "./zofingen-onboarding-options.ts";

class ZofingenTenantHandler extends AlycTenantHandler {
  async getAuthPageLogo(): Promise<ComponentType<SVGProps<SVGElement>>> {
    // @ts-expect-error the hook is auto generated
    return (await import("./logo_zofingen.svg?react")).default;
  }

  getCode(): string {
    return zofingenTenant;
  }

  getPathLabel(): string | null {
    return "zofingen";
  }

  getLabel(): string {
    return zofingenLabel;
  }

  getFullLabel(): string {
    return zofingenFullLabel;
  }

  getLineOptions(): BaseLineOptions | null {
    return zofingenLineOptions;
  }

  getOnboardingOptions(): OnboardingOptions | null {
    return zofingenOnboardingOptions;
  }

  getNotificationsFrom(): string {
    return "no-responder@matchfin.ar";
  }

  getCuit(): string {
    return "30-70750503-2";
  }

  getCnvRegistryId(): string | null {
    return "196";
  }

  publishesInMarketplace(): boolean {
    return true;
  }
}

const zofingenTenantHandler = new ZofingenTenantHandler();

export default zofingenTenantHandler;
