/* DON'T EDIT THIS FILE: edit original and run build again */ import { LiveCell } from "../../live-form/cell.ts";
import { LiveRecord } from "../../live-form/live-record.ts";
import { Labeled } from "../../ui/form/labeled.tsx";
import React from "react";
import { AfterLabelRenderer } from "./after-label-renderer.tsx";
import { LiveFieldGetLiveFormInputFactory, LiveFormWidgets } from "./types.ts";

const createLiveFormViewer = (
  liveCell: LiveCell,
  getLiveFormInputFactory: LiveFieldGetLiveFormInputFactory,
  Widgets: LiveFormWidgets,
  showLabels: boolean
) => {
  const type = liveCell.getLiveField().getType();
  const inputFactory = getLiveFormInputFactory(type);
  if (!inputFactory) {
    throw new Error("cannotFindInputFactorFor: " + type);
  }
  if (!inputFactory.Viewer) {
    throw new Error("cannotFindInputViewerFor: " + type);
  }
  const Viewer = inputFactory.Viewer;
  return (
    <Viewer
      {...{
        liveCell,
        getLiveFormInputFactory,
        Widgets,
        showLabels,
      }}
    />
  );
};

export const createLiveFormFieldViewer = (
  liveCell: LiveCell,
  Widgets: LiveFormWidgets,
  getLiveFormInputFactory: LiveFieldGetLiveFormInputFactory,
  showLabels = true
) => {
  const viewer = createLiveFormViewer(
    liveCell,
    getLiveFormInputFactory,
    Widgets,
    showLabels
  );
  const liveField = liveCell.getLiveField();
  const AfterLabel = liveField.getAfterLabel();
  const label = liveField.getLabel();
  const key = liveField.getKey();
  return (
    <React.Fragment key={key}>
      {showLabels && label ? (
        <Labeled
          dontUseLabelTag={true}
          label={label}
          afterLabel={
            <AfterLabelRenderer
              AfterLabel={AfterLabel}
              Widgets={Widgets}
              liveData={liveCell.getLiveData()}
            />
          }
          input={viewer}
        />
      ) : (
        viewer
      )}
    </React.Fragment>
  );
};

const createFieldViewers = (
  liveRecord: LiveRecord,
  Widgets: LiveFormWidgets,
  getLiveFormInputFactory: LiveFieldGetLiveFormInputFactory,
  showLabels: boolean
) =>
  liveRecord
    .createCells()
    .map((liveCell) =>
      createLiveFormFieldViewer(
        liveCell,
        Widgets,
        getLiveFormInputFactory,
        showLabels
      )
    );

// warning: the live record should be created with viewOnly = true in the live data
export const RawLiveFormViewer = ({
  liveRecord,
  Widgets = {},
  getLiveFormInputFactory,
  showLabels = true,
}: {
  liveRecord: LiveRecord;
  Widgets?: LiveFormWidgets;
  getLiveFormInputFactory: LiveFieldGetLiveFormInputFactory;
  showLabels?: boolean;
}) =>
  createFieldViewers(liveRecord, Widgets, getLiveFormInputFactory, showLabels);
