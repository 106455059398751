/* DON'T EDIT THIS FILE: edit original and run build again */ import { InlineEntity } from "../entity/inline-entity-type.ts";
import { isCompanyCuit } from "../gov-id-number/cuit.ts";
import { gray40 } from "../theme/custom-colors.ts";
import { ApartmentIcon, UserIcon } from "../theme/icon.ts";

export const InlineEntityTypeIcon = ({
  inlineEntity,
  size = 16,
  fill = gray40,
  className,
}: {
  inlineEntity: Partial<InlineEntity>;
  size?: number;
  fill?: string;
  className?: string;
}) => {
  const { isCompany, cuit, foreignIdNumber } = inlineEntity;
  if (!cuit && !foreignIdNumber) {
    return null;
  }
  if (cuit ? isCompanyCuit(cuit) : isCompany) {
    return (
      <ApartmentIcon
        className={className}
        style={{ fill }}
        width={size}
        height={size}
      />
    );
  } else {
    return (
      <UserIcon
        className={className}
        style={{ fill }}
        width={size}
        height={size}
      />
    );
  }
};
