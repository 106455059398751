/* DON'T EDIT THIS FILE: edit original and run build again */ import { mapArrayToObject } from "../core/map-array-to-object.ts";
import {
  guestBankRole,
  guestSgrRole,
  investorRole,
} from "../permission/all-roles.ts";
import {
  alycUserCompanyTypeId,
  bankUserCompanyTypeId,
  chamberUserCompanyTypeId,
  investorUserCompanyTypeId,
  sgrUserCompanyTypeId,
  valueChainUserCompanyTypeId,
} from "../user-company-type-const/user-company-type-const.ts";
import {
  alycSignUpPath,
  bankSignUpPath,
  chamberSignUpPath,
  investorSignUpPath,
  sgrSignUpPath,
  valueChainSignUpPath,
} from "./sign-up-paths.ts";

const investorUserCompanyType = {
  id: investorUserCompanyTypeId,
  label: "Inversor",
  role: investorRole,
  signUpPath: investorSignUpPath,
};

const sgrUserCompanyType = {
  id: sgrUserCompanyTypeId,
  label: "SGR",
  role: guestSgrRole,
  signUpPath: sgrSignUpPath,
};

const bankUserCompanyType = {
  id: bankUserCompanyTypeId,
  label: "Banco",
  role: guestBankRole,
  signUpPath: bankSignUpPath,
};

const alycUserCompanyType = {
  id: alycUserCompanyTypeId,
  label: "ALyC",
  role: null,
  signUpPath: alycSignUpPath,
};

const valueChainUserCompanyType = {
  id: valueChainUserCompanyTypeId,
  label: "Cadena de valor",
  role: null,
  signUpPath: valueChainSignUpPath,
};

const chamberUserCompanyType = {
  id: chamberUserCompanyTypeId,
  label: "Cámara",
  role: null,
  signUpPath: chamberSignUpPath,
};

export const userCompanyTypes = [
  investorUserCompanyType,
  sgrUserCompanyType,
  bankUserCompanyType,
  alycUserCompanyType,
  valueChainUserCompanyType,
  chamberUserCompanyType,
];

const userCompanyTypesById = mapArrayToObject(
  userCompanyTypes,
  (userCompanyType) => [userCompanyType.id, userCompanyType]
);

const userCompanyTypesByRole = mapArrayToObject(
  userCompanyTypes.filter((userCompanyType) => userCompanyType.role !== null),
  (userCompanyType) => [userCompanyType.role as string, userCompanyType]
);

export const userCompanyTypeIds = Object.keys(userCompanyTypesById);

const defaultCompanyTypeLabel = "Otro";

export const getUserCompanyTypeLabel = (
  userCompanyTypeId: string | undefined
) =>
  userCompanyTypeId
    ? userCompanyTypesById[userCompanyTypeId]?.label ?? defaultCompanyTypeLabel
    : defaultCompanyTypeLabel;

export const getUserCompanyTypeIdByRole = (role: string) =>
  userCompanyTypesByRole[role]?.id ?? null;

export const getUserCompanyTypeSignUpPath = (userCompanyTypeId: string) =>
  userCompanyTypesById[userCompanyTypeId]?.signUpPath ?? null;
