/* DON'T EDIT THIS FILE: edit original and run build again */ import { Span } from "../pdf-wrapper/pdf-wrapper.tsx";
import { AddCircleOutlineIcon } from "../theme/icon.ts";
import { useState } from "react";
import { Button, ButtonProps, Modal, ModalProps } from "react-bootstrap";

export type ListSectionState = {
  showPopup: boolean;
  dismissPopup: () => void;
  showCreateNew: () => void;
  showEditItem: (id: string) => void;
  idToEdit?: string | null;
};

export const useListSectionState = (): ListSectionState => {
  const [showPopup, setShowPopup] = useState(false);
  const [idToEdit, setIdToEdit] = useState(null as null | string);
  return {
    showPopup,
    dismissPopup: () => {
      setShowPopup(false);
    },
    showCreateNew: () => {
      setIdToEdit(null);
      setShowPopup(true);
    },
    showEditItem: (id: string) => {
      setIdToEdit(id);
      setShowPopup(true);
    },
    idToEdit,
  };
};

export const AddListItemButton = ({
  label,
  popupContent,
  popupSize,
  buttonSize,
  listSectionState,
}: {
  label: React.ReactNode;
  popupContent: React.ReactNode;
  popupSize?: ModalProps["size"];
  buttonSize?: ButtonProps["size"];
  listSectionState: ListSectionState;
}) => {
  return (
    <>
      <Modal
        size={popupSize}
        animation={false}
        centered
        show={listSectionState.showPopup}
        onHide={() => listSectionState.dismissPopup()}
      >
        <Modal.Body className="p-0">{popupContent}</Modal.Body>
      </Modal>
      <Button
        variant="outline-blue50"
        size={buttonSize}
        className="text-nowrap rounded mt-2"
        onClick={() => {
          listSectionState.showCreateNew();
        }}
      >
        <AddCircleOutlineIcon className="me-1 icon-2" />
        {label}
      </Button>
    </>
  );
};

export const ListSection = ({
  addItemLabel,
  items,
  popupContent,
  popupSize,
  buttonSize,
  listSectionState,
}: {
  addItemLabel: React.ReactNode;
  items: React.ReactNode[];
  popupContent: React.ReactNode;
  popupSize?: ModalProps["size"];
  buttonSize?: ButtonProps["size"];
  listSectionState: ListSectionState;
}) => {
  return (
    <>
      {items}
      {addItemLabel ? (
        <AddListItemButton
          label={addItemLabel}
          popupContent={popupContent}
          popupSize={popupSize}
          buttonSize={buttonSize}
          listSectionState={listSectionState}
        />
      ) : items.length === 0 ? (
        <Span className="fst-italic">Vacío</Span>
      ) : null}
    </>
  );
};
