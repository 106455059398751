/* DON'T EDIT THIS FILE: edit original and run build again */ import { EditorButtonGroup } from "../../html-editor/button-group/editor-button-group.tsx";
import { BoldButton } from "../../html-editor/button/bold-button.tsx";
import { ItalicButton } from "../../html-editor/button/italic-button.tsx";
import { UnderlineButton } from "../../html-editor/button/underline-button.tsx";

export const BasicFormattingButtonGroup = () => (
  <EditorButtonGroup name="basic-formatting">
    <BoldButton />
    <ItalicButton />
    <UnderlineButton />
  </EditorButtonGroup>
);
