/* DON'T EDIT THIS FILE: edit original and run build again */ import { gray40 } from "../theme/custom-colors.ts";

export const getMenuOptionStyle = (
  hover: boolean,
  selected: boolean,
  showHover: boolean
): { backgroundColor: string | undefined; borderRadius: string } => {
  return {
    backgroundColor: (hover || selected) && showHover ? gray40 : undefined,
    borderRadius: "10px",
  };
};
