/* DON'T EDIT THIS FILE: edit original and run build again */ import { canEditSgrReport } from "../permission/permissions.ts";
import { userHasPermission } from "../permission/user-has-permission.ts";
import { SidebarMenuItem } from "../sidebar-type/sidebar-types.ts";
import { ReportIcon } from "../theme/icon.ts";
import { sgrsReportsPath } from "./get-sgrs-report-url.ts";

export const sgrsReportsTabId = "sgrsReports";

const sgrsReportsMenuItem: SidebarMenuItem = {
  id: sgrsReportsTabId,
  getPath: () => sgrsReportsPath,
  label: "Informes SGRs",
  condition: ({ tenantHandler, roles }) =>
    userHasPermission(roles, canEditSgrReport) &&
    !!tenantHandler.getLineOptions() &&
    tenantHandler.hasSgrsReportsSection(),
  order: 9,
  hasPriority: true,
  icon: (className) => <ReportIcon className={className} />,
};

export default sgrsReportsMenuItem;
