/* DON'T EDIT THIS FILE: edit original and run build again */ import {
  endClientUserRole,
  producerRole,
  tenantRealmPendingApprovalRole,
} from "../permission/all-roles.ts";
import { RolesMap } from "../permission/schema.ts";
import { getTenantHandler } from "../tenant-collection/tenant.ts";
import { matchfinTenant } from "../tenant-matchfin-code/matchfin-tenant-code.ts";

export const alwaysTenantUrlRoles = [
  endClientUserRole,
  tenantRealmPendingApprovalRole,
];

export const getInternalRealmRolesForTenant = (tenant: string) => {
  const out = alwaysTenantUrlRoles;
  if (!getTenantHandler(tenant).doesProducersSignUpViaMatchfinRealm()) {
    out.push(producerRole);
  }
  return out;
};

export const isTenantRealmUserRole = (tenant: string, role: string) =>
  getInternalRealmRolesForTenant(tenant).includes(role);

export const getUserRealm = (
  profile: { tenant: string },
  roles: RolesMap
): string => {
  // we 'hackishly' take profile as a parameter instead of tenant to avoid allowing multiple exceptions
  // to the profile.tenant eslint rule.
  // Note its ok to use profile.tenant, given no jumping user changes realm when changing tenant.
  // eslint-disable-next-line no-restricted-properties
  const tenant = profile.tenant;
  const activeRoles = Object.entries(roles)
    .filter(([, active]) => active)
    .map(([role]) => role);
  if (activeRoles.some((role) => !isTenantRealmUserRole(tenant, role))) {
    return matchfinTenant;
  } else {
    return tenant;
  }
};
