/* DON'T EDIT THIS FILE: edit original and run build again */ import { alerter } from "../alert/alert.tsx";
import {
  AuthErrorMessage,
  getAuthErrorMessageProps,
} from "../auth-error-codes/auth-error-message.front.tsx";
import { authErrorWrongPassword } from "../auth-error-codes/firebase-auth-error-codes.ts";
import { useProfile } from "../auth-ui/use-account.ts";
import { PasswordLiveField } from "../live-form-core-types/password/live-field.ts";
import { PasswordSetterLiveField } from "../live-form-password-setter-type/live-field.ts";
import { LiveForm } from "../live-form-simple/live-form.tsx";
import { LiveFieldset } from "../live-form/fieldset.ts";
import { LiveRecord } from "../live-form/live-record.ts";
import { useLiveFormState } from "../live-form/ui/use-live-form-stte.ts";
import { FormSection } from "../theme/form-body.tsx";
import { LoadingButton } from "../theme/loading-button.tsx";
import { changePassword } from "./change-password.ts";

const changePasswordStructure = (scorers: any, disallowed: any) => [
  new PasswordLiveField({
    label: "Contraseña actual",
    field: "currentPassword",
    required: true,
    autoFocus: true,
    autoComplete: "new-password",
  }),
  new PasswordSetterLiveField({
    label: "Nueva contraseña",
    field: "newPassword",
    required: true,
    autoFocus: true,
    scorers: scorers,
    disallow: {
      values: disallowed,
    },
  }),
];

const save = async (password: string, newPassword: string) =>
  await changePassword({
    currentPassword: password,
    newPassword: newPassword,
  });

const DefaultSaveButton = ({
  onClick,
}: {
  onClick: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => Promise<void>;
}) => (
  <LoadingButton
    variant="outline-blue50"
    className="text-nowrap"
    onClick={onClick}
  >
    <div className="d-flex align-items-center">Cambiar contraseña</div>
  </LoadingButton>
);

export const ChangePasswordForm = ({
  scorers,
  SaveButton = DefaultSaveButton,
  onSave,
}: {
  scorers: any;
  SaveButton?: React.ComponentType<{
    onClick: (
      event: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => Promise<void>;
  }>;
  onSave: () => Promise<void>;
}) => {
  const profile = useProfile();
  const disallowed = [
    profile.companyName,
    profile.email,
    ...profile.email.split("@"),
    profile.firstName,
    profile.lastName,
    profile.phone,
    // eslint-disable-next-line no-restricted-properties
    profile.tenant,
  ];
  const fieldset = new LiveFieldset(
    changePasswordStructure(scorers, disallowed)
  );
  const { recordValue, saveField, showErrors, setShowErrors, getError } =
    useLiveFormState({
      fieldset,
      defaultRecordValue: () => fieldset.createDefaultRecord(),
      defaultShowErrors: false,
    });

  const validateAndSave = async () => {
    {
      const error = getError();
      if (error) {
        setShowErrors(true);
        await alerter.alert(error);
        return;
      }
    }
    try {
      await save(recordValue.currentPassword, recordValue.newPassword);
    } catch (e) {
      const props = getAuthErrorMessageProps({
        error: e,
        customErrorMap: {
          [authErrorWrongPassword]: {
            message: "La contraseña actual es incorrecta.",
            showCode: false,
          },
        },
      });
      await alerter.alert(<AuthErrorMessage {...props} />);
    }
    await onSave();
  };

  return (
    <FormSection>
      <LiveForm
        liveRecord={new LiveRecord(fieldset, { recordValue }, showErrors)}
        saveField={saveField}
      />
      <div className="mt-4 d-flex justify-content-end">
        <SaveButton onClick={validateAndSave} />
      </div>
    </FormSection>
  );
};
