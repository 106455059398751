/* DON'T EDIT THIS FILE: edit original and run build again */ import { SgrTenantHandler } from "../../tenant-handlers/sgr-tenant-handler.ts";
import { garantizarTenant } from "./code.ts";
import { garantizarFullLabel } from "./full-label.ts";
import { garantizarLabel } from "./label.ts";

class GarantizarTenantHandler extends SgrTenantHandler {
  getCode(): string {
    return garantizarTenant;
  }

  getPathLabel(): string | null {
    return "garantizar";
  }

  getLabel(): string {
    return garantizarLabel;
  }

  getFullLabel(): string {
    return garantizarFullLabel;
  }

  getNotificationsFrom(): string {
    return "no-responder@matchfin.ar";
  }

  getCuit(): string | null {
    return "30-68833176-1";
  }

  publishesInMarketplace(): boolean {
    return true;
  }

  hasGrantedLineSection(): boolean {
    return true;
  }
}

const garantizarTenantHandler = new GarantizarTenantHandler();
export default garantizarTenantHandler;
