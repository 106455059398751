/* DON'T EDIT THIS FILE: edit original and run build again */ import firebase from "firebase/compat/app";
import { getConfig } from "../config/config.ts";
import { CallSignature } from "./call-signature.ts";

const getFunctionsObject = () => {
  const config = getConfig();
  if (config.functionsRegion) {
    // @ts-expect-error typescript is wrong here
    return firebase.app().functions(config.functionsRegion);
  } else {
    // @ts-expect-error typescript is wrong here
    return firebase.functions();
  }
};

export const callFirebaseFunction = <TCallSignature extends CallSignature<any>>(
  name: TCallSignature["name"],
  params: Parameters<TCallSignature["signature"]>[0] = {}
): ReturnType<TCallSignature["signature"]> =>
  getFunctionsObject()
    .httpsCallable("callFunctionCall", { timeout: 1200000 })({
      name,
      params,
    })
    .then((firebaseResponse: any) => {
      const [error, response] = firebaseResponse.data;
      if (error) {
        throw new Error("errorCallingFirebase: " + name + ", " + response);
      }
      return response;
    })
    .catch((e: Error) => {
      throw new Error(
        "catchedFirebaseError: " + name + ", original error: " + e.message
      );
    });
