/* DON'T EDIT THIS FILE: edit original and run build again */ import { SgrTenantHandler } from "../../tenant-handlers/sgr-tenant-handler.ts";
import { acindarOnboardingOptions } from "./acindar-onboarding-options.ts";
import { acindarTenant } from "./code.ts";
import { acindarFullLabel } from "./full-label.ts";
import { acindarLabel } from "./label.ts";

const AcindarTenantHandler = class extends SgrTenantHandler {
  getOnboardingOptions() {
    return acindarOnboardingOptions;
  }

  getCode(): string {
    return acindarTenant;
  }

  getPathLabel(): string | null {
    return "acindar";
  }

  getLabel(): string {
    return acindarLabel;
  }

  getFullLabel(): string {
    return acindarFullLabel;
  }

  hasOpinionReportSection(): boolean {
    return false;
  }

  getComplianceEmailAddress(): string {
    return "compliance@acindar.com.ar";
  }

  getNotificationsFrom(): string {
    return "no-responder@matchfin.ar";
  }

  getCuit(): string | null {
    return "30-70937729-5";
  }

  publishesInMarketplace(): boolean {
    return true;
  }

  hasGrantedLineSection(): boolean {
    return true;
  }
};

const acindarTenantHandler = new AcindarTenantHandler();

export default acindarTenantHandler;
