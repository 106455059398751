/* DON'T EDIT THIS FILE: edit original and run build again */ import { UserPrivateProfile, UserProfile } from "../auth-profile/schema.ts";
import {
  canChangeToAllowedTenant,
  canChangeToAnyTenant,
} from "../permission/permissions.ts";
import { RolesMap } from "../permission/schema.ts";
import { userHasPermission } from "../permission/user-has-permission.ts";
import { getAllTenantCodes } from "../tenant-collection/tenant.ts";

export const getAllowedTenants = ({
  roles,
  privateProfile,
}: {
  roles: RolesMap;
  privateProfile: UserPrivateProfile;
}) => {
  if (userHasPermission(roles, canChangeToAnyTenant)) {
    return getAllTenantCodes();
  }
  if (
    userHasPermission(roles, canChangeToAllowedTenant) &&
    privateProfile?.allowedTenants
  ) {
    return privateProfile.allowedTenants;
  }
  return [];
};

export const isTenantAllowed = (
  {
    roles,
    profile,
    privateProfile,
  }: {
    roles: RolesMap;
    profile: UserProfile;
    privateProfile: UserPrivateProfile;
  },
  tenant: string
) =>
  // eslint-disable-next-line no-restricted-properties
  profile?.tenant === tenant ||
  getAllowedTenants({ roles, privateProfile }).includes(tenant);

// TEMP. until tenant select is fixed
export const canAccessTenant = (profile?: UserProfile, tenant?: string) =>
  // eslint-disable-next-line no-restricted-properties
  tenant && profile?.tenant === tenant;
