/* DON'T EDIT THIS FILE: edit original and run build again */ import { AttachmentRef } from "../attachment-type/attachment-ref-type.ts";
import { EditorToolbar } from "../html-editor/editor-toolbar.tsx";
import { HtmlEditorContext } from "../html-editor/use-html-editor-context.ts";
import { useRemirrorWrapper } from "../html-editor/use-remirror-wrapper.ts";
import { useSetInputValueForTests } from "../html-editor/use-set-input-value-for-tests.ts";
import { EditorComponent, OnChangeHTML, Remirror } from "@remirror/react";

export const Editor = ({
  onChange,
  htmlEditorState,
  onFocus,
  onBlur,
  containerClassName = "",
  editorClassName = "",
  userId,
  autoFocus,
}: {
  onChange: (value: any, options: any) => void;
  htmlEditorState: any;
  onFocus: (event: any) => void;
  onBlur: (event: any) => void;
  containerClassName: string;
  editorClassName: string;
  userId: string;
  autoFocus: boolean | undefined;
}) => {
  const onEditorChange = (
    html: string,
    newAttachment: AttachmentRef | null = null
  ) => {
    const newValue = {
      ...htmlEditorState.getValue(),
      html,
      // @ts-expect-error I left this on the typescript strictNullChecks migration
      text: getContext().helpers.getText(),
    };
    // Triggered from a image upload
    if (newAttachment) {
      newValue.attachments = [...newValue.attachments, newAttachment];
    }
    htmlEditorState.setValue(newValue);
    onChange(newValue, { forceSetValue: !!newAttachment });
  };

  const { manager, state, getContext } = useRemirrorWrapper({
    htmlEditorState,
    onEditorChange,
    editable: true,
    userId,
  });

  const containerRef = useSetInputValueForTests(manager, (html: string) => {
    const newValue = {
      ...htmlEditorState.getValue(),
      html,
      // @ts-expect-error I left this on the typescript noImplicitAny migration
      text: getContext().helpers.getText(),
    };
    htmlEditorState.setValue(newValue);
    onChange(newValue, { forceSetValue: true });
  });

  return (
    <div
      className={"html-editor-container remirror-theme " + containerClassName}
      data-is-input
      ref={containerRef}
    >
      <HtmlEditorContext.Provider
        value={{
          userId,
          htmlEditorState,
          onEditorChange,
        }}
      >
        <div
          onFocus={(event) => {
            if (onFocus) {
              onFocus(event);
            }
          }}
          onBlur={(event) => {
            if (onBlur) {
              onBlur(event);
            }
          }}
          onClick={() => {
            // @ts-expect-error I left this on the typescript strictNullChecks migration
            getContext().commands.focus();
          }}
        >
          <Remirror
            manager={manager}
            classNames={[editorClassName]}
            initialContent={state}
            autoFocus={autoFocus}
          >
            <EditorToolbar />
            <EditorComponent />
            <OnChangeHTML onChange={onEditorChange} />
          </Remirror>
        </div>
      </HtmlEditorContext.Provider>
    </div>
  );
};
