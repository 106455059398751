/* DON'T EDIT THIS FILE: edit original and run build again */ import React from "react";
import { useIsInPdf } from "./context.ts";

// These wrappers do two things:

//  reduce bundle size by enabling to only loading the pdf dependencies when needed
//  enable the same code to render html and pdf

let PdfDiv;
let PdfSpan;

export const setPdfDiv = (value) => (PdfDiv = value);
export const setPdfSpan = (value) => (PdfSpan = value);

type TagProps<T extends keyof JSX.IntrinsicElements = "div"> = {
  tagName?: T;
  children?: React.ReactNode;
  minPresenceAhead?: number;
  break?: boolean;
  wrap?: boolean;
} & JSX.IntrinsicElements[T];

export const Div = <T extends keyof JSX.IntrinsicElements>({
  tagName,
  children,
  minPresenceAhead = undefined,
  ...props
}: TagProps<T>) => {
  const isInPdf = useIsInPdf();
  if (isInPdf) {
    return (
      <PdfDiv
        children={children}
        minPresenceAhead={minPresenceAhead}
        {...props}
      />
    );
  } else {
    return React.createElement(tagName ?? "div", props, children);
  }
};

export const Span = ({ ...props }) => {
  const isInPdf = useIsInPdf();
  return isInPdf ? <PdfSpan {...props} /> : <span {...props} />;
};

export const Paragraph = <T extends keyof JSX.IntrinsicElements>({
  className = "",
  ...props
}: TagProps<T>) => {
  const MyDiv = Div<T>;
  return <MyDiv className={"mb-2 " + className} {...props} />;
};
