/* DON'T EDIT THIS FILE: edit original and run build again */ export const sgrsReportsPath = "/informes-sgrs";
export const getSgrsReportUrl = (sgrReportId) => {
  return sgrsReportsPath + "/" + sgrReportId + "/informe";
};
export const getPresentationViewUrl = (
  sgrReportId: string,
  fileId: string
): string => {
  return (
    sgrsReportsPath + "/vista-de-presentacion/" + sgrReportId + "/" + fileId
  );
};
