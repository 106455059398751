/* DON'T EDIT THIS FILE: edit original and run build again */ import { alerter } from "../../alert/alert.tsx";
import { AttachmentRef } from "../../attachment-type/attachment-ref-type.ts";
import { ImagePreview } from "../../attachment-ui/image-preview.tsx";
import { SavingFileInputButton } from "../../attachment-ui/saving-file-input-button.tsx";
import { getAttachmentPermalink } from "../../attachment/attachment-storage.ts";
import { CommandButton } from "../../html-editor/button/command-button.tsx";
import { useHtmlEditorContext } from "../../html-editor/use-html-editor-context.ts";
import { DarkTitle } from "../../theme/dark-title.tsx";
import { ImageIcon } from "../../theme/icon.ts";
import { Loading } from "../../theme/loading.tsx";
import { useActive, useCommands, useHelpers } from "@remirror/react";
import { useCallback, useEffect, useState } from "react";

const ImageUploadContent = ({
  loading,
  userId,
  attachment,
  setAttachment,
  setLoading,
}: {
  loading: boolean;
  userId: string;
  attachment: AttachmentRef | null;
  setAttachment: (attachment: AttachmentRef | null) => void;
  setLoading: (loading: boolean) => void;
}) => {
  if (loading) {
    return <Loading />;
  }
  if (attachment) {
    return <ImagePreview attachment={attachment} />;
  }
  return (
    <SavingFileInputButton
      uploadLabel="Seleccionar"
      uid={userId}
      onUpload={([attachment]) => setAttachment(attachment)}
      onUploadingChange={setLoading}
      multiple={false}
      accept=".png,.jpg,.jpeg"
    />
  );
};

const ImageUploadModal = ({
  userId,
  onAttachmentReady,
}: {
  userId: string;
  onAttachmentReady: (attachment: AttachmentRef) => void;
}) => {
  const [attachment, setAttachment] = useState<AttachmentRef | null>(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (attachment) {
      onAttachmentReady(attachment);
    }
  }, [attachment, onAttachmentReady]);

  return (
    <>
      <DarkTitle>Insertar imagen</DarkTitle>
      <ImageUploadContent
        userId={userId}
        loading={loading}
        setLoading={setLoading}
        attachment={attachment}
        setAttachment={setAttachment}
      />
    </>
  );
};

export const ImageButton = () => {
  const { getHTML } = useHelpers();
  const { insertImage } = useCommands();
  const { userId, htmlEditorState, onEditorChange } = useHtmlEditorContext();
  const [attachment, setAttachment] = useState<AttachmentRef | null>(null);
  const [result, setResult] = useState<boolean | null>(false);

  useEffect(() => {
    if (result && attachment) {
      const permalink = getAttachmentPermalink(attachment.id);
      setResult(null);
      setAttachment(null);
      insertImage({
        src: permalink,
      });
      onEditorChange?.(getHTML(), {
        ...attachment,
        permalink,
      });
    }
  }, [
    result,
    attachment,
    htmlEditorState,
    insertImage,
    onEditorChange,
    getHTML,
  ]);

  const handleAction = useCallback(async () => {
    // @ts-expect-error I think the types are wrong
    if (insertImage.enabled(undefined)) {
      const result = (await alerter.confirm(
        <ImageUploadModal
          userId={userId as string}
          onAttachmentReady={setAttachment}
        />
      )) as any;
      setResult(result);
    }
  }, [insertImage, userId]);

  const active = useActive().image();
  // @ts-expect-error I think the types are wrong
  const enabled = insertImage.enabled(undefined);

  return (
    <CommandButton
      commandName="image"
      active={active}
      enabled={enabled}
      onAction={handleAction}
    >
      <ImageIcon />
    </CommandButton>
  );
};
