/* DON'T EDIT THIS FILE: edit original and run build again */ import { LiveTextViewer } from "../../live-form/ui/text-viewer.tsx";
import {
  LiveFieldInputParam,
  LiveFieldRenderer,
} from "../../live-form/ui/types.ts";
import { useLiveAfterBlurTextInput } from "../../live-form/ui/use-live-after-blur-text-input.tsx";
import { TextInput } from "../../ui/form/text-input.tsx";
import { textLiveFieldType } from "./type.ts";

const Input = (fieldInfo: LiveFieldInputParam) => (
  <TextInput {...useLiveAfterBlurTextInput(fieldInfo)} />
);

export const textLiveFieldRenderers: LiveFieldRenderer = {
  type: textLiveFieldType,
  Input,
  Viewer: LiveTextViewer,
};
