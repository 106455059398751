/* DON'T EDIT THIS FILE: edit original and run build again */ import { EditorButtonGroup } from "../../html-editor/button-group/editor-button-group.tsx";
import { LinkButton } from "../../html-editor/button/link-button.tsx";
import { RemoveLinkButton } from "../../html-editor/button/remove-link-button.tsx";

export const LinkButtonGroup = () => (
  <EditorButtonGroup name="link">
    <LinkButton />
    <RemoveLinkButton />
  </EditorButtonGroup>
);
