/* DON'T EDIT THIS FILE: edit original and run build again */ import { AlycTenantHandler } from "../../tenant-handlers/alyc-tenant-handler.ts";
import { bavsaTenant } from "./code.ts";
import { bavsaFullLabel } from "./full-label.ts";

class BavsaTenantHandler extends AlycTenantHandler {
  getCode(): string {
    return bavsaTenant;
  }

  getPathLabel(): string | null {
    return "bavsa";
  }

  getLabel(): string {
    return "BAVSA";
  }

  getFullLabel(): string {
    return bavsaFullLabel;
  }

  getNotificationsFrom(): string {
    return "no-responder@matchfin.ar";
  }

  getCuit(): string | null {
    return "30-61594737-3";
  }

  getCnvRegistryId(): string | null {
    return "497";
  }

  publishesInMarketplace(): boolean {
    return true;
  }
}

const bavsaTenantHandler = new BavsaTenantHandler();

export default bavsaTenantHandler;
