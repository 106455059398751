/* DON'T EDIT THIS FILE: edit original and run build again */ import { FormControl } from "react-bootstrap";
import { NumericFormat } from "react-number-format";

export const NumberFormControl = ({
  onChange,
  setValue,
  value,
  ...props
}: any) => (
  <FormControl
    as={NumericFormat}
    thousandSeparator="."
    decimalSeparator=","
    value={value}
    onValueChange={({ floatValue }: { floatValue: number | undefined }) => {
      // if the value is not a number, pass null
      const numberToPass =
        floatValue === undefined || isNaN(floatValue) ? null : floatValue;
      if (setValue) {
        setValue(numberToPass);
      }
      if (onChange) {
        onChange(numberToPass);
      }
    }}
    {...props}
  />
);
