/* DON'T EDIT THIS FILE: edit original and run build again */ export const multiValidator =
  (...validators) =>
  (...params) => {
    for (const validator of validators) {
      if (validator) {
        const tmp = validator(...params);
        if (tmp) {
          return tmp;
        }
      }
    }
    return null;
  };
