/* DON'T EDIT THIS FILE: edit original and run build again */ import { UserListType } from "../../users/user-list-type.ts";
import { createListTenantUsersPipe } from "./data-pipe.ts";
import {
  userHasPermissionToListAssignedEndClientUsers,
  userHasPermissionToListExternalTenantUsers,
  userHasPermissionToListInternalTenantUsers,
  userHasPermissionToListProducerTenantUsers,
} from "./permissions.ts";

export const tenantUsersTypeId = "tenantUsers";

export const tenantUsersType: UserListType = {
  id: tenantUsersTypeId,
  hasPermission: ({ roles }) =>
    userHasPermissionToListInternalTenantUsers(roles) ||
    userHasPermissionToListExternalTenantUsers(roles) ||
    userHasPermissionToListProducerTenantUsers(roles) ||
    userHasPermissionToListAssignedEndClientUsers(roles),
  dataPipe: createListTenantUsersPipe,
};
