/* DON'T EDIT THIS FILE: edit original and run build again */ import { createAssignedEntitiesListPipe } from "../assigned-entities/data-pipe.ts";
import { EndClientAccessRequestStatus } from "../auth-access-request/schema.ts";
import { UserProfile } from "../auth-profile/schema.ts";
import { getRoleRequestsCol } from "../auth-role-requests/role-request.ts";
import {
  RoleRequest,
  RoleRequestStatus,
} from "../auth-role-requests/schema.ts";
import { combinePipesAsArray } from "../data-pipe/combine-pipes-as-array.ts";
import { DataPipe } from "../data-pipe/data-pipe.ts";
import { colPipe } from "../firebase/firestore-pipe.ts";
import { RolesMap } from "../permission/schema.ts";
import { createBasicEndClientAccessRequestsPipe } from "../users/approve-access-requests/data-pipe.ts";
import { userHasPermissionToListApproveEndClientAccessRequests } from "../users/approve-access-requests/permissions.ts";
import { createBasicEndClientRequestsPipe } from "../users/approve-end-client-users/data-pipe.ts";
import { userHasPermissionToListApproveEndClientsUsers } from "../users/approve-end-client-users/permissions.ts";
import { userHasPermissionToListApproveUsers } from "../users/approve-tenant-users/permissions.ts";

const createUserApprovalNotificationsPipe = (
  profile: UserProfile,
  roles: RolesMap,
  tenant: string,
  approvesEndclients: boolean,
  approvesRoles: boolean
): DataPipe<number> => {
  const col = getRoleRequestsCol()
    .where("tenant", "==", tenant)
    .where("status", "==", RoleRequestStatus.pending);
  let pipe: DataPipe<RoleRequest[]>;
  if (approvesEndclients && approvesRoles) {
    pipe = colPipe(col, "request", "userHasNotificationPipe");
  } else if (approvesRoles) {
    pipe = colPipe(
      col.where("isEndClient", "==", false),
      "request",
      "userHasNotificationPipe"
    );
  } else {
    pipe = createAssignedEntitiesListPipe(profile, roles, tenant).pipeToPipe(
      (assignedEntitiesList) =>
        createBasicEndClientRequestsPipe(
          roles,
          tenant,
          [RoleRequestStatus.pending],
          assignedEntitiesList
        )
    );
  }
  return pipe.pipe((usersToApprove) => usersToApprove.length);
};

const createAccessApprovalNotificationsPipe = (
  profile: UserProfile,
  roles: RolesMap,
  tenant: string
): DataPipe<number> => {
  return createAssignedEntitiesListPipe(profile, roles, tenant)
    .pipeToPipe((assignedEntitiesList) =>
      createBasicEndClientAccessRequestsPipe(
        roles,
        tenant,
        [EndClientAccessRequestStatus.pending],
        assignedEntitiesList
      )
    )
    .pipe((accessToApprove) => accessToApprove.length);
};

export const getUsersHasNotificationPipe = ({
  profile,
  roles,
  tenant,
}: {
  profile: UserProfile;
  roles: RolesMap;
  tenant: string;
}): DataPipe<any> /* FIXME: properly type sidebar notifications */ => {
  const approvesRoles = userHasPermissionToListApproveUsers(roles);
  const approvesEndclients =
    userHasPermissionToListApproveEndClientsUsers(roles);
  const approvesAccessRequests =
    userHasPermissionToListApproveEndClientAccessRequests(roles);
  if (!approvesRoles && !approvesEndclients && !approvesAccessRequests) {
    return DataPipe.withInitialData(null, {});
  }
  const pipes: DataPipe<number>[] = [];
  if (approvesRoles || approvesEndclients) {
    pipes.push(
      createUserApprovalNotificationsPipe(
        profile,
        roles,
        tenant,
        approvesEndclients,
        approvesRoles
      )
    );
  }
  if (approvesAccessRequests) {
    pipes.push(createAccessApprovalNotificationsPipe(profile, roles, tenant));
  }
  return combinePipesAsArray(pipes).pipe((data) => {
    const unread = data.reduce((accum, curr) => accum + curr, 0);
    if (unread === 0) {
      return null;
    }
    return {
      unread,
      badge: "Hay usuarios para aprobar",
    };
  });
};
