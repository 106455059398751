/* DON'T EDIT THIS FILE: edit original and run build again */ import { promisePipe } from "../data-pipe/promise-pipe.ts";
import { usePipe } from "../firebase/use-pipe.ts";
import { registerPasswordSetterLiveFieldType } from "./register.ts";
import { Scorers } from "./scorers-type.ts";

let scorersCache: Scorers | null = null;

const getPasswordScorer = async (): Promise<Scorers> => {
  if (scorersCache === null) {
    const [
      { zxcvbn, zxcvbnAsync, zxcvbnOptions },
      zxcvbnCommonPackage,
      zxcvbnEsEsPackage,
      zxcvbnEnPackage,
    ] = await Promise.all([
      import("@zxcvbn-ts/core"),
      import("@zxcvbn-ts/language-common"),
      import("@zxcvbn-ts/language-es-es"),
      import("@zxcvbn-ts/language-en"),
    ]);
    zxcvbnOptions.setOptions({
      dictionary: {
        ...zxcvbnCommonPackage.dictionary,
        ...zxcvbnEsEsPackage.dictionary,
        ...zxcvbnEnPackage.dictionary,
      },
      graphs: zxcvbnCommonPackage.adjacencyGraphs,
      useLevenshteinDistance: true,
    });
    scorersCache = {
      sync: (passwordToScore: string, userInputs) =>
        zxcvbn(passwordToScore, userInputs),
      async: async (passwordToScore, userInputs) =>
        zxcvbnAsync(passwordToScore, userInputs),
    };
  }
  return scorersCache;
};

const getPasswordScorerPipe = () => promisePipe(getPasswordScorer());

export const usePasswordSetterScorers = ():
  | [loading: true, scorers: null, error: null]
  | [loading: false, scorers: Scorers, error: null]
  | [loading: false, scorers: null, error: string | Error] => {
  registerPasswordSetterLiveFieldType();
  return usePipe(getPasswordScorerPipe, []);
};
