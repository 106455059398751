/* DON'T EDIT THIS FILE: edit original and run build again */ import firebase from "firebase/compat/app";
import { getPrivateProfileDocument } from "../auth-profile/private-profile.ts";
import { getProfileDocument } from "../auth-profile/profile.ts";
import {
  PrivateProfile,
  Profile,
  UserPrivateProfile,
  UserProfile,
} from "../auth-profile/schema.ts";
import { FirebaseAccount } from "../auth/firebase-account.ts";
import { mapArrayToObject } from "../core/map-array-to-object.ts";
import { DataPipe } from "../data-pipe/data-pipe.ts";
import {
  DocumentBaseData,
  FirestoreDocumentSnapshot,
} from "../firebase/firestore-wrappers-types.ts";
import { FirestoreDocument } from "../firebase/firestore-wrappers.ts";
import {
  FirstTimePopups,
  UserFirstTimePopups,
  getFirstTimePopupsDoc,
} from "../first-time-popups-data/data.ts";
import { getUserRolesDoc } from "../permission/roles.ts";
import { RolesMap, UserRoles } from "../permission/schema.ts";

export type UserRelatedInfo = {
  account?: FirebaseAccount;
  profile?: UserProfile;
  roles?: RolesMap;
  privateProfile?: UserPrivateProfile;
  updating?: boolean;
  firstTimeModals?: UserFirstTimePopups;
};

const UserDataFetcher = class<S extends DocumentBaseData, T> {
  private value: undefined | T;
  private getDoc: (id: string) => FirestoreDocument<S>;
  private onchange: () => void;
  private transform: (snap: FirestoreDocumentSnapshot<S>) => T;
  private cleaner: (() => void) | null;

  constructor(
    getDoc: (id: string) => FirestoreDocument<S>,
    onChange: () => void,
    transform: (snap: FirestoreDocumentSnapshot<S>) => T
  ) {
    this.value = undefined;
    this.getDoc = getDoc;
    this.onchange = onChange;
    this.transform = transform;
    this.cleaner = null;
  }

  clean(): void {
    if (this.cleaner) {
      this.cleaner();
      this.cleaner = null;
    }
    this.value = undefined;
  }

  async handleNewUid(uid: string): Promise<void> {
    this.clean();
    if (uid) {
      this.cleaner = this.getDoc(uid).onSnapshot((doc) => {
        this.value = this.transform(doc);
        this.onchange();
      });
    }
  }

  getValue(): T | undefined {
    return this.value;
  }
};

const profileTransform = (
  snap: FirestoreDocumentSnapshot<Profile>
): UserProfile | undefined => {
  const data: Profile | undefined = snap.data();
  if (data) {
    return {
      ...data,
      uid: snap.id,
    };
  }
  return undefined;
};

const rolesTransform = (
  snap: FirestoreDocumentSnapshot<UserRoles>
): RolesMap | undefined => {
  if (snap.exists) {
    return mapArrayToObject(snap.data()?.roles ?? [], (role) => [role, true]);
  }
  return undefined;
};

const firstTimesTransform = (
  snap: FirestoreDocumentSnapshot<FirstTimePopups>
): UserFirstTimePopups | undefined => {
  if (snap.exists) {
    return {
      dontShowAgain: { ...snap.data()?.dontShowAgain },
      uid: snap.id,
    };
  }
  return undefined;
};

const privateProfileTransform = (
  snap: FirestoreDocumentSnapshot<PrivateProfile>
): UserPrivateProfile | undefined => {
  const data = snap.data();
  if (data) {
    return {
      ...data,
      uid: snap.id,
    };
  }
  return undefined;
};

const createAccountPipe = () => {
  let account: FirebaseAccount | undefined = undefined;
  let lastUid: string | null = null;
  const pipe = new DataPipe<UserRelatedInfo>(null, "accountPipe");
  const maybeUpdatePipe = () => {
    if (account) {
      const profile = profileFetcher.getValue();
      const roles = rolesFetcher.getValue();
      const privateProfile = privateProfileFetcher.getValue();
      const firstTimeModals = firstTimesFetcher.getValue();
      if (profile && roles && privateProfile && firstTimeModals) {
        pipe.setValue({
          account,
          profile,
          roles,
          privateProfile,
          firstTimeModals,
        });
      } else {
        // to be able to halt redirects until the full account and related permissions are ready
        pipe.setValue({ ...pipe.getValue(), updating: true });
      }
    } else {
      pipe.setValue({
        account: undefined,
        profile: undefined,
        privateProfile: undefined,
        roles: undefined,
        firstTimeModals: undefined,
      });
    }
  };
  const createFetcher = <S extends DocumentBaseData, T>(
    getDoc: (id: string) => FirestoreDocument<S>,
    transform: (snap: FirestoreDocumentSnapshot<S>) => T
  ) => new UserDataFetcher(getDoc, maybeUpdatePipe, transform);
  const profileFetcher = createFetcher(getProfileDocument, profileTransform);
  const rolesFetcher = createFetcher(getUserRolesDoc, rolesTransform);
  const privateProfileFetcher = createFetcher(
    getPrivateProfileDocument,
    privateProfileTransform
  );
  const firstTimesFetcher = createFetcher(
    getFirstTimePopupsDoc,
    firstTimesTransform
  );
  // @ts-expect-error the hook is auto generated
  firebase.auth().onAuthStateChanged((user: User | null) => {
    account = user;
    const uid = user?.uid ?? null;
    if (lastUid !== uid) {
      rolesFetcher.handleNewUid(uid);
      profileFetcher.handleNewUid(uid);
      privateProfileFetcher.handleNewUid(uid);
      firstTimesFetcher.handleNewUid(uid);
      lastUid = uid;
    }
    maybeUpdatePipe();
  });
  return pipe;
};

let accountPipe: DataPipe<UserRelatedInfo> | null = null;

export const getAccountPipe = (): DataPipe<UserRelatedInfo> => {
  if (!accountPipe) {
    accountPipe = createAccountPipe();
  }
  return accountPipe;
};
