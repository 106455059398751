/* DON'T EDIT THIS FILE: edit original and run build again */ import {
  canApproveAssignedFoldersEndClientAccessRequests,
  canApproveEndClientAccessRequests,
  canApproveProducerFolderEndClientAccessRequests,
} from "../../permission/permissions.ts";
import { RolesMap } from "../../permission/schema.ts";
import { userHasPermission } from "../../permission/user-has-permission.ts";

export const userHasPermissionToListApproveEndClientAccessRequests = (
  roles: RolesMap
): boolean =>
  userHasPermission(roles, canApproveEndClientAccessRequests) ||
  userHasPermission(roles, canApproveProducerFolderEndClientAccessRequests) ||
  userHasPermission(roles, canApproveAssignedFoldersEndClientAccessRequests);
