/* DON'T EDIT THIS FILE: edit original and run build again */ import { LiveCell } from "../../live-form/cell.ts";
import { LiveRecord } from "../../live-form/live-record.ts";
import { Labeled } from "../../ui/form/labeled.tsx";
import { ValidationFeedback } from "../../ui/form/validation-feedback.tsx";
import { AfterLabelRenderer } from "./after-label-renderer.tsx";
import {
  RawLiveFormViewer,
  createLiveFormFieldViewer,
} from "./raw-live-form-viewer.tsx";
import {
  LiveFieldGetLiveFormInputFactory,
  LiveFieldSaver,
  LiveFormWidgets,
} from "./types.ts";

const Input = ({
  getLiveFormInputFactory,
  liveCell,
  saveField,
  Widgets,
  canAutoFocus,
  allowDefaultValue = true,
  onEnterPress,
}: {
  getLiveFormInputFactory: LiveFieldGetLiveFormInputFactory;
  liveCell: LiveCell;
  saveField: LiveFieldSaver;
  Widgets: Record<string, any>;
  canAutoFocus: boolean;
  allowDefaultValue?: boolean;
  onEnterPress?: () => void;
}) => {
  const liveField = liveCell.getLiveField();
  if (liveField.getSpecDefaultValue() !== undefined && !allowDefaultValue) {
    throw new Error("defaultValuesAreNotAllowedHere");
  }
  const Input = getLiveFormInputFactory(liveField.getType()).Input;
  const input = (
    <Input
      {...{
        liveCell,
        saveField,
        getLiveFormInputFactory,
        canAutoFocus,
        Widgets,
        onEnterPress,
      }}
    />
  );
  const label = liveField.getLabel();
  const maybeValidatedInput = liveField.showsOwnErrors()
    ? input
    : (() => {
        {
          const error = liveCell.getThisFieldError();
          return (
            <>
              {input}
              {error ? <ValidationFeedback error={error} /> : null}
            </>
          );
        }
      })();
  return label ? (
    <Labeled
      label={label}
      optional={liveCell.showOptionalIndicator()}
      afterLabel={
        <AfterLabelRenderer
          AfterLabel={liveField.getAfterLabel()}
          Widgets={Widgets}
          liveData={liveCell.getLiveData()}
        />
      }
      marginAfterLabel={liveField.hasMarginAfterLabel()}
      dontUseLabelTag={liveField.dontUseLabelTag()}
      input={maybeValidatedInput}
    />
  ) : (
    maybeValidatedInput
  );
};

type RawLiveFormParams = {
  getLiveFormInputFactory: LiveFieldGetLiveFormInputFactory;
  liveRecord: LiveRecord;
  saveField: (field: string, value: any) => Promise<void> | void;
  Widgets?: LiveFormWidgets;
  autoFocus?: boolean;
  allowDefaultValue?: boolean;
  onEnterPress?: () => any;
};

export const RawLiveForm = ({
  getLiveFormInputFactory,
  liveRecord,
  saveField,
  Widgets = {},
  autoFocus = false,
  allowDefaultValue = true,
  onEnterPress,
}: RawLiveFormParams) => {
  const liveData = liveRecord.getLiveData();
  if (liveData.viewOnly) {
    return (
      <RawLiveFormViewer
        getLiveFormInputFactory={getLiveFormInputFactory}
        liveRecord={liveRecord}
        Widgets={Widgets}
      />
    );
  }
  return liveRecord.createCells().map((liveCell) => {
    const liveField = liveCell.getLiveField();
    if (liveField.isViewMode()) {
      return createLiveFormFieldViewer(
        liveCell,
        Widgets,
        getLiveFormInputFactory,
        true
      );
    }
    return (
      <Input
        key={liveField.getKey()}
        getLiveFormInputFactory={getLiveFormInputFactory}
        liveCell={liveCell}
        saveField={saveField}
        Widgets={Widgets}
        canAutoFocus={autoFocus}
        allowDefaultValue={allowDefaultValue}
        onEnterPress={onEnterPress}
      />
    );
  });
};
