/* DON'T EDIT THIS FILE: edit original and run build again */ import { OnboardingOptions } from "../../tenant-handler-type/onboarding-options.ts";
import { AlycTenantHandler } from "../../tenant-handlers/alyc-tenant-handler.ts";
import { BaseLineOptions } from "../../tenant-handlers/base-line-options.ts";
import { ComponentType, SVGProps } from "react";
import { stcTenant } from "./code.ts";
import { stcFullLabel } from "./full-label.ts";
import { stcLabel } from "./label.ts";
import { stcOnboardingOptions } from "./stc-onboarding-options.ts";

class StcTenantHandler extends AlycTenantHandler {
  async getAuthPageLogo(): Promise<ComponentType<SVGProps<SVGElement>>> {
    // @ts-expect-error the hook is auto generated
    return (await import("./logo_stc.svg?react")).default;
  }

  getCode(): string {
    return stcTenant;
  }

  getPathLabel(): string | null {
    return "stc";
  }

  getLabel(): string {
    return stcLabel;
  }

  getFullLabel(): string {
    return stcFullLabel;
  }

  getLineOptions(): BaseLineOptions | null {
    return null;
  }

  getOnboardingOptions(): OnboardingOptions | null {
    return stcOnboardingOptions;
  }

  getNotificationsFrom(): string {
    return "no-responder@matchfin.ar";
  }

  getCuit(): string {
    return "30-71437950-6";
  }

  getCnvRegistryId(): string | null {
    return "338";
  }

  publishesInMarketplace(): boolean {
    return true;
  }
}

const stcTenantHandler = new StcTenantHandler();

export default stcTenantHandler;
