/* DON'T EDIT THIS FILE: edit original and run build again */ import { blue70, gray40 } from "../../theme/custom-colors.ts";
import { EyeIcon } from "../../theme/icon.ts";
import { InputElement } from "../../ui/form/input-element.tsx";
import { useState } from "react";
import { InputGroup } from "react-bootstrap";

const ViewButton = ({
  isHidden,
  onClick,
}: {
  isHidden: boolean;
  onClick: React.MouseEventHandler<HTMLLabelElement>;
}) => (
  <label className="input-group-text" onClick={onClick}>
    <EyeIcon
      size={25}
      style={{
        fill: isHidden ? gray40 : blue70,
      }}
    />
  </label>
);

export const PasswordInput = ({
  id,
  value,
  setValue,
  disabled,
  autoFocus,
  className,
  autoComplete,
  placeholder,
  onKeyDown,
}: {
  id?: string;
  value: string;
  setValue: (value: string) => void;
  disabled?: boolean;
  autoFocus?: boolean;
  className?: string;
  autoComplete?: string;
  placeholder?: string;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
}) => {
  const [hide, setHide] = useState(true);
  return (
    <>
      <InputGroup>
        <InputElement
          {...{
            type: hide ? "password" : "",
            id,
            value,
            setValue,
            disabled,
            autoFocus,
            className,
            autoComplete,
            placeholder,
            onKeyDown,
          }}
        />
        <ViewButton onClick={() => setHide(!hide)} isHidden={hide} />
      </InputGroup>
    </>
  );
};
