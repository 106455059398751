/* DON'T EDIT THIS FILE: edit original and run build again */ import { getLiveFormInputFactory } from "../live-form-field-collection/get-input-factory.ts";
import { LiveRecord } from "../live-form/live-record.ts";
import { RawLiveForm } from "../live-form/ui/raw-live-form.tsx";
import { LiveFormWidgets } from "../live-form/ui/types.ts";

export const LiveForm = ({
  liveRecord,
  saveField,
  Widgets = {},
  autoFocus = false,
  allowDefaultValue = true,
  onEnterPress,
}: {
  liveRecord: LiveRecord;
  saveField: (field: string, value: any) => Promise<void> | void;
  disabled?: boolean;
  Widgets?: LiveFormWidgets;
  autoFocus?: boolean;
  allowDefaultValue?: boolean;
  onEnterPress?: () => any;
}) => (
  <RawLiveForm
    getLiveFormInputFactory={getLiveFormInputFactory}
    liveRecord={liveRecord}
    saveField={saveField}
    Widgets={Widgets}
    autoFocus={autoFocus}
    allowDefaultValue={allowDefaultValue}
    onEnterPress={onEnterPress}
  />
);
