/* DON'T EDIT THIS FILE: edit original and run build again */ import { canListOnboardings } from "../permission/permissions.ts";
import { userHasPermission } from "../permission/user-has-permission.ts";
import { SidebarMenuItem } from "../sidebar-type/sidebar-types.ts";
import { OnboardingIcon } from "../theme/icon.ts";

export const onboardingsListingTabId = "onboardingsListing";

const onboardingsMenuItem: SidebarMenuItem = {
  id: onboardingsListingTabId,
  getPath: () => "/onboardings",
  label: "Onboardings",
  condition: ({ tenantHandler, roles }) =>
    tenantHandler.supportsOnboardings() &&
    userHasPermission(roles, canListOnboardings),
  order: 4,
  hasPriority: true,
  icon: (className) => <OnboardingIcon className={className} />,
};

export default onboardingsMenuItem;
