/* DON'T EDIT THIS FILE: edit original and run build again */ import { alycUserCompanyTypeId } from "../user-company-type-const/user-company-type-const.ts";
import { BaseTenantHandler } from "./base-tenant-handler.ts";

export abstract class AlycTenantHandler extends BaseTenantHandler {
  getUserCompanyType(): string {
    return alycUserCompanyTypeId;
  }

  abstract getCnvRegistryId(): string | null;
}
