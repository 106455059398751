/* DON'T EDIT THIS FILE: edit original and run build again */ import { CommandButton } from "../../html-editor/button/command-button.tsx";
import { useActive, useCommands } from "@remirror/react";
import { useCallback, useMemo } from "react";

export const HeadingButton = ({
  level,
  children,
}: {
  level: number;
  children: React.ReactNode;
}) => {
  const { toggleHeading } = useCommands();
  const attrs = useMemo(() => ({ level }), [level]);
  const handleAction = useCallback(() => {
    if (toggleHeading.enabled(attrs)) {
      toggleHeading(attrs);
    }
  }, [toggleHeading, attrs]);

  const active = useActive()?.heading(attrs);
  const enabled = toggleHeading.enabled(attrs);

  return (
    <CommandButton
      commandName="cut"
      active={active}
      enabled={enabled}
      onAction={handleAction}
    >
      {children}
    </CommandButton>
  );
};
