/* DON'T EDIT THIS FILE: edit original and run build again */ import { AlycTenantHandler } from "../../tenant-handlers/alyc-tenant-handler.ts";
import { inviuTenant } from "./code.ts";
import { inviuFullLabel } from "./full-label.ts";
import { inviuLabel } from "./label.ts";

class InvuTenantHandler extends AlycTenantHandler {
  getCode(): string {
    return inviuTenant;
  }

  getPathLabel(): string | null {
    return "inviu";
  }

  getLabel(): string {
    return inviuLabel;
  }

  getFullLabel(): string {
    return inviuFullLabel;
  }

  getNotificationsFrom(): string {
    return "no-responder@matchfin.ar";
  }

  getCuit(): string | null {
    return "30-61945306-5";
  }

  getCnvRegistryId(): string | null {
    return "205";
  }
}

const inviuTenantHandler = new InvuTenantHandler();

export default inviuTenantHandler;
