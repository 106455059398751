/* DON'T EDIT THIS FILE: edit original and run build again */ import { BaseLiveField, LiveFieldSpec } from "../../live-form/field-spec.ts";

export type BaseTextLiveFieldSpec = LiveFieldSpec & {
  suffix?: string;
  autoComplete?: string;
  field: string; // not undefined
};

export abstract class BaseTextLiveField extends BaseLiveField {
  private suffix: string;
  private autoComplete: string;
  private field: string;

  constructor(spec: BaseTextLiveFieldSpec, type: string) {
    super(spec, type);
    this.suffix = spec.suffix ?? "";
    this.autoComplete = spec.autoComplete ?? "";
    this.field = spec.field;
  }

  getSuffix() {
    return this.suffix;
  }

  getAutoComplete() {
    return this.autoComplete;
  }

  getField(): string {
    return this.field;
  }
}
