/* DON'T EDIT THIS FILE: edit original and run build again */ import { DocumentType } from "../background-check/argentina-document-type.ts";
import { BackgroundCheckAmbiguousMatch } from "../background-check/background-check-types.ts";
import { BaseFolder } from "../base-folder/base-folder-type.ts";
import { InlineEntity } from "../entity/inline-entity-type.ts";
import { EntityType } from "../gov-id-number/entity-type.ts";
import { useEffect, useReducer, useRef } from "react";
import {
  findCurrentIdNumberError,
  getEntityInfoCall,
  shouldMarkCuitInputError,
} from "./base-cuit-input.tsx";

const cuitInputReducer = (state: any, action: any) => {
  const type = action.type;
  if (type === "replaceState") {
    return action.newState;
  } else {
    const newState = { ...state };
    if (type === "setLoadingIdNumber") {
      newState.loadingIdNumber = true;
      newState.idNumber = action.idNumber;
      const match = action.match;
      if (match) {
        newState.matchChosen = true;
      }
      newState.fixed = !!match;
    } else if (type === "inputfocus") {
      newState.inputIsFocused = true;
    } else if (type === "inputblur") {
      newState.inputIsFocused = false;
    } else if (type === "matchselectorfocus") {
      newState.matchSelectorIsFocused = true;
    } else if (type === "matchselectorblur") {
      newState.matchSelectorIsFocused = false;
    } else if (type === "loaded") {
      const { info } = action;
      newState.entityName = info?.data?.entityName ?? null;
      newState.backendErrorMessage = info?.error ?? null;
      newState.matches = info?.matches ?? null;
      newState.matchChosen = false;
      newState.loadingIdNumber = false;
    } else {
      throw new Error("unknownCuitReducerType: " + type);
    }
    return newState;
  }
};

const getIdNumberFromValue = (value: any, useDni?: boolean) =>
  (useDni ? value?.dni : value?.cuit) ?? "";

const getStateFromValue = (value: any, useDni?: boolean) => {
  const idNumber = getIdNumberFromValue(value, useDni);
  return {
    idNumber,
    oldIdNumber: idNumber,
    entityName: value?.entityName || "",
    loadingIdNumber: false,
  };
};

const setValueForInput = async ({
  cancelLastSearchRef,
  dispatch,
  baseFolder,
  idNumber,
  required,
  allowEntityTypes,
  setValue,
  useDni,
  match,
}: any) => {
  if (cancelLastSearchRef.current) {
    cancelLastSearchRef.current();
  }
  let canceled = false;
  cancelLastSearchRef.current = () => {
    canceled = true;
  };
  dispatch({ type: "setLoadingIdNumber", idNumber, match });
  const info = await getEntityInfoCall(
    baseFolder,
    idNumber,
    useDni,
    match?.gender
  );
  if (canceled) {
    return;
  }
  dispatch({ type: "loaded", info });
  const error = findCurrentIdNumberError({
    required,
    idNumber,
    loadingIdNumber: false,
    hasEntityName: !!info?.data?.entityName,
    allowEntityTypes,
    useDni,
    backendErrorMessage: info?.error,
  });
  if (!error) {
    setValue(info?.data);
  }
};

export type CuitInputState = {
  idNumber: string;
  loadingIdNumber: boolean;
  entityName: string;
  error: string | null;
  markError: boolean;
  useDni: boolean;
  matches?: BackgroundCheckAmbiguousMatch[];
  fixed: boolean;
  isFocused: boolean;
  matchChosen?: boolean;
  dispatchFocusInInput: () => void;
  dispatchBlurInInput: () => void;
  dispatchFocusInMatchSelector: () => void;
  dispatchBlurInMatchSelector: () => void;
  setValue: (idNumber: string) => Promise<void>;
  setMatch: (match: BackgroundCheckAmbiguousMatch) => Promise<void>;
};

export const useCuitInputState = ({
  value,
  setValue,
  baseFolder,
  required,
  allowEntityTypes,
  showErrors,
  useDni = false,
}: {
  value: Partial<InlineEntity> | undefined;
  setValue: (value: Partial<InlineEntity> | undefined) => void;
  baseFolder?: BaseFolder;
  required?: boolean;
  allowEntityTypes?: EntityType;
  showErrors: boolean;
  useDni?: boolean;
}): CuitInputState => {
  const [state, dispatch] = useReducer(cuitInputReducer, null, () =>
    getStateFromValue(value, useDni)
  );
  useEffect(() => {
    if (getIdNumberFromValue(value, useDni) !== state.oldIdNumber) {
      dispatch({
        type: "replaceState",
        newState: getStateFromValue(value, useDni),
      });
    }
  }, [value, state.oldIdNumber, useDni]);
  const cancelLastSearchRef = useRef(null);
  const {
    idNumber,
    loadingIdNumber,
    entityName,
    inputIsFocused,
    matchSelectorIsFocused,
    matches,
    fixed,
    matchChosen,
    backendErrorMessage,
  } = state;
  const error = findCurrentIdNumberError({
    required,
    idNumber,
    loadingIdNumber,
    hasEntityName: !!entityName,
    allowEntityTypes,
    useDni,
    backendErrorMessage,
  });
  const markError = shouldMarkCuitInputError({
    showErrors,
    isFocused: inputIsFocused || matchSelectorIsFocused,
    error,
    isBackendErrorMessage: !!backendErrorMessage,
  });
  const setValueWithType = (
    idNumber: string,
    documentType: string,
    match: any
  ) =>
    setValueForInput({
      cancelLastSearchRef,
      dispatch,
      baseFolder,
      idNumber,
      required,
      allowEntityTypes,
      setValue,
      useDni: documentType === DocumentType.Dni,
      match,
    });
  let inputBlurTimeout: any, selectorBlurTimeout: any;
  return {
    idNumber,
    loadingIdNumber,
    entityName,
    error,
    markError,
    useDni,
    matches,
    fixed,
    isFocused: inputIsFocused || matchSelectorIsFocused,
    matchChosen,
    dispatchFocusInInput: () => {
      if (inputBlurTimeout) {
        clearTimeout(inputBlurTimeout);
      }
      dispatch({ type: "inputfocus" });
    },
    dispatchBlurInInput: () => {
      inputBlurTimeout = setTimeout(() => dispatch({ type: "inputblur" }), 0);
    },
    dispatchFocusInMatchSelector: () => {
      if (selectorBlurTimeout) {
        clearTimeout(selectorBlurTimeout);
      }
      dispatch({ type: "matchselectorfocus" });
    },
    dispatchBlurInMatchSelector: () => {
      selectorBlurTimeout = setTimeout(
        () => dispatch({ type: "matchselectorblur" }),
        0
      );
    },
    setValue: (idNumber) =>
      setValueWithType(
        idNumber,
        useDni ? DocumentType.Dni : DocumentType.Cuit,
        null
      ),
    setMatch: (match) =>
      setValueWithType(match.idNumber, match.documentType, match),
  };
};
