/* DON'T EDIT THIS FILE: edit original and run build again */ import {
  emailChannelType,
  reutersChannelType,
  whatsappChannelType,
} from "../../channel/channel-types.ts";
import { BaseOnboardingOptions } from "../../tenant-handlers/base-onboarding-options.ts";
import { ComponentType } from "react";

class BancobicaOnboardingOptions extends BaseOnboardingOptions {
  getSupportedChannelTypes(): string[] {
    return [reutersChannelType, whatsappChannelType, emailChannelType];
  }

  getReutersUserDestinations(): { fullName: string; address: string }[] {
    return [
      { fullName: "NOMBRE EJEMPLO", address: "ejemplo@bancobica.com.ar" },
    ];
  }

  hasOnboardingCoholdersSection(): boolean {
    return false;
  }

  async getDocumentHeader(): Promise<
    ComponentType<{ agreementLabel: string }>
  > {
    return (await (() => { throw new Error("This file is not meant to be imported in this context.") })()).default;
  }
}

export const bancobicaOnboardingOptions = new BancobicaOnboardingOptions();
