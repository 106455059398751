/* DON'T EDIT THIS FILE: edit original and run build again */ import { EndClientAccessRequestStatus } from "../../auth-access-request/schema.ts";
import { descSorter } from "../../core/sort-by-many.ts";
import { usersListDefaultSorters } from "../../users/list-sorters.ts";
import { UserListType } from "../../users/user-list-type.ts";
import { createApproveEndClientAccessRequestsPipe } from "./data-pipe.ts";
import { userHasPermissionToListApproveEndClientAccessRequests } from "./permissions.ts";

export const approveEndClientAccessUsersTypeId =
  "approveEndClientAccessRequests";

export const approveEndClientAccessUsersType: UserListType = {
  id: approveEndClientAccessUsersTypeId,
  hasPermission: ({ roles }) =>
    userHasPermissionToListApproveEndClientAccessRequests(roles),
  dataPipe: createApproveEndClientAccessRequestsPipe,
  sorters: [
    ...Object.values(EndClientAccessRequestStatus).map((status) =>
      descSorter((user: any) => (user.accessRequest.status === status ? 1 : 0))
    ),
    ...usersListDefaultSorters,
  ],
};
