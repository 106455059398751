/* DON'T EDIT THIS FILE: edit original and run build again */ // based on http://stackoverflow.com/questions/4060004/calculate-age-in-javascript/7091965#7091965

import { now } from "./now.ts";

export const getAge = (dateString) => {
  const today = now();
  const birthDate = new Date(dateString);
  const uncorrectedAge = today.getUTCFullYear() - birthDate.getUTCFullYear();
  const month = today.getUTCMonth() - birthDate.getUTCMonth();
  if (
    month < 0 ||
    (month === 0 && today.getUTCDate() < birthDate.getUTCDate())
  ) {
    return uncorrectedAge - 1;
  }
  return uncorrectedAge;
};
