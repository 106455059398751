/* DON'T EDIT THIS FILE: edit original and run build again */ import { fetchColIds } from "../firebase/fetch-col.ts";
import {
  FirestoreCollection,
  FirestoreDocument,
} from "../firebase/firestore-wrappers.ts";
import { getAllTenantCodes } from "../tenant-collection/tenant.ts";
import {
  getUserCompanyDoc,
  maybeFetchUserCompany,
} from "../user-company/data.ts";
import { UserCompanyType } from "../user-company/user-company-type.ts";
import { tenantLimitsColName } from "./col-name.ts";
import { ObjectPermissionLimitsData } from "./permission-limits.ts";

const getTenantUserCompanyLimitsCol = (
  userCompanyId: string
): FirestoreCollection<ObjectPermissionLimitsData> =>
  getUserCompanyDoc(userCompanyId).collection<ObjectPermissionLimitsData>(
    tenantLimitsColName
  );

export const doesUserCompanyHaveTenantLimits = (
  userCompany: UserCompanyType
): boolean =>
  userCompany.companyType === undefined || userCompany.companyType !== null;

const doesUserCompanyHaveTenantLimitsById = async (
  userCompanyId: string
): Promise<boolean> => {
  const userCompany = await maybeFetchUserCompany(userCompanyId);
  if (!userCompany) {
    return false;
  }
  return doesUserCompanyHaveTenantLimits(userCompany);
};

export const getTenantUserCompanyLimitsDoc = (
  userCompanyId: string,
  tenant: string
): FirestoreDocument<ObjectPermissionLimitsData> =>
  getTenantUserCompanyLimitsCol(userCompanyId).doc(tenant);

export const getAvailableTenantsForUserCompany = async (
  userCompanyId: string
): Promise<string[]> => {
  if (!(await doesUserCompanyHaveTenantLimitsById(userCompanyId))) {
    return getAllTenantCodes();
  }
  return fetchColIds(
    getTenantUserCompanyLimitsCol(userCompanyId).where("hideLines", "==", false)
  );
};
