// DON'T EDIT THIS FILE: edit the original Triggers.ts files and regenerate derived files

import hook0 from "../tenant/acavalores/acavalores-tenant-handler.ts";
import hook1 from "../tenant/acindar/acindar-tenant-handler.ts";
import hook2 from "../tenant/adcap/adcap-tenant-handler.ts";
import hook3 from "../tenant/affidavit/affidavit-tenant-handler.ts";
import hook4 from "../tenant/alchemy/alchemy-tenant-handler.ts";
import hook5 from "../tenant/aptorgan/aptorgan-tenant-handler.ts";
import hook6 from "../tenant/argenfunds/argenfunds-tenant-handler.ts";
import hook7 from "../tenant/argenpymes/argenpymes-tenant-handler.ts";
import hook8 from "../tenant/argsecurities/argsecurities-tenant-handler.ts";
import hook9 from "../tenant/avalfertil/avalfertil-tenant-handler.ts";
import hook10 from "../tenant/avalrural/avalrural-tenant-handler.ts";
import hook11 from "../tenant/avaluar/avaluar-tenant-handler.ts";
import hook12 from "../tenant/awa/awa-tenant-handler.ts";
import hook13 from "../tenant/bancobica/bancobica-tenant-handler.ts";
import hook14 from "../tenant/bancocoinag/bancocoinag-tenant-handler.ts";
import hook15 from "../tenant/bancocomafi/bancocomafi-tenant-handler.ts";
import hook16 from "../tenant/bancocorrientes/bancocorrientes-tenant-handler.ts";
import hook17 from "../tenant/bavsa/bavsa-tenant-handler.ts";
import hook18 from "../tenant/brio/brio-tenant-handler.ts";
import hook19 from "../tenant/casfog/casfog-tenant-handler.ts";
import hook20 from "../tenant/cuyo-aval/cuyo-tenant-handler.ts";
import hook21 from "../tenant/davalores/davalores-tenant-handler.ts";
import hook22 from "../tenant/delta/delta-tenant-handler.ts";
import hook23 from "../tenant/dracma/dracma-tenant-handler.ts";
import hook24 from "../tenant/dracma-fci/dracma-fci-tenant-handler.ts";
import hook25 from "../tenant/efire/efire-tenant-handler.ts";
import hook26 from "../tenant/facimex/facimex-tenant-handler.ts";
import hook27 from "../tenant/fidaval/fidaval-tenant-handler.ts";
import hook28 from "../tenant/fintechsgr/fintechsgr-tenant-handler.ts";
import hook29 from "../tenant/galileo/galileo-tenant-handler.ts";
import hook30 from "../tenant/garantizar/garantizar-tenant-handler.ts";
import hook31 from "../tenant/inviu/inviu-tenant-handler.ts";
import hook32 from "../tenant/lam/lam-tenant-handler.ts";
import hook33 from "../tenant/matbarofex/matbarofex-tenant-handler.ts";
import hook34 from "../tenant/matchfin/matchfin-tenant-handler.ts";
import hook35 from "../tenant/maxcapital/maxcapital-tenant-handler.ts";
import hook36 from "../tenant/megaqm/megaqm-tenant-handler.ts";
import hook37 from "../tenant/movil/movil-tenant-handler.ts";
import hook38 from "../tenant/neix/neix-tenant-handler.ts";
import hook39 from "../tenant/petrini-valores/petrini-valores-tenant-handler.ts";
import hook40 from "../tenant/portfolio/portfolio-tenant-handler.ts";
import hook41 from "../tenant/ppi/ppi-tenant-handler.ts";
import hook42 from "../tenant/rigsgr/rigsgr-tenant-handler.ts";
import hook43 from "../tenant/rosval/rosval-tenant-handler.ts";
import hook44 from "../tenant/sgr/sgr-tenant-handler.ts";
import hook45 from "../tenant/southerntrust/southerntrust-tenant-handler.ts";
import hook46 from "../tenant/stc/stc-tenant-handler.ts";
import hook47 from "../tenant/stonex/stonex-tenant-handler.ts";
import hook48 from "../tenant/stonex-alyc/stonex-alyc-tenant-handler.ts";
import hook49 from "../tenant/syc/syc-tenant-handler.ts";
import hook50 from "../tenant/syngenta/syngenta-tenant-handler.ts";
import hook51 from "../tenant/trendsgr/trendsgr-tenant-handler.ts";
import hook52 from "../tenant/trinidad-capital/trinidadcapital-tenant-handler.ts";
import hook53 from "../tenant/vinculossgr/vinculossgr-tenant-handler.ts";
import hook54 from "../tenant/winsecurities/winsecurities-tenant-handler.ts";
import hook55 from "../tenant/zofingen/zofingen-tenant-handler.ts";

export default [hook0, hook1, hook2, hook3, hook4, hook5, hook6, hook7, hook8, hook9, hook10, hook11, hook12, hook13, hook14, hook15, hook16, hook17, hook18, hook19, hook20, hook21, hook22, hook23, hook24, hook25, hook26, hook27, hook28, hook29, hook30, hook31, hook32, hook33, hook34, hook35, hook36, hook37, hook38, hook39, hook40, hook41, hook42, hook43, hook44, hook45, hook46, hook47, hook48, hook49, hook50, hook51, hook52, hook53, hook54, hook55];
