/* DON'T EDIT THIS FILE: edit original and run build again */ import { Form } from "react-bootstrap";

export type SimpleDropdownValueOptions = Record<string, string>;

type SimpleDropdownProps = {
  onChange?: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  setValue?: (value: string) => void;
  value: string;
  options: SimpleDropdownValueOptions;
  sizeCode?: "sm" | "lg";
  allowEmpty?: boolean;
  placeholder?: string;
} & React.SelectHTMLAttributes<HTMLSelectElement>;

export const SimpleDropdown = ({
  onChange,
  setValue,
  value,
  options,
  sizeCode,
  allowEmpty = true,
  placeholder = "Elegí una opción",
  ...rest
}: SimpleDropdownProps) => {
  return (
    <Form.Select
      {...rest}
      size={sizeCode}
      value={value}
      onChange={(e) => {
        if (setValue) {
          setValue(e.target.value);
        }
        if (onChange) {
          onChange(e);
        }
      }}
    >
      {allowEmpty ? <option value="">{placeholder}</option> : null}
      {Object.entries(options).map(([id, label]) => (
        <option value={id} key={id}>
          {label}
        </option>
      ))}
    </Form.Select>
  );
};
