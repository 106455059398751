/* DON'T EDIT THIS FILE: edit original and run build again */ import { AlycTenantHandler } from "../../tenant-handlers/alyc-tenant-handler.ts";
import { davaloresTenant } from "./code.ts";
import { davaloresFullLabel } from "./full-label.ts";
import { davaloresLabel } from "./label.ts";

class DavaloresTenantHandler extends AlycTenantHandler {
  getCode(): string {
    return davaloresTenant;
  }

  getPathLabel(): string | null {
    return "da-valores";
  }

  getLabel(): string {
    return davaloresLabel;
  }

  getFullLabel(): string {
    return davaloresFullLabel;
  }

  getNotificationsFrom(): string {
    return "no-responder@matchfin.ar";
  }

  getCuit(): string | null {
    return "30-71618100-2";
  }

  getCnvRegistryId(): string | null {
    return "976";
  }
}

const davaloresTenantHandler = new DavaloresTenantHandler();

export default davaloresTenantHandler;
