/* DON'T EDIT THIS FILE: edit original and run build again */ import { FirestoreDocumentData } from "../firebase/firestore-wrappers-types.ts";

export enum AssignedEntitiesEntityIdTypes {
  producer = "producer",
  assignee = "assignee",
}

export type AssignedEntities = FirestoreDocumentData<{
  entityIds: {
    [entityId: string]: AssignedEntitiesEntityIdTypes;
  };
  assignedAsProducerCount: number;
  tenant: string;
}>;
