/* DON'T EDIT THIS FILE: edit original and run build again */ import { canAccessMarketplace } from "../permission/permissions.ts";
import { userHasPermission } from "../permission/user-has-permission.ts";
import { SidebarMenuItem } from "../sidebar-type/sidebar-types.ts";
import { BriefcaseIcon } from "../theme/icon.ts";
import { marketplacePathCommon } from "./marketplace-path.ts";

export const marketplaceTabId = "marketplace";

const marketplaceMenuItem: SidebarMenuItem = {
  id: marketplaceTabId,
  getPath: () => marketplacePathCommon,
  label: "Marketplace",
  condition: ({ tenantHandler, roles }) =>
    !!tenantHandler.getLineOptions() &&
    userHasPermission(roles, canAccessMarketplace),
  order: 1,
  hasPriority: true,
  icon: (className) => <BriefcaseIcon className={className} />,
};

export default marketplaceMenuItem;
