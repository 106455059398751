/* DON'T EDIT THIS FILE: edit original and run build again */ import { descSorter } from "../../core/sort-by-many.ts";
import { RolesMap } from "../../permission/schema.ts";
import { TenantHandler } from "../../tenant-handler-type/tenant-handler.ts";
import { usersListDefaultSorters } from "../../users/list-sorters.ts";
import { createListPendingUserInvitesPipe } from "./data-pipe.ts";
import { userHasPermissionToListUsersInvited } from "./permissions.ts";
import { inviteUserStatuses } from "./status.ts";

export const inviteUsersTypeId = "inviteUsers";

export const inviteUsersType = {
  id: inviteUsersTypeId,
  hasPermission: ({
    roles,
    tenantHandler,
  }: {
    roles: RolesMap;
    tenantHandler?: TenantHandler;
  }) =>
    !!tenantHandler &&
    userHasPermissionToListUsersInvited({ roles, tenantHandler }),
  dataPipe: createListPendingUserInvitesPipe,
  sorters: [
    ...inviteUserStatuses.map((status) =>
      descSorter((user: any) => (user.status === status ? 1 : 0))
    ),
    ...usersListDefaultSorters,
  ],
};
