/* DON'T EDIT THIS FILE: edit original and run build again */ import { useAccountCached } from "../auth-ui/use-account.ts";
import { canAccessTenant } from "../auth/allowed-tenants.ts";
import { Settings } from "../settings/schema.ts";
import { unknownTenant } from "../tenant-collection/null-tenant.ts";
import { getTenantCodeFromUrl } from "../url-parsing/parse-tenant-from-url.ts";
import React, { useContext } from "react";

export const AppStateContext = React.createContext({
  settings: {},
  realmFromUrl: null,
} as { settings: Settings; realmFromUrl: string | null });

export const useSettings = () => {
  const { settings } = useContext(AppStateContext);
  return settings;
};

export const useRealmFromUrl = (): string => {
  const { realmFromUrl } = useContext(AppStateContext);
  if (!realmFromUrl) {
    throw new Error("noRealmFromUrl");
  }
  return realmFromUrl;
};

export const useCurrentTenant = (guessIfInvalid = false): string => {
  const tenantFromUrl = getTenantCodeFromUrl();
  const realmFromUrl = useRealmFromUrl();
  const { profile } = useAccountCached();
  if (
    (tenantFromUrl === unknownTenant && !guessIfInvalid) ||
    canAccessTenant(profile, tenantFromUrl)
  ) {
    return tenantFromUrl;
  }
  // eslint-disable-next-line no-restricted-properties
  return profile?.tenant ?? realmFromUrl;
};
