/* DON'T EDIT THIS FILE: edit original and run build again */ import { LiveTextViewer } from "../../live-form/ui/text-viewer.tsx";
import {
  LiveFieldInputParam,
  LiveFieldRenderer,
} from "../../live-form/ui/types.ts";
import { useLiveAfterBlurTextInput } from "../../live-form/ui/use-live-after-blur-text-input.tsx";
import { InputElement } from "../../ui/form/input-element.tsx";
import { emailLiveFieldType } from "./type.ts";

const EmailLiveInput = (fieldInfo: LiveFieldInputParam) => (
  <InputElement type="email" {...useLiveAfterBlurTextInput(fieldInfo)} />
);

export const emailLiveFieldRenderers: LiveFieldRenderer = {
  type: emailLiveFieldType,
  Input: EmailLiveInput,
  Viewer: LiveTextViewer,
};
