/* DON'T EDIT THIS FILE: edit original and run build again */ import { ChamberTenantHandler } from "../../tenant-handlers/chamber-tenant-handler.ts";
import { casfogTenant } from "./code.ts";
import { casfogFullLabel } from "./full-label.ts";
import { casfogLabel } from "./label.ts";

class CasfogTenantHandler extends ChamberTenantHandler {
  getCode(): string {
    return casfogTenant;
  }

  getPathLabel(): string | null {
    return "casfog";
  }

  getLabel(): string {
    return casfogLabel;
  }

  getFullLabel(): string {
    return casfogFullLabel;
  }

  getNotificationsFrom(): string {
    return "no-responder@matchfin.ar";
  }

  getCuit(): string | null {
    return "30-70842655-1";
  }
}

const casfogTenantHandler = new CasfogTenantHandler();

export default casfogTenantHandler;
