/* DON'T EDIT THIS FILE: edit original and run build again */ import { CommandButton } from "../../html-editor/button/command-button.tsx";
import { ListUlIcon } from "../../theme/icon.ts";
import { useActive, useChainedCommands, useCommands } from "@remirror/react";
import { useCallback } from "react";

export const BulletListButton = (_props: Record<string, never>) => {
  const chain = useChainedCommands();
  const { toggleBulletList } = useCommands();

  const handleAction = useCallback(() => {
    if (toggleBulletList.enabled()) {
      chain.toggleBulletList().focus().run();
    }
  }, [chain, toggleBulletList]);

  const active = useActive().bulletList();
  const enabled = toggleBulletList.enabled();

  return (
    <CommandButton
      commandName="bullet-list"
      active={active}
      enabled={enabled}
      onAction={handleAction}
    >
      <ListUlIcon />
    </CommandButton>
  );
};
